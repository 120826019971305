import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, TabContext, TabList, PillButtonTab } from '@talentmesh/core';
import { formatJobApplicationFilterCount } from '../../../utils/JobApplicationUtil';
import { ApplicantAssessmentInfoFilter } from '../../../../../../Models/ApplicantAssessmentInfo';
import { useApplicantAssessmentInfoContext } from '../../../../../../Context/ApplicantAssessmentInfoContext';
import useTMTranslation from '../../../../../../Hooks/useTMTranslation';

function JobApplicationFilter() {
    const theme = useTheme();
    const { tCommon: t } = useTMTranslation();
    const { filterBy, setFilterBy, totalOpen, totalHired, totalRejected } = useApplicantAssessmentInfoContext();

    const handleChange = (_: React.SyntheticEvent, newValue: ApplicantAssessmentInfoFilter) => {
        setFilterBy(newValue);
    };

    return (
        <Box
            data-cy="JobApplicationFilter"
            sx={{
                display: 'flex',
                justifyContent: 'end',
                marginTop: theme.spacing(3.75),
                marginBottom: theme.spacing(2.5),
            }}
        >
            <TabContext value={filterBy}>
                <TabList onChange={handleChange} sx={{ minHeight: theme.spacing(3.5) }}>
                    <PillButtonTab
                        label={formatJobApplicationFilterCount(t('Open'), totalOpen)}
                        value={ApplicantAssessmentInfoFilter.Open}
                    />
                    <PillButtonTab
                        label={formatJobApplicationFilterCount(t('Hired'), totalHired)}
                        value={ApplicantAssessmentInfoFilter.Hired}
                    />
                    <PillButtonTab
                        label={formatJobApplicationFilterCount(t('Rejected'), totalRejected)}
                        value={ApplicantAssessmentInfoFilter.Rejected}
                        sx={{ marginRight: 0 }}
                    />
                </TabList>
            </TabContext>
        </Box>
    );
}

export default JobApplicationFilter;
