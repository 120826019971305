import React, { ReactNode } from 'react';
import { Stack, Divider, Typography } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';

interface BaseSectionProps {
    header: string;
    children: ReactNode;
}

function BaseSection({ header, children }: BaseSectionProps): JSX.Element {
    const theme = useTheme();

    return (
        <Stack
            spacing={1.25}
            alignItems="flex-start"
            sx={{
                width: '100%',
                alignItems: 'flex-start',
                backgroundColor: 'white',
                borderRadius: theme.shape.mediumBorderRadius,
                border: `1px solid ${theme.palette.border.main}`,
            }}
        >
            <Typography
                variant="h4"
                color="primary"
                sx={{ paddingX: theme.spacing(2.5), paddingTop: theme.spacing(2.5) }}
            >
                {header}
            </Typography>
            <Divider sx={{ borderColor: theme.palette.border.main, width: '100%' }} />
            {children}
        </Stack>
    );
}

export default BaseSection;
