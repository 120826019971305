import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Typography, Box, Stack, Button } from '@talentmesh/core';
import Page from '../../Components/Pages/Page';
import TopBar from '../../Components/TopBar';
import RestrictedArea from './Components/RestrictedArea.svg';
import useNavigation from '../../Hooks/useNavigation';
import useTMTranslation from '../../Hooks/useTMTranslation';

function RecruitmentClosedPage(): JSX.Element {
    const theme = useTheme();
    const { tCommon: t } = useTMTranslation();
    const { navigateToJobApplicationsPage } = useNavigation();

    const handleClick = () => {
        navigateToJobApplicationsPage();
    };

    return (
        <Page
            topBar={<TopBar />}
            childrenSx={{
                justifyContent: 'center',
                height: '100%',
            }}
        >
            <Stack direction="row" alignItems="center" justifyContent="center">
                <Stack direction="column" spacing={theme.spacing(1)}>
                    <Typography variant="h2" color={theme.palette.text.menuTitle}>
                        {t('AccessToThisRecruitmentIsClosed')}
                    </Typography>
                    <Box
                        sx={{
                            backgroundColor: theme.palette.status.main,
                            padding: theme.spacing(1),
                            borderRadius: theme.shape.smallBorderRadius,
                        }}
                    >
                        <Typography variant="body1" color={theme.palette.text.menuTitle}>
                            {t('ApologizeForTheInconvenience')}
                        </Typography>
                    </Box>
                    <Button
                        variant="contained"
                        onClick={handleClick}
                        sx={{
                            width: 'fit-content',
                        }}
                    >
                        {t('BackToJobApplications')}
                    </Button>
                </Stack>
                <Box
                    sx={{
                        height: theme.spacing(87),
                        width: theme.spacing(65),
                    }}
                >
                    <object
                        width="100%"
                        height="100%"
                        type="image/svg+xml"
                        data={RestrictedArea}
                        aria-label="Restricted area"
                    />
                </Box>
            </Stack>
        </Page>
    );
}

export default RecruitmentClosedPage;
