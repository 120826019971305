import React, { createContext, ReactNode, useContext } from 'react';
import { CandidateTestMonitoring } from '../Models/CandidateTestMonitoringSettings';

export interface CTMonitoringContextInterface {
    updateSettingsMap: (assessmentId: string, entry: CandidateTestMonitoring) => void;
    areSettingsRequired: (assessmentId: string) => boolean;
    getSettingsByAssessmentId: (assessmentId: string) => CandidateTestMonitoring | undefined;
}
export const CandidateTestMonitoringContext = createContext<CTMonitoringContextInterface | undefined>(undefined);

const CTMonitoringContextProvider = CandidateTestMonitoringContext.Provider;

interface CTMonitoringProviderProps {
    children: ReactNode;
}

const settingsMap = new Map<string, CandidateTestMonitoring>();

export const CandidateTestMonitoringProvider = ({ children }: CTMonitoringProviderProps): JSX.Element => {
    const updateSettingsMap = (assessmentId: string, entry: CandidateTestMonitoring) => {
        settingsMap.set(assessmentId, entry);
    };

    const areSettingsRequired = (assessmentId: string) => {
        return !settingsMap.has(assessmentId);
    };

    const getSettingsByAssessmentId = (assessmentId: string) => {
        return settingsMap.get(assessmentId);
    };

    return (
        <CTMonitoringContextProvider
            value={{
                updateSettingsMap,
                areSettingsRequired,
                getSettingsByAssessmentId,
            }}
        >
            {children}
        </CTMonitoringContextProvider>
    );
};

export function useCandidateTestMonitoringContext() {
    const context = useContext(CandidateTestMonitoringContext);
    if (!context) {
        throw new Error(
            'useCandidateTestMonitoringContext must be used within the CandidateTestMonitoringContext.Provider',
        );
    }
    return context;
}
