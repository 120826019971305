import { useEffect, useState } from 'react';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';
import { SnapshotsTimeouts } from '../AppSettings';
import CreateSnapshotsSliceDTO from '../DTO/AntiCheat/CreateSnapshotsSliceDTO';
import { useSnapshotsClient, useTestMonitoringClient } from '../Hooks/ClientHooks';
import { TestState } from '../Models/TestState';
import { withRandomInterval } from './SnapshotsUtils';
import VideoGrabberService from './services/video-grabber.service';
import VideoGrabberFactoryService from './services/video-grabber-factory.service';
import { ScreenStream, WebcamStream } from './services/video-grabber';
import { mapToExceptionEventLog } from '../Telemetry/Models/ExceptionEventLog';
import ImageCaptureScreenService from './services/image-capture-screen.service';
import ImageCaptureWebcamService from './services/image-capture-webcam.service';
import { CandidateTestMonitoring } from '../Models/CandidateTestMonitoringSettings';
import BitmapCaptureScreenService from './services/bitmap-capture-screen.service';
import BitmapCaptureWebcamService from './services/bitmap-capture-webcam.service';

function useSnapshots(test: TestState, media: MediaStream | undefined): void {
    const monitoringSettings = test as CandidateTestMonitoring;
    const { testId } = test;

    const [screenshotGrabber, setScreenshotGrabber] = useState<VideoGrabberService | undefined>();
    const [webcamGrabber] = useState<VideoGrabberService>(VideoGrabberFactoryService.create(WebcamStream));

    const client = useSnapshotsClient();
    const testMonitoringClient = useTestMonitoringClient();
    const appInsights = useAppInsightsContext();
    const trackGrabberException = useTrackEvent(appInsights, 'Grabber Exception', {});
    const trackMonitoringException = useTrackEvent(appInsights, 'Monitoring Exception', {});

    const updateSettingsAsync = async (exceptionName: string, grabber: VideoGrabberService): Promise<void> => {
        if (exceptionName === 'NotAllowedError' || exceptionName === 'NotFoundError') {
            if (grabber instanceof ImageCaptureScreenService || grabber instanceof BitmapCaptureScreenService) {
                monitoringSettings.isScreenInvigilationEnabled = false;
            } else if (grabber instanceof ImageCaptureWebcamService || grabber instanceof BitmapCaptureWebcamService) {
                monitoringSettings.isWebcamEnabled = false;
            }

            try {
                await testMonitoringClient.updateSettingsAsync(test.assessmentId, test.testId, monitoringSettings);
            } catch (e: any) {
                trackMonitoringException({ exception: e });
            }
        }
    };

    const getFrame = async (grabber: VideoGrabberService, enabled: boolean): Promise<File | undefined> => {
        if (enabled && grabber) {
            try {
                return await grabber.grab();
            } catch (e: any) {
                trackGrabberException(mapToExceptionEventLog(e, grabber.constructor.name));

                if (e instanceof DOMException) {
                    updateSettingsAsync(e.name, grabber);
                }
            }
        }
        return undefined;
    };

    const createSlice = async (): Promise<boolean> => {
        const slice: CreateSnapshotsSliceDTO = {
            Screenshot: await getFrame(
                screenshotGrabber as VideoGrabberService,
                monitoringSettings.isScreenInvigilationEnabled,
            ),
            WebcamPhoto: await getFrame(webcamGrabber, monitoringSettings.isWebcamEnabled),
        };
        if (!slice.Screenshot && !slice.WebcamPhoto) {
            return false;
        }
        await client.createSlice(slice, testId || '');

        return true;
    };

    useEffect(() => {
        setScreenshotGrabber(VideoGrabberFactoryService.create(ScreenStream, media));
    }, [media]);

    useEffect(() => {
        return withRandomInterval(createSlice, SnapshotsTimeouts.minDelayMinutes, SnapshotsTimeouts.maxDelayMinutes);
    }, [test]);
}

export default useSnapshots;
