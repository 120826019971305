export interface CandidateTestMonitoring {
    isWebcamEnabled: boolean;
    isFullScreenEnabled: boolean;
    isScreenInvigilationEnabled: boolean;
}

export const DefaultCTMonitoring: CandidateTestMonitoring = {
    isWebcamEnabled: false,
    isFullScreenEnabled: false,
    isScreenInvigilationEnabled: false,
};
