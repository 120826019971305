import { $Dictionary } from 'i18next/typescript/helpers';
import { TOptionsBase } from 'i18next/typescript/options';
import { i18n } from 'i18next';
import { useTranslation } from 'react-i18next';
import { CommonResourceKey } from '../i18n/byNamespace/i18nCommonResourceKeys';
import { TestResourceKey } from '../i18n/byNamespace/i18nTestResourceKeys';

export type TranslationOptions = (TOptionsBase & $Dictionary<unknown>) | undefined;

export type TMTranslationGetter<T> = (key: T, options?: TranslationOptions) => string;

interface TMTranslationResponse {
    tCommon: TMTranslationGetter<CommonResourceKey>;
    tTest: TMTranslationGetter<TestResourceKey>;
    current: i18n;
    ready: boolean;
}

type TMTranslationProps = () => TMTranslationResponse;

export const defaultTranslationOptions: TranslationOptions = {
    defaultValue: '',
};

const useTMTranslation: TMTranslationProps = () => {
    const { t: internalT, i18n: current, ready } = useTranslation();

    const tCommon: TMTranslationGetter<CommonResourceKey> = (key, options): string => {
        return internalT(key, { ...defaultTranslationOptions, ...options, ns: 'common' });
    };

    const tTest: TMTranslationGetter<TestResourceKey> = (key, options): string => {
        return internalT(key, { ...defaultTranslationOptions, ...options, ns: 'test' });
    };

    return {
        tCommon,
        tTest,
        current,
        ready,
    };
};

export default useTMTranslation;
