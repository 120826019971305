import { createContext, useContext } from 'react';
import ProcessingState from '../../../Models/ProcessingState';
import { LikertAnswerGroupData } from '../../../Models/AssessmentData';
import { TestState } from '../../../Models/TestState';
import QuestionType from '../../../Models/QuestionType';

export interface TestContextData {
    assessmentId: string;
    setAssessmentId: (value: string) => void;

    test: TestState;
    setTest: (value: TestState) => void;

    likert: LikertAnswerGroupData;
    setLikert: (value: LikertAnswerGroupData) => void;

    likertGroups: LikertAnswerGroupData[];
    setLikertGroups: (value: LikertAnswerGroupData[]) => void;

    processingState: ProcessingState;
    setProcessingState: (value: ProcessingState) => void;

    elapsedTime: string;
    setElapsedTime: (value: string) => void;

    saveAnswerAsync: (questionId: string, questionType: QuestionType, answer: string) => Promise<void>;
    saveStatsAsync: (questionId: string, questionType: QuestionType) => Promise<void>;
    unansweredQuestionsCount: () => number;

    completeTestAsync: () => Promise<void>;
    redirectAfterTestEnd: () => void;

    resetStartTime: () => void;
}
export const TestContext = createContext<TestContextData | undefined>(undefined);

export function useTestContext() {
    const context = useContext(TestContext);
    if (!context) {
        throw new Error('useTestContext must be used within the TestContext.Provider');
    }
    return context;
}
