import React from 'react';
import { Navigate } from 'react-router-dom';
import { LoadingOverlay } from '@talentmesh/core';
import { useUserContext } from '../Context/UserContext';
import RoutePath from './RoutePath';

// eslint-disable-next-line func-names
const withTalentMeshUserRequired = function <T extends JSX.IntrinsicAttributes>(Component: React.ComponentType<T>) {
    return (props: T) => {
        const { isLoading, error } = useUserContext();

        if (error) {
            return <Navigate to={RoutePath.Error} />;
        }

        if (isLoading) {
            <LoadingOverlay />;
        }

        return <Component {...props} />;
    };
};

export default withTalentMeshUserRequired;
