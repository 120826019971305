import React from 'react';
import { Typography } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import { ApplicantAssessmentInfo } from '../../../../../../../../../Models/ApplicantAssessmentInfo';
import { TestInfo, TestStates } from '../../../../../../../../../Models/TestInfo';
import useTMTranslation from '../../../../../../../../../Hooks/useTMTranslation';

interface AssessmentSectionHeaderProps extends Pick<ApplicantAssessmentInfo, 'recruiterFirstName'> {
    tests: Array<TestInfo>;
    isSubmitted: boolean;
}

function AssessmentSectionHeader({ recruiterFirstName, tests, isSubmitted }: AssessmentSectionHeaderProps) {
    const theme = useTheme();
    const { tCommon: t } = useTMTranslation();

    let testCount = tests.length;
    if (isSubmitted) {
        const completedTests = tests.filter((test) => test.testState === TestStates.Completed);
        testCount = completedTests.length;
    }

    return (
        <Typography variant="body2" sx={{ color: theme.palette.secondary.main }}>
            {isSubmitted ? (
                <span>{`${t('YouHaveCompleted')} `}</span>
            ) : (
                <>
                    <span style={{ fontWeight: theme.typography.fontWeightBold }}>{`${recruiterFirstName} `}</span>
                    <span>{`${t('HasRequestedThatYouComplete')} `}</span>
                </>
            )}
            <span>{`${testCount} `}</span>
            <span>{`${t('TalentAssessment').toLowerCase()} `}</span>
            <span>{`${testCount === 1 ? t('Test') : t('Tests')}.`}</span>
        </Typography>
    );
}

export default AssessmentSectionHeader;
