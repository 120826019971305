import { useTheme } from '@mui/material';
import { Paper, Stack, Typography } from '@talentmesh/core';
import React, { ReactNode } from 'react';
import profilePaperPadding from './Consts';

interface ProfilePaperProps {
    header: string;
    children: ReactNode;
}

const ProfilePaper: React.FC<ProfilePaperProps> = ({ header, children }) => {
    const theme = useTheme();

    return (
        <Paper
            elevation={0}
            sx={{
                border: `1px solid ${theme.palette.border.main}`,
            }}
        >
            <Stack>
                <Paper
                    elevation={0}
                    sx={{
                        paddingTop: 1,
                        paddingBottom: 1,
                        paddingLeft: profilePaperPadding,
                        paddingRight: profilePaperPadding,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        backgroundColor: theme.palette.grey[200],
                        borderBottom: `1px solid ${theme.palette.border.main}`,
                    }}
                >
                    <Typography variant="h4" color={theme.palette.secondary.main}>
                        {header}
                    </Typography>
                </Paper>
                <Paper
                    elevation={0}
                    sx={{
                        padding: profilePaperPadding,
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        backgroundColor: theme.palette.background.aliciaBlue,
                    }}
                >
                    {children}
                </Paper>
            </Stack>
        </Paper>
    );
};

export default ProfilePaper;
