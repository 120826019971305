import React from 'react';
import { useTheme } from '@mui/material';
import { Box } from '@talentmesh/core';
import DocSmallSVG from './DocSmallIcon.svg';

function DocSmallIcon(): JSX.Element {
    const theme = useTheme();
    return (
        <Box
            sx={{
                width: theme.spacing(7),
                height: theme.spacing(9),
            }}
        >
            <object
                width="100%"
                height="100%"
                type="image/svg+xml"
                data={DocSmallSVG}
                aria-label="Doc image"
                data-cy="DocSmallIcon"
            />
        </Box>
    );
}

export default DocSmallIcon;
