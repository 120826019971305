import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Card, CardContent, Stack, Typography, FilePreview } from '@talentmesh/core';
import HtmlViewer from '../../../../Components/HtmlViewer';
import useTMTranslation from '../../../../Hooks/useTMTranslation';

export interface TestQuestionProps {
    text: string;
    index: number;
    total: number;
    src?: string;
    fileMimeType?: string;
}

function TestQuestion({ text, index, total, src, fileMimeType }: TestQuestionProps): JSX.Element {
    const theme = useTheme();
    const { tTest } = useTMTranslation();

    return (
        <Card
            sx={{
                maxWidth: theme.spacing(70),
                minHeight: theme.spacing(75),
                backgroundColor: 'transparent',
                boxShadow: 'none',
            }}
        >
            <CardContent>
                <Stack direction="column" spacing={theme.spacing(1)}>
                    <Stack direction="row" spacing={theme.spacing(1)} alignItems="flex-end">
                        <Typography variant="h4" color={theme.palette.primary.main}>
                            {tTest('Question')}
                        </Typography>
                        <Typography variant="h5">
                            {index}/{total}
                        </Typography>
                    </Stack>
                    <Stack>
                        <HtmlViewer html={text} />
                    </Stack>
                    <FilePreview src={src} fileMimeType={fileMimeType} />
                </Stack>
            </CardContent>
        </Card>
    );
}

export default TestQuestion;
