import React, { ReactNode } from 'react';
import { ScreenSharingProvider } from './ScreenSharingContext';
import { CandidateTestMonitoringProvider } from './CandidateTestMonitoringContext';

interface ApplicationContextProviderProps {
    children: ReactNode;
}

function AssessmentExecutionContextProvider({ children }: ApplicationContextProviderProps): JSX.Element {
    return (
        <CandidateTestMonitoringProvider>
            <ScreenSharingProvider>{children}</ScreenSharingProvider>
        </CandidateTestMonitoringProvider>
    );
}

export default AssessmentExecutionContextProvider;
