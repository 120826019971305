import VideoGrabberService from './video-grabber.service';

abstract class ImageCaptureService extends VideoGrabberService {
    public grab = async (): Promise<File | undefined> => {
        await this.initialize();

        if (this.media) {
            const track = this.media.getVideoTracks().find((item) => item.kind === 'video');
            if (track) {
                const capture = new ImageCapture(track.clone());
                const frame = await capture.grabFrame();
                this.canvas.getContext('bitmaprenderer')?.transferFromImageBitmap(frame);
                return await this.readFile();
            }
        }
        return undefined;
    };
}

export default ImageCaptureService;
