import { useTheme } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { FormControlLabelProps } from '@talentmesh/core';
import React from 'react';
import Body1Typography from './Body1Typography';

interface TMRadioProps extends Omit<FormControlLabelProps, 'control'> {
    label: string;
}
const TMRadio: React.FC<TMRadioProps> = ({ label, ...props }) => {
    const theme = useTheme();

    return (
        <FormControlLabel
            {...props}
            control={
                <Radio
                    sx={{
                        '&.Mui-checked': {
                            color: theme.palette.secondary.light,
                        },
                    }}
                />
            }
            label={<Body1Typography>{label}</Body1Typography>}
        />
    );
};

export default TMRadio;
