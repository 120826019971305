import { useTheme } from '@mui/material';
import { Box, Button, LoadingButton, Stack } from '@talentmesh/core';
import { ChevronRightIcon } from '@talentmesh/icons';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import React, { useCallback, useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import profileSettingsSchema from '../../../../Components/ProfileFields/profileSettingsSchema';
import { useOnBoardingContext } from '../../../../Context/OnBoardingContext';
import { useUserContext } from '../../../../Context/UserContext';
import EducationList from '../../../Components/Profile/Educations/EducationList';
import JobExperienceList from '../../../Components/Profile/JobExperiences/JobExperienceList';
import NameAndLocation from '../../../Components/Profile/NameAndLocation/NameAndLocation';
import {
    applicantOnboardFormValuesFromCandidate,
    ApplicantProfileFormValues,
    EducationFormValues,
    ExperienceFormValues,
    profileFromApplicantOnboardFormValues,
} from '../../../MyAssessment/ProfileSettings/utils/ApplicantProfileUtils';
import InformationHeader from '../ProfileInformationHeader';
import useTMTranslation from '../../../../Hooks/useTMTranslation';
import CvUpload from './components/CvUpload/CvUpload';
import CvUploadDialog from './components/CvUpload/CvUploadDialog';
import ProcessingState from '../../../../Models/ProcessingState';
import CvParsingErrorDialog from './components/CvUpload/CvParsingErrorDialog';
import ApplicantInformationType from '../../../../Models/ApplicantInformationType';
import isExperienceEditRequired from '../../../Components/Profile/JobExperiences/isExperienceEditRequired';
import isEducationEditRequired from '../../../Components/Profile/Educations/isEducationEditRequired';

interface ProfileInformationStepProps {
    handleNext: () => void;
}

function ProfileInformationStep({ handleNext }: ProfileInformationStepProps): JSX.Element {
    const theme = useTheme();
    const { user } = useUserContext();
    const { tCommon: t } = useTMTranslation();

    const {
        applicantProfile,
        experiences,
        educations,
        setApplicantProfile,
        cvFileValidationError,
        cvProcessingState,
        info,
        onBoardingWrapper,
        isOnBoardingInProgress,
    } = useOnBoardingContext();
    const { assessmentAdditionalInformation } = info;
    const needSecondStep =
        assessmentAdditionalInformation.filter((x) => x !== ApplicantInformationType.ResumeCV).length > 0;

    if (applicantProfile.email === '') {
        applicantProfile.email = user.email;
    }

    const initValues = useMemo(() => applicantOnboardFormValuesFromCandidate(applicantProfile, t), [applicantProfile]);

    const submitHandler: SubmitHandler<ApplicantProfileFormValues> = async (values) => {
        // do not allow submit if there is backend file upload error
        if (cvFileValidationError) {
            return;
        }

        const newApplicantProfile = profileFromApplicantOnboardFormValues(values);

        if (needSecondStep) {
            setApplicantProfile(newApplicantProfile);
            handleNext();
        } else {
            await onBoardingWrapper({
                applicantProfile: newApplicantProfile,
                applicantInformation: [],
            });
        }
    };

    const form = useForm<ApplicantProfileFormValues>({
        defaultValues: initValues,
        mode: 'all',
        resolver: yupResolver(profileSettingsSchema(t)),
    });
    const { handleSubmit } = form;

    useEffect(() => {
        form.setValue('experiences', experiences, { shouldValidate: true });
        form.setValue('educations', educations, { shouldValidate: true });
    }, [experiences, educations]);

    const countIncompleteExperience = useCallback((fields: ExperienceFormValues[]): number => {
        return fields.filter((field) => isExperienceEditRequired(field)).length;
    }, []);

    const countIncompleteEducation = useCallback((fields: EducationFormValues[]): number => {
        return fields.filter((field) => isEducationEditRequired(field)).length;
    }, []);

    return (
        <FormProvider {...form}>
            <form onSubmit={handleSubmit(submitHandler)}>
                <Stack alignContent="center" direction="column" spacing={theme.spacing(2.5)}>
                    <InformationHeader
                        title={t('ProfileInformation')}
                        message={t('PleaseFillInTheBelowProfileInformation')}
                    />

                    <NameAndLocation />
                    <CvUpload />
                    <CvUploadDialog open={cvProcessingState === ProcessingState.Processing} />
                    <CvParsingErrorDialog open={cvProcessingState === ProcessingState.Error} />
                    {cvProcessingState === ProcessingState.Success && (
                        <>
                            <JobExperienceList
                                defaultHasExperience={experiences.length > 0}
                                defaultIncompleteExperienceCount={countIncompleteExperience(experiences)}
                            />
                            <EducationList
                                defaultHasEducation={educations.length > 0}
                                defaultIncompleteEducationCount={countIncompleteEducation(educations)}
                            />
                        </>
                    )}

                    <Box alignSelf="center">
                        {needSecondStep ? (
                            <Button
                                size="large"
                                variant="contained"
                                type="submit"
                                disabled={
                                    cvProcessingState === ProcessingState.Processing ||
                                    cvProcessingState === ProcessingState.Error
                                }
                                endIcon={<ChevronRightIcon />}
                            >
                                {t('Next')}
                            </Button>
                        ) : (
                            <LoadingButton
                                variant="contained"
                                size="large"
                                type="submit"
                                loading={isOnBoardingInProgress}
                                endIcon={<ChevronRightIcon />}
                                disabled={
                                    cvProcessingState === ProcessingState.Processing ||
                                    cvProcessingState === ProcessingState.Error
                                }
                            >
                                {t('SubmitApplicantInformation')}
                            </LoadingButton>
                        )}
                    </Box>
                </Stack>
            </form>
        </FormProvider>
    );
}

export default ProfileInformationStep;
