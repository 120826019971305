import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, CircularProgress } from '@talentmesh/core';

function LoadingContent() {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: 'flex',
                height: theme.spacing(34.375),
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: theme.spacing(2.5),
            }}
        >
            <CircularProgress />
        </Box>
    );
}

export default LoadingContent;
