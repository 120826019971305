import { useTheme } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Box, LoadingButton, Stack } from '@talentmesh/core';
import { FormProvider, useForm } from 'react-hook-form';
import useProfileSettingsForm from './useProfileSettingsForm';
import { applicantOnboardFormValuesFromCandidate, ApplicantProfileFormValues } from '../utils/ApplicantProfileUtils';
import profileSettingsSchema from '../../../../Components/ProfileFields/profileSettingsSchema';
import { useUserContext } from '../../../../Context/UserContext';
import { ApplicantProfile } from '../../../../Models/ApplicantProfile';
import { useNotificationContext } from '../../../../Context/NotificationContext';
import NameAndLocation from '../../../Components/Profile/NameAndLocation/NameAndLocation';
import JobExperienceList from '../../../Components/Profile/JobExperiences/JobExperienceList';
import EducationList from '../../../Components/Profile/Educations/EducationList';
import useTMTranslation from '../../../../Hooks/useTMTranslation';

const ProfileSettingsFormInner: React.FC = () => {
    const { user, error } = useUserContext();
    const { showFailToaster } = useNotificationContext();
    const { submitHandler } = useProfileSettingsForm();
    const theme = useTheme();
    const { tCommon: t } = useTMTranslation();

    useEffect(() => {
        if (error) {
            showFailToaster();
        }
    }, [error]);

    const initValues = applicantOnboardFormValuesFromCandidate(user as ApplicantProfile, t);

    const form = useForm<ApplicantProfileFormValues>({
        defaultValues: initValues,
        mode: 'all',
        resolver: yupResolver(profileSettingsSchema(t, true)),
    });

    const { handleSubmit, formState, reset } = form;
    const { isDirty, isSubmitting } = formState;

    return (
        <FormProvider {...form}>
            <form
                onSubmit={handleSubmit((data) => {
                    submitHandler(data);
                    reset(data);
                })}
            >
                <Stack spacing={theme.spacing(2.5)}>
                    <NameAndLocation showEmail />
                    <JobExperienceList
                        defaultHasExperience={initValues.experiences.length > 0}
                        defaultIncompleteExperienceCount={0}
                    />
                    <EducationList
                        defaultHasEducation={initValues.educations.length > 0}
                        defaultIncompleteEducationCount={0}
                    />

                    <Box alignSelf="center">
                        <LoadingButton
                            variant="contained"
                            size="large"
                            type="submit"
                            disabled={!isDirty}
                            loading={isSubmitting}
                        >
                            {t('Save')}
                        </LoadingButton>
                    </Box>
                </Stack>
            </form>
        </FormProvider>
    );
};

export default ProfileSettingsFormInner;
