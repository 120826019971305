import React from 'react';
import { Stack } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import TestPurpose from '../../TestPurpose/TestPurpose';
import { ApplicantAssessmentInfo } from '../../../../../../../../Models/ApplicantAssessmentInfo';
import StartTestButton from '../../TestAction/StartTestButton';

interface AssessmentActionSectionProps
    extends Pick<ApplicantAssessmentInfo, 'id' | 'tests' | 'companyName' | 'assessmentStatus'> {
    isOngoing: boolean;
}

function AssessmentActionSection({
    isOngoing,
    id,
    tests,
    companyName,
    assessmentStatus,
}: AssessmentActionSectionProps) {
    const theme = useTheme();

    return (
        <Stack
            direction="row"
            sx={{ marginTop: theme.spacing(2.5), justifyContent: 'space-between', alignItems: 'center' }}
        >
            <TestPurpose />
            <StartTestButton
                id={id}
                tests={tests}
                isOngoing={isOngoing}
                companyName={companyName}
                assessmentStatus={assessmentStatus}
            />
        </Stack>
    );
}

export default AssessmentActionSection;
