import { AutocompleteOption } from '@talentmesh/core';
import { EducationFieldDTO } from '../DTO/Users/EducationFieldDTO';
import { JobExperienceDTO } from '../DTO/Users/JobExperienceDTO';
import { get } from '../Utils/HTTP/HttpUtils';
import ClientBase from './ClientBase';

export default class SettingsClient extends ClientBase {
    apiPrefix = 'users/settings';

    getEducationFields = async (search: string): Promise<AutocompleteOption[]> => {
        return await this.getOptions<EducationFieldDTO>('education-fields', search);
    };

    getJobExperiences = async (search: string): Promise<AutocompleteOption[]> => {
        return await this.getOptions<JobExperienceDTO>('job-experiences', search);
    };

    private async getOptions<T>(subPath: string, search: string): Promise<AutocompleteOption[]> {
        const accessToken = await this.getAccessTokenSilently();
        const query = new URLSearchParams({
            search,
        });
        const path = this.addHost(`${this.apiPrefix}/${subPath}?${query.toString()}`);

        const response = await get<T[]>({
            path,
            accessToken,
        });
        if (response.ok && response.body) {
            return response.body.map((item: any) => {
                const option: AutocompleteOption = {
                    id: item.id?.toString(),
                    label: item.userDefined || '',
                };

                return option;
            });
        }
        return [];
    }
}
