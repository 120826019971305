import { useTheme } from '@mui/material';
import React from 'react';
import { SpanTypography } from '@talentmesh/core';

interface DialogTypographyProps {
    text: string;
    bold?: boolean;
    italic?: boolean;
}

function DialogTypography({ text, bold, italic }: DialogTypographyProps): JSX.Element {
    const theme = useTheme();
    return (
        <SpanTypography
            variant="body2"
            color={theme.palette.text.primary}
            fontWeight={bold ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular}
            fontStyle={italic ? 'italic' : 'unset'}
        >
            {text}
        </SpanTypography>
    );
}

export default DialogTypography;
