import axios from 'axios';
import ClientBase from './ClientBase';

export default class BlobClient extends ClientBase {
    async uploadCvAsync(assessmentId: string, file: File): Promise<string> {
        const form = new FormData();
        form.append('file', file);
        form.append('assessmentId', assessmentId);
        const response = await axios.post(this.addHost('blob/cv'), form, {
            headers: {
                Authorization: `Bearer ${await this.getAccessTokenSilently()}`,
            },
        });

        if (response.status === 200 && response.data) {
            return response.data;
        }

        return '';
    }

    async deleteCvAsync(assessmentId: string) {
        await axios.delete(this.addHost(`blob/cv`), {
            headers: {
                Authorization: `Bearer ${await this.getAccessTokenSilently()}`,
            },
            data: {
                assessmentId,
            },
        });
    }
}
