import React from 'react';
import JobApplicationPageContent from './JobApplicationPageContent';
import PageSideNav from '../../../Components/Pages/PageSideNav';
import { ApplicantAssessmentInfoProvider } from '../../../Context/ApplicantAssessmentInfoContext';
import withTalentMeshUserRequired from '../../../Routing/withTalentMeshUserRequired';
import { AssessmentActionsProvider } from '../../../Context/AssessmentActionsContext';
import { DialogsProvider } from '../../../Context/DialogsContext';

function JobApplicationPage() {
    return (
        <DialogsProvider>
            <AssessmentActionsProvider>
                <ApplicantAssessmentInfoProvider>
                    <PageSideNav>
                        <JobApplicationPageContent />
                    </PageSideNav>
                </ApplicantAssessmentInfoProvider>
            </AssessmentActionsProvider>
        </DialogsProvider>
    );
}

export default withTalentMeshUserRequired(JobApplicationPage);
