import React from 'react';
import { FormikTextField } from '@talentmesh/forms';
import Constants from '../Constants';
import ApplicantInformationType from '../../../../../../Models/ApplicantInformationType';
import useTMTranslation from '../../../../../../Hooks/useTMTranslation';

function ReasonForLeaving(): JSX.Element {
    const { tCommon: t } = useTMTranslation();

    return (
        <FormikTextField
            label={`${t('ReasonForLeaving')}*`}
            name={ApplicantInformationType.ReasonForLeaving}
            multiline
            rows={Constants.numberOfRows}
            maxChar={Constants.textFieldLargeCapacity}
        />
    );
}

export default ReasonForLeaving;
