import React from 'react';
import { TMTranslationGetter } from '../../../../../../../Hooks/useTMTranslation';
import { CommonResourceKey } from '../../../../../../../i18n/byNamespace/i18nCommonResourceKeys';
import DocSmallIcon from '../../../../RecruitmentInformation/Components/Utility/Icons/DocSmallIcon';
import PdfSmallIcon from '../../../../RecruitmentInformation/Components/Utility/Icons/PdfSmallIcon';

export function getFileSizeString(t: TMTranslationGetter<CommonResourceKey>, fileSize?: number): string {
    if (!fileSize) {
        return '';
    }
    const kb = Number((fileSize / 1024).toFixed(2));
    const mb = Number((kb / 1024).toFixed(2));
    if (mb >= 1) {
        return `${mb} ${t('Mb')}`;
    }
    if (kb >= 1) {
        return `${kb} ${t('Kb')}`;
    }
    return '';
}

export function getCvFileIcon(currentFilename?: string): JSX.Element | null {
    const extension = currentFilename?.split('.').at(-1);
    switch (extension) {
        case 'pdf':
            return <PdfSmallIcon />;
        case 'doc':
        case 'docx':
            return <DocSmallIcon />;
        default:
            return null;
    }
}
