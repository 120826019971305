import React from 'react';
import { Stack, Typography } from '@talentmesh/core';

function VersionPage(): JSX.Element {
    return (
        <Stack direction="column">
            <Typography>{`app version: ${process.env.REACT_APP_VERSION}`}</Typography>
        </Stack>
    );
}

export default VersionPage;
