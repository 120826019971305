import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { TMLSupportedLanguageCode, TMTranslationNamespaces, DefaultTMTranslationNamespace } from './i18nTypes';

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        // detect language on shortcode (eg. en-us -> en) since we do not support
        // each country language that shares the same shortcode (en-uk, en-sg).
        load: 'languageOnly',

        // disabled preload after switching to self managed browser language detection
        // attempt to reduce the effect of lazy sideloading when needed
        // preload: [TMFallbackLanguageCode] as Array<CommonLanguageCode>,

        // lowercase the language code to make sure we do not run into case
        // sensitivity problems when loading the files from the http backend
        // especially on production where this has happened before
        cleanCode: true,

        // setting fallback language to false as fallbacks are handled by self browser
        // language detection
        fallbackLng: false,
        supportedLngs: TMLSupportedLanguageCode,
        interpolation: {
            escapeValue: false,
        },

        // namespace by importance name to help translator with focused context to translate
        ns: TMTranslationNamespaces,
        defaultNS: DefaultTMTranslationNamespace,

        detection: {
            // configure browser detection to prioritize navigator than localstorage
            // or any other form of storage and variables to depend on when detecting
            // to avoid stale user config when the user changes their display language
            // order: ['navigator'],
            // only detect language and use as an initial option and load from backend,
            // enabling cache may clash against changes from the browser and host settings
            // caches: [],
        },

        // disabled suspense for i18next until we move to React 18 as we are missing
        // a couple APIs to implement spinner-less language switching and consistent
        // language assets loading spinner. Loading and swithcing is now self managed
        react: {
            useSuspense: false,
        },

        // enable when needed for development purposes
        // debug: true,
    });

export default i18n;
