import { ScreenStream, VideoStreamType, WebcamStream } from './video-grabber';
import ImageCaptureScreenService from './image-capture-screen.service';
import ImageCaptureWebcamService from './image-capture-webcam.service';
import BitmapCaptureScreenService from './bitmap-capture-screen.service';
import BitmapCaptureWebcamService from './bitmap-capture-webcam.service';
import VideoGrabberService from './video-grabber.service';

class VideoGrabberFactoryService {
    public static create(type: VideoStreamType, media?: MediaStream): VideoGrabberService {
        switch (type) {
            case ScreenStream:
                if ('ImageCapture' in window) {
                    return new ImageCaptureScreenService(media);
                } else {
                    return new BitmapCaptureScreenService(media);
                }

            case WebcamStream:
                if ('ImageCapture' in window) {
                    return new ImageCaptureWebcamService();
                } else {
                    return new BitmapCaptureWebcamService();
                }
            default:
                throw new Error(`Media ${type} is not supported`);
        }
    }
}

export default VideoGrabberFactoryService;
