import { createContext, useContext } from 'react';

export enum StepStatuses {
    None,
    Visited,
    Answered,
}

export interface Step {
    status: StepStatuses;
    index: number;
}

export interface StepperSkillsContextState {
    activeIndex: number;
    steps: Step[];
    visibleSteps: Step[];
    completedCount: number;
}

export const DefaultStepperSkillsContextState: StepperSkillsContextState = {
    activeIndex: 0,
    steps: [],
    visibleSteps: [],
    completedCount: 0,
};

export interface StepperSkillsContextData {
    contextState: StepperSkillsContextState;

    loadSteps: (initialValues: Step[], initialRange: number) => void;

    setActiveStep: (index: number, status: StepStatuses) => void;

    handleShiftLeft: () => void;
    handleShiftRight: () => void;
}

export const StepperSkillsContext = createContext<StepperSkillsContextData | undefined>(undefined);

export function useStepperSkillsContext(): StepperSkillsContextData {
    const stepperContext = useContext(StepperSkillsContext);
    if (!stepperContext) {
        throw new Error('useStepperSkillsContext must be used within the StepperSkillsContext.Provider');
    }
    return stepperContext;
}
