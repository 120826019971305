import { useTheme } from '@mui/material';
import React from 'react';
import { Dialog, Stack } from '@talentmesh/core';
import { useTestContext } from '../../../../Contexts/TestContext';
import DialogBody from './DialogBody';
import DialogButtonPanel from './DialogButtonPanel';
import DialogHeader from './DialogHeader';

interface SubmitUnansweredDialogProps {
    open: boolean;
    ok: () => void;
    cancel: () => void;
}

function SubmitUnansweredDialog({ open, ok, cancel }: SubmitUnansweredDialogProps): JSX.Element {
    const theme = useTheme();
    const { elapsedTime, unansweredQuestionsCount } = useTestContext();
    return (
        <Dialog open={open}>
            <Stack
                direction="column"
                spacing={theme.spacing(2)}
                padding={theme.spacing(2)}
                sx={{ width: theme.spacing(70) }}
            >
                <DialogHeader />
                <DialogBody timeLeft={elapsedTime} unansweredQuestionsCount={unansweredQuestionsCount()} />
                <DialogButtonPanel ok={ok} cancel={cancel} />
            </Stack>
        </Dialog>
    );
}

export default SubmitUnansweredDialog;
