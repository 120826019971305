import { BoxProps, FormHelperText, theme } from '@talentmesh/core';
import React from 'react';
import useTMTranslation from '../../../../../../../Hooks/useTMTranslation';

export interface PhoneHelperTextProps extends BoxProps {
    errorMessage?: string;
}
export function PhoneHelperText({ errorMessage }: PhoneHelperTextProps): JSX.Element {
    const { tCommon: t } = useTMTranslation();

    return (
        <FormHelperText
            sx={{
                marginTop: theme.spacing(1.25),
                marginInline: theme.spacing(1.75),
                color: errorMessage ? theme.palette.error.main : theme.palette.text.secondary,
            }}
        >
            {errorMessage ?? t('TheHiringTeamMayUseThisNumber')}
        </FormHelperText>
    );
}
