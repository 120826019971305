import { useTheme } from '@mui/material';
import React from 'react';
import { Box, IconButton, Stack, TMTooltip, TooltipPaper, Typography, Label } from '@talentmesh/core';
import { InfoIcon } from '@talentmesh/icons';

interface ProfileSettingsLabelTooltipProps {
    tooltipTitle?: string;
    tooltipDescription?: React.ReactNode;
    htmlFor?: string;
}

const ProfileSettingsLabelTooltip = ({
    children,
    tooltipDescription,
    tooltipTitle,
    htmlFor,
}: React.PropsWithChildren<ProfileSettingsLabelTooltipProps>) => {
    const theme = useTheme();

    return (
        <Stack direction="row" alignItems="center" spacing={0.5} maxHeight={theme.spacing(2.5)}>
            <Label htmlFor={htmlFor}>{children}</Label>
            <TMTooltip
                title={
                    <TooltipPaper minWidth={theme.spacing(43)}>
                        <Stack
                            direction="column"
                            alignItems="flex-start"
                            spacing={2}
                            color={theme.palette.text.menuTitle}
                        >
                            <Typography variant="h5">{tooltipTitle}</Typography>
                            <Box>{tooltipDescription}</Box>
                        </Stack>
                    </TooltipPaper>
                }
                placement="bottom"
            >
                <IconButton
                    title={tooltipTitle}
                    sx={{
                        color: theme.palette.secondary.light,
                        padding: 0,
                        height: theme.typography.h6.fontSize,
                        maxHeight: theme.typography.h6.fontSize,
                        marginBottom: theme.spacing(1),
                    }}
                >
                    <InfoIcon fontSize="small" />
                </IconButton>
            </TMTooltip>
        </Stack>
    );
};

export default ProfileSettingsLabelTooltip;
