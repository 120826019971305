import { CandidateAssessmentInfo, DefaultCandidateAssessmentInfo } from '../Models/CandidateAssessmentInfo';
import ClientBase from './ClientBase';
import { get } from '../Utils/HTTP/HttpUtils';
import { ApplicantAssessmentInfoDTO } from '../DTO/Assessments/ApplicantAssessmentInfoDTO';
import {
    ApplicantAssessmentInfo,
    ApplicantAssessmentInfoFilter,
    EmptyApplicantAssessmentInfoPage,
    ApplicantAssessmentInfoPagination,
    mapFromApplicantAssessmentInfoDTO,
} from '../Models/ApplicantAssessmentInfo';
import { AssessmentSpecificationDTO } from '../DTO/Assessments/AssessmentSpecificationDTO';
import { AssessmentSpecificationDTO2Status, AssessmentStatus } from '../Models/AssessmentStatus';
import { RecruitmentLocale, DefaultRecruitmentLocale } from '../Models/RecruitmentLocale';

export default class AssessmentClient extends ClientBase {
    buildAssessmentQuery(
        offset: number,
        limit: number,
        status: ApplicantAssessmentInfoFilter,
        search?: string,
    ): string {
        const query = new URLSearchParams({
            offset: offset.toString(),
            limit: limit.toString(),
        });

        if (search && search.length > 1) {
            query.append('search', search);
        }

        if (status !== ApplicantAssessmentInfoFilter.Open) {
            query.append('status', status);
        }

        return query.toString();
    }

    async getAssessmentStatusAsync(id: string): Promise<AssessmentStatus | undefined> {
        const response = await get<AssessmentSpecificationDTO>({
            path: this.addHost(`assessments/${id}/specification`),
            accessToken: await this.getAccessTokenSilently(),
        });

        if (response.ok && response.body) {
            return AssessmentSpecificationDTO2Status(response.body);
        }
        return undefined;
    }

    async getApplicantAssessmentInfosAsync(
        offset: number,
        limit: number,
        status: ApplicantAssessmentInfoFilter,
        search?: string,
    ): Promise<ApplicantAssessmentInfoPagination<ApplicantAssessmentInfo>> {
        const response = await get<ApplicantAssessmentInfoPagination<ApplicantAssessmentInfoDTO>>({
            path: this.addHost(`applicant-assessments?${this.buildAssessmentQuery(offset, limit, status, search)}`),
            accessToken: await this.getAccessTokenSilently(),
        });

        if (response.ok && response.body) {
            const { pagination, totalOpen, totalHired, totalRejected, data } = response.body;
            const dataFromDTO = (data ?? []).map((dto: ApplicantAssessmentInfoDTO) =>
                mapFromApplicantAssessmentInfoDTO(dto),
            );

            return {
                pagination,
                data: dataFromDTO,
                totalOpen,
                totalHired,
                totalRejected,
            };
        }

        return EmptyApplicantAssessmentInfoPage;
    }

    async getCandidateAssessmentInfos(): Promise<CandidateAssessmentInfo[]> {
        const response = await get<CandidateAssessmentInfo[]>({
            path: this.addHost(`assessments/assessment-infos`),
            accessToken: await this.getAccessTokenSilently(),
        });

        if (response.ok && response.body) {
            return response.body;
        }
        return [DefaultCandidateAssessmentInfo];
    }

    async getRecruitmentLocaleAsync(recruitmentId: string): Promise<RecruitmentLocale> {
        const result = await get<RecruitmentLocale>({
            path: this.addHost(`assessments/${recruitmentId}/locale`),
            accessToken: await this.getAccessTokenSilently(),
        });

        const { ok, body } = result;

        if (ok && body) {
            return body;
        }

        return DefaultRecruitmentLocale;
    }
}
