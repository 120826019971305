import { useEffect, useState } from 'react';
import {
    DefaultStepperPersonalityContextState,
    maxSteps,
    StepperPersonalityContextData,
    StepperPersonalityContextState,
} from './StepperPersonalityContext';

function useStepperPersonalityContextValue(): StepperPersonalityContextData {
    const [count, setCount] = useState(0);
    const [steps, setSteps] = useState<number[]>([]);

    const [contextState, setContextState] = useState<StepperPersonalityContextState>(
        DefaultStepperPersonalityContextState,
    );

    useEffect(() => {
        setSteps(Array.from(Array(maxSteps).keys()));
    }, [count]);

    const normalize = (value: number, cnt: number): number => {
        return cnt > 0 ? Math.round(((value < cnt ? value + 1 : cnt) * 100) / cnt) : 0;
    };

    const initialize = (initialCount: number, completedIndex: number) => {
        setCount(initialCount);

        setContextState((prevState) => {
            const newState = { ...prevState };

            newState.activeIndex = completedIndex + 1;
            newState.activeStep = Math.round((newState.activeIndex / initialCount) * maxSteps);
            newState.completedIndex = completedIndex;
            newState.completedStep = Math.round((newState.completedIndex / initialCount) * maxSteps);
            newState.percentage = normalize(newState.completedIndex, initialCount);

            return newState;
        });
    };

    const handleNext = () => {
        setContextState((prevState) => {
            const newState = { ...prevState };

            if (prevState.activeIndex < count - 1) {
                newState.activeIndex += 1;
            }

            newState.activeStep = Math.round((newState.activeIndex / count) * maxSteps);

            if (newState.activeStep >= maxSteps) {
                newState.activeStep -= 1;
            }

            return newState;
        });
    };

    const handleBack = () => {
        setContextState((prevState) => {
            const newState = { ...prevState };

            if (newState.activeIndex > 0) {
                newState.activeIndex -= 1;
            }

            newState.activeStep = Math.round((newState.activeIndex / count) * maxSteps);

            return newState;
        });
    };

    const handleComplete = () => {
        setContextState((prevState) => {
            const newState = { ...prevState };

            if (prevState.completedIndex < prevState.activeIndex) {
                newState.completedIndex = prevState.activeIndex;
            }

            newState.completedStep = Math.round((newState.completedIndex / count) * maxSteps);
            newState.percentage = normalize(newState.completedIndex, count);

            return newState;
        });
    };

    return {
        count,
        steps,
        contextState,
        initialize,

        handleNext,
        handleBack,
        handleComplete,
    };
}

export default useStepperPersonalityContextValue;
