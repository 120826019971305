import { EducationParsedDTO } from '../Users/EducationDTO';
import { ExperienceParsedDTO } from '../Users/ExperienceDTO';

export interface CvSummaryDTO {
    experiences: ExperienceParsedDTO[];
    educations: EducationParsedDTO[];
}

export const DefaultCvSummaryDTO: CvSummaryDTO = {
    experiences: [],
    educations: [],
};
