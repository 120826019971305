/* eslint-disable import/prefer-default-export */
const initialTimeout = 5 * 1000;
function withRandomInterval(
    callback: () => Promise<boolean>,
    minDelayMinutes: number,
    maxDelayMinutes: number,
): () => void {
    let timeout: number;

    const runInterval = (timeOut?: number) => {
        const timeoutFunction = async () => {
            const success = await callback();
            let t: number | undefined;
            if (!success) {
                t = initialTimeout;
            }

            runInterval(t);
        };

        const delay = Math.random() * (maxDelayMinutes - minDelayMinutes) + minDelayMinutes;

        timeout = window.setTimeout(timeoutFunction, timeOut || delay * 1000 * 60);
    };

    runInterval(initialTimeout);

    return () => {
        clearTimeout(timeout);
    };
}

export { withRandomInterval };
