import { ApplicantOnBoardingInfoDTO } from '../DTO/Onboarding/ApplicantOnBoardingInfoDTO';
import { AdditionalInfo } from '../Models/AdditionalInfo';
import {
    ApplicantOnBoardingInfo,
    DefaultApplicantOnBoardingInfo,
    mapAdditionalInfo2ApplicantOnBoardDataDTO,
    mapApplicantOnBoardingInfoDTO2Model,
} from '../Models/ApplicantOnBoardingInfo';
import { get, post, toJson } from '../Utils/HTTP/HttpUtils';
import ClientBase from './ClientBase';

export default class OnBoardingClient extends ClientBase {
    async getOnboardingInfoAsync(assessmentId: string): Promise<ApplicantOnBoardingInfo> {
        const response = await get<ApplicantOnBoardingInfoDTO>({
            accessToken: await this.getAccessTokenSilently(),
            path: this.addHost(`onboarding-info/${assessmentId}`),
        });

        if (response.ok && response.body) {
            return mapApplicantOnBoardingInfoDTO2Model(response.body);
        }
        return DefaultApplicantOnBoardingInfo;
    }

    async onBoardApplicantAsync(assessmentId: string, additionalInfo: AdditionalInfo): Promise<void> {
        const dto = mapAdditionalInfo2ApplicantOnBoardDataDTO(assessmentId, additionalInfo);

        const { body, contentType } = toJson(dto);
        const path = this.addHost(`onboarding-info`);
        const accessToken = await this.getAccessTokenSilently();

        await post({
            path,
            body,
            contentType,
            accessToken,
        });
    }
}
