import BitmapCaptureService from './bitmap-capture.service';

class BitmapCaptureScreenService extends BitmapCaptureService {
    protected initialize = async (): Promise<void> => {
        if (!this.media?.active) {
            this.media = await navigator.mediaDevices.getDisplayMedia({ video: true });
        }
        if (this.video.srcObject == null) {
            this.video.srcObject = this.media;
        }
    };
}

export default BitmapCaptureScreenService;
