import ImageCaptureService from './image-capture.service';

class ImageCaptureWebcamService extends ImageCaptureService {
    protected initialize = async (): Promise<void> => {
        if (this.media?.active) {
            return;
        }

        this.media = await navigator.mediaDevices.getUserMedia({ video: true });
    };
}

export default ImageCaptureWebcamService;
