import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, TruncatedTypography } from '@talentmesh/core';
import CompanyLogo from './components/CompanyLogo';
import OnBoardTestButton from '../../TestAction/OnBoardTestButton';
import { ApplicantAssessmentInfo } from '../../../../../../../../Models/ApplicantAssessmentInfo';

interface InfoSectionProps
    extends Pick<ApplicantAssessmentInfo, 'id' | 'companyName' | 'assessmentName' | 'companyIconURL'> {
    isNotStarted: boolean;
}

function InfoSection({ id, companyName, assessmentName, companyIconURL, isNotStarted }: InfoSectionProps) {
    const theme = useTheme();

    return (
        <Stack
            direction="row"
            sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                marginX: theme.spacing(2.5),
                marginBottom: theme.spacing(isNotStarted ? 2.5 : 0),
            }}
        >
            <Stack spacing={1.25} sx={{ justifyContent: 'flex-start' }}>
                <Typography variant="h3" sx={{ color: theme.palette.secondary.main }}>
                    {assessmentName}
                </Typography>
                <Stack
                    data-cy="JobApplicationCardCompanyInfo"
                    direction="row"
                    spacing={1.25}
                    sx={{ alignItems: 'center' }}
                >
                    <CompanyLogo companyIconURL={companyIconURL} />
                    <TruncatedTypography
                        value={companyName}
                        typographyProps={{
                            variant: 'h5',
                            sx: { color: theme.palette.secondary.main },
                        }}
                    />
                </Stack>
            </Stack>
            {isNotStarted && <OnBoardTestButton id={id} />}
        </Stack>
    );
}

export default InfoSection;
