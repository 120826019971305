import { useTheme } from '@mui/material/styles';
import { useFormikContext } from 'formik';
import React from 'react';
import { Box, Stack, SpanTypography } from '@talentmesh/core';
import CTMonitoringProgressBar from './CTMonitoringProgressBar';
import { CTMonitoringPanelFormValues } from './CTMonitoringTogglesForm';
import useTMTranslation from '../../../Hooks/useTMTranslation';

type CTMonitoringLevel = 'VeryPoor' | 'Poor' | 'Fair' | 'Excellent';

function CTMonitoringProgressPanel(): JSX.Element {
    const theme = useTheme();
    const { tTest } = useTMTranslation();
    const { values } = useFormikContext<CTMonitoringPanelFormValues>();
    const featuresState = [values.isFullScreenEnabled, values.isWebcamEnabled, values.isScreenInvigilationEnabled];
    const progress = (featuresState.filter((item) => item).length / featuresState.length) * 100;

    const getCandidateTestMonitoringLevel = (value: number): CTMonitoringLevel => {
        if (value > 0 && value <= 50) {
            return 'Poor';
        }

        if (value > 50 && value <= 75) {
            return 'Fair';
        }

        if (value === 100) {
            return 'Excellent';
        }

        return 'VeryPoor';
    };

    const getProgressColor = (level: CTMonitoringLevel) => {
        switch (level) {
            case 'VeryPoor':
                return theme.palette.grey[400];
            case 'Poor':
            case 'Fair':
                return theme.palette.error.main;
            default:
                return theme.palette.charts.chart9;
        }
    };

    const getColor = (level: CTMonitoringLevel) => {
        switch (level) {
            case 'Excellent':
                return theme.palette.charts.chart9;
            default:
                return theme.palette.error.main;
        }
    };

    const level = getCandidateTestMonitoringLevel(progress);
    const label = tTest(level);

    return (
        <Stack direction="column" spacing={0.625}>
            <Box>
                <SpanTypography variant="body2">{`${tTest('ApplicantIdentificationFeatures')}: `}</SpanTypography>
                <SpanTypography variant="body2" color={getColor(level)} fontWeight={theme.typography.fontWeightBold}>
                    {label}
                </SpanTypography>
            </Box>
            <CTMonitoringProgressBar value={progress} color={getProgressColor(level)} />
        </Stack>
    );
}

export default CTMonitoringProgressPanel;
