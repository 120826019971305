import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import useTMTranslation from '../../../../../../../Hooks/useTMTranslation';
import toDateString from '../../../../../../../Utils/DateHelper';
import { getFileSizeString } from './CvUtils';

interface CvFileMetadataProps {
    fileSize?: number;
    currentFilename?: string;
    isUploadInProgress: boolean;
}
function CvFileMetadata({ fileSize, currentFilename, isUploadInProgress }: CvFileMetadataProps): JSX.Element {
    const theme = useTheme();
    const { tCommon: t } = useTMTranslation();

    return (
        <Stack
            direction="column"
            sx={{
                flex: 1,
            }}
        >
            <Typography
                variant="body2"
                color={theme.palette.common.black}
                fontWeight={theme.typography.fontWeightMedium}
            >
                {currentFilename}
            </Typography>
            {currentFilename && !isUploadInProgress && (
                <Typography variant="label" color={theme.palette.text.secondary} fontSize="10px">
                    {getFileSizeString(t, fileSize)}
                </Typography>
            )}
            {currentFilename && !isUploadInProgress && (
                <Typography variant="label" color={theme.palette.text.secondary}>
                    {`${t('Uploaded')} ${toDateString(new Date())}`}
                </Typography>
            )}
        </Stack>
    );
}

export default CvFileMetadata;
