import React from 'react';
import { DropdownOption } from '@talentmesh/core';
import { FormikDropdown } from '@talentmesh/forms';
import ApplicantInformationType from '../../../../../../Models/ApplicantInformationType';
import useTMTranslation, { TMTranslationGetter } from '../../../../../../Hooks/useTMTranslation';
import { CommonResourceKey } from '../../../../../../i18n/byNamespace/i18nCommonResourceKeys';

type ValidWorkPermitDropdownOptionsType = (t: TMTranslationGetter<CommonResourceKey>) => DropdownOption[];

const ValidWorkPermitDropdownOptions: ValidWorkPermitDropdownOptionsType = (t) => {
    return [
        { id: 'Yes', name: t('Yes') },
        { id: 'No', name: t('No') },
    ];
};

function ValidWorkPermit(): JSX.Element {
    const { tCommon: t } = useTMTranslation();

    return (
        <FormikDropdown
            data-cy="ValidWorkPermit"
            label={`${t('ValidWorkPermitForTheCountry')}*`}
            name={ApplicantInformationType.ValidWorkPermit}
            options={ValidWorkPermitDropdownOptions(t)}
        />
    );
}

export default ValidWorkPermit;
