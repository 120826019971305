import { SaveAnswerDTO } from '../DTO/Questions/SaveAnswerDTO';
import { post, toJson } from '../Utils/HTTP/HttpUtils';
import ClientBase from './ClientBase';

export default class AnswersClient extends ClientBase {
    async saveAnswerAsync(answer: SaveAnswerDTO): Promise<void> {
        const strategy = toJson(answer);
        await post({
            path: this.addHost('answers'),
            accessToken: await this.getAccessTokenSilently(),
            ...strategy,
        });
    }
}
