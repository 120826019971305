import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { DropdownOption } from '@talentmesh/core';
import { FormikDropdown } from '@talentmesh/forms';
import ApplicantInformationType from '../../../../../../Models/ApplicantInformationType';
import { RecruitmentInformationFormValues } from '../../RecruitmentInformationUtils';
import Constants from '../Constants';
import useTMTranslation, { TMTranslationGetter } from '../../../../../../Hooks/useTMTranslation';
import { CommonResourceKey } from '../../../../../../i18n/byNamespace/i18nCommonResourceKeys';

type IsEmployedDropdownOptionsType = (t: TMTranslationGetter<CommonResourceKey>) => DropdownOption[];

const IsEmployedDropdownOptions: IsEmployedDropdownOptionsType = (t) => {
    return [
        { id: Constants.isEmployed, name: t('Yes') },
        { id: Constants.isUnemployed, name: t('No') },
    ];
};

const fieldsToReset: string[] = ['ReasonForLeaving', 'NoticePeriod'];

function IsEmployedDropdown(): JSX.Element {
    const {
        values: { IsEmployed },
        setFieldValue,
    } = useFormikContext<RecruitmentInformationFormValues>();
    const { tCommon: t } = useTMTranslation();

    useEffect(() => {
        // Should reset only when transitioning from unemployed to employed
        if (IsEmployed === Constants.isUnemployed) {
            fieldsToReset.map((fieldName) => setFieldValue(fieldName, ''));
        }
    }, [IsEmployed]);

    return (
        <FormikDropdown
            data-cy="IsEmployedDropdown"
            label={`${t('AreYouCurrentlyEmployed')}*`}
            name={ApplicantInformationType.IsEmployed}
            options={IsEmployedDropdownOptions(t)}
        />
    );
}

export default IsEmployedDropdown;
