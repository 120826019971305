import { useTheme } from '@mui/material';
import { Box, BoxProps } from '@talentmesh/core';
import React from 'react';

export interface PhoneBoxProps extends BoxProps {
    hasError: boolean;
}
export function PhoneBox({ hasError, children }: PhoneBoxProps): JSX.Element {
    const theme = useTheme();
    return (
        <Box
            sx={{
                '& .react-tel-input': {
                    borderWidth: `1px 1px ${hasError ? theme.spacing(0.25) : '1px'} 1px`,
                    borderStyle: 'solid',
                    borderRadius: theme.shape.smallBorderRadius,
                    borderColor: hasError ? theme.palette.error.dark : theme.palette.border.main,

                    '&:focus-within': {
                        borderBottomWidth: theme.spacing(0.25),
                        borderColor: theme.palette.secondary.main,
                    },

                    '& .form-control': {
                        border: 'none',
                        borderRadius: theme.shape.smallBorderRadius,
                        width: '100%',
                        height: theme.spacing(6.5),
                        color: theme.palette.text.menuTitle,
                        ...theme.typography.body1,
                    },

                    '& .form-control:focus, .form-control.open': {
                        borderRadius: 'inherit',
                    },

                    '& .flag-dropdown': {
                        border: 'none',
                        borderRadius: `${theme.shape.smallBorderRadius} 0px 0px ${theme.shape.smallBorderRadius}`,
                        height: '100%',

                        '& .selected-flag': {
                            '&:hover, :focus': {
                                borderRadius: 'inherit',
                            },
                        },

                        '&.open': {
                            width: '100%',
                            border: theme.shape.smallBorderRadius,

                            '& .selected-flag': {
                                '&.open': {
                                    borderRadius: 'inherit',
                                },
                            },

                            '& .country-list': {
                                left: 0,
                                right: 0,
                                top: -1,
                                width: `calc(100% + ${theme.spacing(0.25)})`,
                                zIndex: 2,
                                maxHeight: theme.spacing(31.25),
                                borderRadius: theme.shape.smallBorderRadius,
                                border: 'none',

                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: theme.palette.grey[200],
                                    borderRadius: 'inherit',
                                    width: theme.spacing(1.25),
                                },

                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: theme.palette.info.main,
                                    borderRadius: 'inherit',
                                    width: theme.spacing(1),
                                    height: theme.spacing(6.75),
                                },

                                '& .search': {
                                    padding: theme.spacing(2.25, 1.25, 1.25, 1.25),

                                    '&  .search-box': {
                                        padding: theme.spacing(0.625),
                                        borderRadius: theme.shape.smallBorderRadius,
                                    },
                                },

                                '& .country': {
                                    padding: theme.spacing(1, 2),

                                    '&.highlight': {
                                        backgroundColor: theme.palette.info.light,
                                    },
                                },
                            },
                        },
                    },
                },
            }}
        >
            {children}
        </Box>
    );
}
