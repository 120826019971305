import { Message } from 'yup/lib/types';
import { TMTranslationGetter } from '../Hooks/useTMTranslation';
import { CommonResourceKey } from '../i18n/byNamespace/i18nCommonResourceKeys';

interface ValidationErrorsValue {
    Required: string;
    FirstNameExceedsLimit: Message<{ max: number }>;
    LastNameExceedsLimit: Message<{ max: number }>;
    TitleExceedsLimit: Message<{ max: number }>;
    CompanyExceedsLimit: Message<{ max: number }>;
    SchoolNameExceedsLimit: Message<{ max: number }>;
    PhoneNumberIsInvalid: string;
    FieldShouldNotExceed: (maxChar: number) => string;
    PleaseEnterAValidDate: string;
    StartDateCannotBeInTheFuture: string;
    EndDateCannotBeInTheFuture: string;
    StartDateCannotGoBeyond: (limit: Date) => string;
    EmptyFieldError: string;
    NegativeValueError: string;
    SalaryExceedsSystemLimitError: string;
    IncorrectSalaryLimitsError: string;
    InvalidInputError: string;
}

export default function ValidationErrors(t: TMTranslationGetter<CommonResourceKey>): ValidationErrorsValue {
    const Required = t('Required');

    const FirstNameExceedsLimit: Message<{ max: number }> = ({ max }): string => t('FirstNameExceedsLimit', { max });

    const LastNameExceedsLimit: Message<{ max: number }> = ({ max }) => t('LastNameExceedsLimit', { max });

    const TitleExceedsLimit: Message<{ max: number }> = ({ max }) => t('TitleExceedsLimit', { max });

    const CompanyExceedsLimit: Message<{ max: number }> = ({ max }) => t('CompanyExceedsLimit', { max });

    const SchoolNameExceedsLimit: Message<{ max: number }> = ({ max }) => t('SchoolNameExceedsLimit', { max });

    const PhoneNumberIsInvalid = t('PhoneNumberIsInvalid');

    const FieldShouldNotExceed = (maxChar: number) => t('FieldShouldNotExceed', { maxChar });

    const PleaseEnterAValidDate = t('PleaseEnterAValidDate');

    const StartDateCannotBeInTheFuture = t('StartDateCannotBeInTheFuture');

    const EndDateCannotBeInTheFuture = t('EndDateCannotBeInTheFuture');

    const StartDateCannotGoBeyond = (limit: Date) => {
        const formattedLimit = limit.toDateString();
        return t('StartDateCannotGoBeyond', { formattedLimit });
    };

    const EmptyFieldError = t('EmptyFieldError');

    const NegativeValueError = t('NegativeValueError');

    const SalaryExceedsSystemLimitError = t('SalaryExceedsSystemLimitError');

    const IncorrectSalaryLimitsError = t('IncorrectSalaryLimitsError');

    const InvalidInputError = t('InvalidInputError');

    return {
        Required,
        FirstNameExceedsLimit,
        LastNameExceedsLimit,
        TitleExceedsLimit,
        CompanyExceedsLimit,
        SchoolNameExceedsLimit,
        PhoneNumberIsInvalid,
        FieldShouldNotExceed,
        PleaseEnterAValidDate,
        StartDateCannotBeInTheFuture,
        EndDateCannotBeInTheFuture,
        StartDateCannotGoBeyond,
        EmptyFieldError,
        NegativeValueError,
        SalaryExceedsSystemLimitError,
        IncorrectSalaryLimitsError,
        InvalidInputError,
    };
}
