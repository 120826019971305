import { useTheme } from '@mui/material/styles';
import { LinearProgress } from '@talentmesh/core';
import { linearProgressClasses } from '@mui/material';
import React from 'react';

export interface CTMonitoringProgressBarProps {
    value: number;
    color: string;
}

function CTMonitoringProgressBar({ value, color }: CTMonitoringProgressBarProps): JSX.Element {
    if (value > 100 || value < 0) {
        throw new Error('Invalid prop value: should be in range [0, 100]');
    }
    const theme = useTheme();

    return (
        <LinearProgress
            variant="determinate"
            value={value}
            color="primary"
            sx={{
                height: theme.spacing(1.25),
                [linearProgressClasses.root]: {
                    '& .MuiLinearProgress-barColorPrimary': {
                        backgroundColor: color,
                    },
                    backgroundColor: theme.palette.grey[400],
                },
            }}
        />
    );
}

export default CTMonitoringProgressBar;
