import { Paper, PaperProps } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import React from 'react';

function HeaderPaper(props: PaperProps): JSX.Element {
    const theme = useTheme();

    return (
        <Paper
            {...props}
            square
            elevation={0}
            sx={{
                paddingTop: 8,
                paddingBottom: 2,
                paddingX: 0,
                backgroundColor: theme.palette.table.main,
                position: 'fixed',
                width: '100%',
                zIndex: 1,
            }}
        />
    );
}

export default HeaderPaper;
