import React from 'react';
import { Box, Stack, theme, Typography, SpanTypography } from '@talentmesh/core';
import { useAuth0 } from '@auth0/auth0-react';
import useTMTranslation from '../../Hooks/useTMTranslation';

const ForbiddenPageInfo = () => {
    const { user } = useAuth0();
    const { tCommon: t } = useTMTranslation();

    return (
        <>
            <Typography variant="h2" sx={{ color: theme.palette.secondary.main }}>
                {t('AccessDenied')}
            </Typography>
            <Stack
                sx={{
                    borderRadius: theme.shape.smallBorderRadius,
                    backgroundColor: theme.palette.status.main,
                    marginTop: theme.spacing(2),
                    padding: theme.spacing(2),
                }}
                spacing={theme.spacing(2)}
            >
                <Box>
                    <SpanTypography variant="body1">{`${t('WeAreSorryButTheEmail')} `}</SpanTypography>
                    <SpanTypography sx={{ fontWeight: 'bold' }} variant="body1">
                        {user?.email}
                    </SpanTypography>
                    <SpanTypography variant="body1">{` ${t('HasNotBeenInvitedTo')}`}</SpanTypography>
                </Box>
                <Typography variant="body1">{t('PleaseContact')}</Typography>
            </Stack>
        </>
    );
};

export default ForbiddenPageInfo;
