import { useTheme } from '@mui/material';
import React from 'react';
import { Stack, Typography } from '@talentmesh/core';
import RoundedNumber from './RoundedNumber';

interface RuleItemProps {
    value: number;
    header: string;
    body: string;
}

function RuleItem({ value, header, body }: RuleItemProps): JSX.Element {
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            spacing={1.25}
            sx={{
                maxWidth: theme.spacing(48.375),
                paddingX: theme.spacing(2.5),
                paddingTop: theme.spacing(1.25),
                paddingBottom: theme.spacing(2.5),
                color: theme.palette.text.primary,
            }}
        >
            <RoundedNumber value={value} />
            <Stack>
                <Typography variant="h5">{header}</Typography>
                <Typography variant="h5" sx={{ fontWeight: theme.typography.fontWeightRegular }}>
                    {body}
                </Typography>
            </Stack>
        </Stack>
    );
}

export default RuleItem;
