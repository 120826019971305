import React, { createContext, ReactNode, useContext, useState } from 'react';

export interface ScreenSharingContextInterface {
    media: MediaStream | undefined;
    setMedia: (media: MediaStream | undefined) => void;
}

export const ScreenSharingContext = createContext<ScreenSharingContextInterface | undefined>(undefined);

const ScreenSharingContextProvider = ScreenSharingContext.Provider;

interface ScreenSharingProviderProps {
    children: ReactNode;
}

export const ScreenSharingProvider = ({ children }: ScreenSharingProviderProps): JSX.Element => {
    const [media, setMedia] = useState<MediaStream | undefined>();

    return <ScreenSharingContextProvider value={{ media, setMedia }}>{children}</ScreenSharingContextProvider>;
};

export function useScreenSharingContext() {
    const context = useContext(ScreenSharingContext);
    if (!context) {
        throw new Error('useScreenSharingContext must be used within the ScreenSharingContext.Provider');
    }
    return context;
}
