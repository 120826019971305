import { Education } from './Education';
import { Experience } from './Experience';
import { LocationDetails } from './LocationDetails';

export interface ApplicantProfile {
    firstName: string;
    lastName: string;
    email: string;
    location: LocationDetails | null;
    isWillingToRelocate: boolean;
    experiences: Experience[];
    educations: Education[];
}

export const DefaultApplicantProfile: ApplicantProfile = {
    firstName: '',
    lastName: '',
    email: '',
    location: null,
    isWillingToRelocate: false,
    experiences: [],
    educations: [],
};
