import { Grid, GridProps } from '@talentmesh/core';
import React from 'react';

const GridItem: React.FC<GridProps> = (props) => {
    return (
        <Grid
            item
            display="flex"
            alignItems="center"
            sx={{
                marginTop: 0.5,
                marginBottom: 0.5,
            }}
            {...props}
        />
    );
};

export default GridItem;
