import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import ProcessingState from '../Models/ProcessingState';
import { useAssessmentClient } from '../Hooks/ClientHooks';
import {
    ApplicantAssessmentInfo,
    ApplicantAssessmentInfoFilter,
    ApplicantAssessmentInfoPagination,
} from '../Models/ApplicantAssessmentInfo';
import { Pagination } from '../Models/Page';
import useNavigation from '../Hooks/useNavigation';
import useAssessmentId from '../Hooks/useAssessmentId';
import PaginationConstants from '../Utils/PaginationConstants';

export interface ApplicantAssessmentInfoContextState
    extends Pick<Pagination, 'totalCount'>,
        Pick<ApplicantAssessmentInfoPagination<any>, 'totalOpen' | 'totalHired' | 'totalRejected'> {
    assessments: Array<ApplicantAssessmentInfo>;
    lastUpdatedAssessmentId: string;
    processingState: ProcessingState;
    initialPageLoading: boolean;
    initialTabLoading: boolean;
    filterBy: ApplicantAssessmentInfoFilter;
    search: string;
}

export interface ApplicantAssessmentInfoContextType extends ApplicantAssessmentInfoContextState {
    loadAssessmentAsync: (offset: number) => Promise<void>;
    setFilterBy: (filter: ApplicantAssessmentInfoFilter) => void;
    setSearch: (value: string) => void;
}

export const ApplicantAssessmentInfoContext = createContext<ApplicantAssessmentInfoContextType | undefined>(undefined);

const ApplicantAssessmentInfoContextProvider = ApplicantAssessmentInfoContext.Provider;

interface ApplicantAssessmentInfoProps {
    children: ReactNode;
}

export const ApplicantAssessmentInfoProvider = ({ children }: ApplicantAssessmentInfoProps): JSX.Element => {
    const client = useAssessmentClient();
    const { replaceWithJobApplicationsPage } = useNavigation();
    const assessmentId = useAssessmentId();

    const [state, setState] = useState<ApplicantAssessmentInfoContextState>({
        assessments: [],
        lastUpdatedAssessmentId: '',
        processingState: ProcessingState.Processing,
        initialPageLoading: true,
        initialTabLoading: true,
        totalCount: 0,
        filterBy: ApplicantAssessmentInfoFilter.Open,
        search: '',
        totalOpen: 0,
        totalHired: 0,
        totalRejected: 0,
    });

    const {
        assessments,
        lastUpdatedAssessmentId,
        processingState,
        initialPageLoading,
        initialTabLoading,
        totalCount,
        filterBy,
        search,
        totalOpen,
        totalHired,
        totalRejected,
    } = state;

    const loadAssessmentAsync = async (offset: number): Promise<void> => {
        try {
            setState((prev) => {
                return {
                    ...prev,
                    processingState: ProcessingState.Processing,
                };
            });

            const page = await client.getApplicantAssessmentInfosAsync(
                offset,
                PaginationConstants.RegularPageSize,
                filterBy,
                search,
            );

            setState((prev) => {
                return {
                    ...prev,
                    assessments: offset === 0 ? page.data : [...prev.assessments, ...page.data],
                    lastUpdatedAssessmentId: initialPageLoading ? assessmentId : '',
                    processingState: ProcessingState.Success,
                    initialPageLoading: false,
                    initialTabLoading: false,
                    totalCount: page.pagination.totalCount,
                    totalOpen: page.totalOpen,
                    totalHired: page.totalHired,
                    totalRejected: page.totalRejected,
                };
            });
        } catch (error: any) {
            if (error && error.status === 403 && error.type === 'canceled_subscription') {
                replaceWithJobApplicationsPage();
                return;
            }

            setState((prev) => {
                return {
                    ...prev,
                    processingState: ProcessingState.Error,
                    initialTabLoading: false,
                };
            });
        }
    };

    const setFilterBy = (filter: ApplicantAssessmentInfoFilter) => {
        setState((prev) => {
            return {
                ...prev,
                initialTabLoading: true,
                filterBy: filter,
            };
        });
    };

    const setSearch = (value: string) => {
        setState((prev) => {
            return {
                ...prev,
                search: value.length > 1 ? value : '',
            };
        });
    };

    useEffect(() => {
        const doLoadAssessment = async () => {
            await loadAssessmentAsync(PaginationConstants.InitialOffset);
        };
        doLoadAssessment();
    }, [filterBy, search]);

    return (
        <ApplicantAssessmentInfoContextProvider
            value={{
                assessments,
                lastUpdatedAssessmentId,
                processingState,
                initialPageLoading,
                initialTabLoading,
                filterBy,
                search,
                totalCount,
                totalOpen,
                totalHired,
                totalRejected,

                loadAssessmentAsync,
                setFilterBy,
                setSearch,
            }}
        >
            {children}
        </ApplicantAssessmentInfoContextProvider>
    );
};

export function useApplicantAssessmentInfoContext() {
    const context = useContext(ApplicantAssessmentInfoContext);
    if (!context) {
        throw new Error(
            'useApplicantAssessmentInfoContext must be used within the ApplicantAssessmentInfoContext.Provider',
        );
    }
    return context;
}
