import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { NonInteractableButton, Menu, MenuItem } from '@talentmesh/core';
import { LanguageOutlined } from '@mui/icons-material';
import useTMTranslation from '../Hooks/useTMTranslation';
import { CommonLanguageCode, CommonLanguageCodes } from '../i18n/i18nTypes';
import { useLocalizationContext } from '../Context/LocalizationContext';
import mapLocaleCodeToLanguageName from '../Utils/mapLocaleCodeToLanguageName';

const LanguageMenu = () => {
    const theme = useTheme();
    const {
        tCommon: t,
        current: { language },
    } = useTMTranslation();

    const { updateAppLanguage } = useLocalizationContext();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeLanguageByCode = async (locale: CommonLanguageCode) => {
        await updateAppLanguage(locale, true);
        handleClose();
    };

    const menuItems = CommonLanguageCodes.map((item) => (
        <MenuItem
            key={item}
            onClick={() => changeLanguageByCode(item)}
            sx={{ height: 'auto', padding: theme.spacing(1, 2) }}
        >
            {mapLocaleCodeToLanguageName(item, t)}
        </MenuItem>
    ));

    return (
        <>
            <NonInteractableButton
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="text"
                color="secondary"
                disableElevation
                onClick={(event) => handleClick(event)}
                startIcon={<LanguageOutlined />}
                sx={{ backgroundColor: 'transparent' }}
                size="large"
            >
                {language && language.toUpperCase()}
            </NonInteractableButton>
            <Menu
                anchorEl={anchorEl}
                onClose={handleClose}
                open={open}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {menuItems}
            </Menu>
        </>
    );
};

export default LanguageMenu;
