import React from 'react';
import { generatePath } from 'react-router';
import { Navigate } from 'react-router-dom';
import RouteParam from '../../Routing/RouteParam';
import withGooglePlacesAPICheck from '../../Routing/withGooglePlacesAPICheck';
import withTalentMeshUserRequired from '../../Routing/withTalentMeshUserRequired';
import { OnBoardingContextProvider, useOnBoardingContext } from '../../Context/OnBoardingContext';
import ProcessingState from '../../Models/ProcessingState';
import RoutePath from '../../Routing/RoutePath';
import Page from '../../Components/Pages/Page';
import useNavigation from '../../Hooks/useNavigation';
import PageContentLoading from '../../Components/Pages/PageContentLoading';
import ApplicantOnboardStepper from './Steps/ApplicantOnboardStepper';

function CandidateOnboardPageInner(): JSX.Element {
    const { navigateToJobApplicationsPage, replaceWithRecruitmentClosedPage } = useNavigation();
    const {
        info: { isOnBoardingFormSubmitted, inProgressTestId, assessmentId, isRecruitmentAvailable },
        processingState,
    } = useOnBoardingContext();

    if (processingState === ProcessingState.Error) return <Navigate to={RoutePath.Error} />;

    if (isOnBoardingFormSubmitted) {
        // continue test
        if (inProgressTestId) {
            const path = generatePath(RoutePath.AssessmentTest, {
                [RouteParam.AssessmentId]: assessmentId,
                [RouteParam.TestId]: inProgressTestId,
            });
            // history.push causes render error here
            return <Navigate to={path} />;
        }
        // don't need to onboard twice
        navigateToJobApplicationsPage();
    } else if (!isRecruitmentAvailable) {
        // don't need to onboard in closed assessment
        replaceWithRecruitmentClosedPage();
    }

    return (
        <Page>
            <PageContentLoading isLoading={processingState === ProcessingState.Processing}>
                <ApplicantOnboardStepper />
            </PageContentLoading>
        </Page>
    );
}

function CandidateOnboardPage(): JSX.Element {
    return (
        <OnBoardingContextProvider>
            <CandidateOnboardPageInner />
        </OnBoardingContextProvider>
    );
}

export default withTalentMeshUserRequired(withGooglePlacesAPICheck(CandidateOnboardPage));
