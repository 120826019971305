import { createContext, useContext, useState } from 'react';

interface JobExperienceListContextState {
    hasExperience: boolean;
    subformsInEditCounter: number;
}

type JobExperienceListContextType = Omit<JobExperienceListContextState, 'subformsInEditCounter'> & {
    editing: boolean;
    setHasExperience: (value: boolean) => void;
    incrementEditingSubformsCounter: () => void;
    decrementEditingSubformsCounter: () => void;
    resetEditingSubformsCounter: () => void;
};

const JobExperienceListContext = createContext<JobExperienceListContextType | undefined>(undefined);
export const JobExperienceListContextProvider = JobExperienceListContext.Provider;

export const useJobExperienceListContextValue = (
    defaultHasExperience: boolean,
    defaultSubformsInEditCounter: number,
): JobExperienceListContextType => {
    const [state, setState] = useState<JobExperienceListContextState>({
        subformsInEditCounter: defaultSubformsInEditCounter,
        hasExperience: defaultHasExperience,
    });
    const { hasExperience, subformsInEditCounter } = state;

    const setHasExperience = (value: boolean) => {
        setState((prevState) => {
            return {
                ...prevState,
                hasExperience: value,
            };
        });
    };

    const incrementEditingSubformsCounter = () => {
        setState((prevState) => {
            return {
                ...prevState,
                subformsInEditCounter: prevState.subformsInEditCounter + 1,
            };
        });
    };

    const decrementEditingSubformsCounter = () => {
        setState((prevState) => {
            return {
                ...prevState,
                subformsInEditCounter: prevState.subformsInEditCounter - 1,
            };
        });
    };

    const resetEditingSubformsCounter = () => {
        setState((prevState) => {
            return {
                ...prevState,
                subformsInEditCounter: 0,
            };
        });
    };

    return {
        hasExperience,
        editing: subformsInEditCounter > 0,

        setHasExperience,
        incrementEditingSubformsCounter,
        decrementEditingSubformsCounter,
        resetEditingSubformsCounter,
    };
};

export function useJobExperienceListContext() {
    const context = useContext(JobExperienceListContext);
    if (!context) {
        throw new Error('useJobExperienceListContext must be used within the JobExperienceListContext.Provider');
    }
    return context;
}
