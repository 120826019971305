import { useTheme } from '@mui/material';
import React from 'react';
import Body2Typography from './Body2Typography';
import GridItem from './GridItem';

interface GridLabelProps {
    label: string;
}
const GridLabel: React.FC<GridLabelProps> = ({ label }) => {
    const theme = useTheme();
    return (
        <GridItem xs={4}>
            <Body2Typography color={theme.palette.grey[500]}>{`${label}:`}</Body2Typography>
        </GridItem>
    );
};

export default GridLabel;
