import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Divider, Typography } from '@talentmesh/core';
import useTMTranslation from '../../../../../../Hooks/useTMTranslation';

function EndOfJobApplicationList() {
    const theme = useTheme();
    const { tCommon: t } = useTMTranslation();

    return (
        <Box sx={{ width: '100%', marginY: theme.spacing(3.75) }}>
            <Divider color={theme.palette.text.lightText} sx={{ maxWidth: theme.spacing(36.5), marginX: 'auto' }}>
                <Typography variant="body2" sx={{ color: theme.palette.text.lightText }}>
                    {t('NoMoreJobsApplicationToDisplay')}
                </Typography>
            </Divider>
        </Box>
    );
}

export default EndOfJobApplicationList;
