import React from 'react';
import { useField } from 'formik';
import { useTheme } from '@mui/material';
import { FormControlLabel, RadioGroup, FormControl, Radio } from '@talentmesh/core';
import { Answer } from '../../../../Models/Answer';
import HtmlViewer from '../../../../Components/HtmlViewer';

interface RadioAnswersProps {
    name: string;
    answers: Array<Answer>;
}

function RadioAnswers({ name, answers }: RadioAnswersProps): JSX.Element {
    const [field, , helpers] = useField(name);
    const theme = useTheme();

    return (
        <FormControl>
            <RadioGroup
                value={field.value}
                onChange={(event) => {
                    helpers.setValue(event.currentTarget.value);
                }}
            >
                {answers.map((answer) => (
                    <FormControlLabel
                        key={answer.answerId}
                        value={answer.answerId}
                        label={<HtmlViewer html={answer.answerText} />}
                        control={<Radio />}
                        sx={{
                            marginTop: theme.spacing(1),
                            marginBottom: theme.spacing(1),
                        }}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
}

export default RadioAnswers;
