import { ApplicantProfile } from '../../Models/ApplicantProfile';
import {
    mapEducationFieldToDTO,
    mapExperienceToDTO,
} from '../../Pages/MyAssessment/ProfileSettings/utils/ApplicantProfileUtils';
import autoMapping from '../../Utils/autoMapping';
import { DefaultEducationDTO, EducationDTO } from '../Users/EducationDTO';
import { DefaultExperienceDTO, ExperienceDTO } from '../Users/ExperienceDTO';
import LocationDetailsDTO from './LocationDetailsDTO';

export interface ApplicantProfileDTO {
    firstName?: string;
    lastName?: string;
    email: string;
    location: LocationDetailsDTO | null;
    isWillingToRelocate: boolean;
    experiences: ExperienceDTO[];
    educations: EducationDTO[];
}

export const DefaultApplicantProfileDTO: ApplicantProfileDTO = {
    firstName: '',
    lastName: '',
    email: '',
    location: null,
    isWillingToRelocate: false,
    experiences: [],
    educations: [],
};

export function mapApplicantProfile2DTO(model: ApplicantProfile): ApplicantProfileDTO {
    const dto = autoMapping(model, DefaultApplicantProfileDTO);

    const { experiences, educations } = model;

    dto.experiences = experiences.map((x) => {
        const experience = autoMapping(x, DefaultExperienceDTO);
        experience.jobExperience = mapExperienceToDTO(x.jobExperience);
        return experience;
    });

    dto.educations = educations.map((x) => {
        const education = autoMapping(x, DefaultEducationDTO);
        education.educationField = mapEducationFieldToDTO(x.educationField);
        return education;
    });

    return dto;
}
