import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Stack } from '@talentmesh/core';
import CTMonitoringPageHeader from './Components/CTMonitoringPageHeader';
import CTMonitoringTogglesForm from './Components/CTMonitoringTogglesForm';
import Page from '../../Components/Pages/Page';
import { AssessmentActionsProvider } from '../../Context/AssessmentActionsContext';
import { DialogsProvider } from '../../Context/DialogsContext';

function CandidateTestMonitoringPageInner(): JSX.Element {
    const theme = useTheme();
    return (
        <Page>
            <Stack
                spacing={2.5}
                sx={{
                    marginTop: theme.spacing(3.75),
                }}
            >
                <CTMonitoringPageHeader />
                <CTMonitoringTogglesForm />
            </Stack>
        </Page>
    );
}

function CandidateTestMonitoringPage(): JSX.Element {
    return (
        <DialogsProvider>
            <AssessmentActionsProvider>
                <CandidateTestMonitoringPageInner />
            </AssessmentActionsProvider>
        </DialogsProvider>
    );
}

export default CandidateTestMonitoringPage;
