import React from 'react';
import { Box, Divider } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import TestInfoList from './components/TestInfoList';
import AssessmentSectionHeader from './components/AssessmentSectionHeader';
import AssessmentActionSection from '../AssessmentActionSection/AssessmentActionSection';
import { customEaseCurve } from '../../../../../animations/JobApplicationAnimation';
import { ApplicantAssessmentInfo } from '../../../../../../../../Models/ApplicantAssessmentInfo';
import { ApplicantAssessmentInfoStatus } from '../../../../../../../../DTO/Assessments/ApplicantAssessmentInfoDTO';
import {
    getApplicantAssessmentSubmittedState,
    getCardAnimationTrigger,
    getCardHeightByStatus,
    getCardAnimationByStatus,
} from '../../../../../utils/JobApplicationUtil';

interface AssessmentSectionProps {
    assessment: ApplicantAssessmentInfo;
    applicantAssessmentInfoStatus: ApplicantAssessmentInfoStatus;
    isUpdated: boolean;
}

function AssessmentSection({ assessment, applicantAssessmentInfoStatus, isUpdated }: AssessmentSectionProps) {
    const theme = useTheme();

    const { id, tests, recruiterFirstName, companyName, assessmentStatus, startedAt, completedAt } = assessment;

    const isSubmitted = completedAt !== null || getApplicantAssessmentSubmittedState(applicantAssessmentInfoStatus);
    const isOngoing = completedAt === null && startedAt !== null; // used to be `applicantAssessmentInfoStatus === ApplicantAssessmentInfoStatus.Ongoing;` but was changed to support assessment execution when invited for interview
    const triggerAnimation = isUpdated && getCardAnimationTrigger(applicantAssessmentInfoStatus);
    const triggerShrink = isUpdated && applicantAssessmentInfoStatus === ApplicantAssessmentInfoStatus.Submitted;

    return (
        <Box
            data-cy="JobApplicationCardAssessmentSection"
            sx={{
                marginTop: theme.spacing(1.25),
                marginBottom: theme.spacing(2.5),
                overflow: 'hidden',
                height: triggerAnimation ? getCardHeightByStatus(theme, applicantAssessmentInfoStatus) : 'auto',
                animationName: triggerAnimation
                    ? `${getCardAnimationByStatus(theme, applicantAssessmentInfoStatus)}`
                    : 'none',
                animationDuration: '.3s',
                animationDelay: '.9s',
                animationTimingFunction: customEaseCurve,
                animationFillMode: 'forwards',
            }}
        >
            <Divider sx={{ borderColor: theme.palette.border.main, marginBottom: theme.spacing(1.25) }} />
            <Box sx={{ marginX: theme.spacing(2.5) }}>
                <AssessmentSectionHeader
                    recruiterFirstName={recruiterFirstName}
                    tests={tests}
                    isSubmitted={isSubmitted}
                />
                <TestInfoList
                    tests={tests}
                    isSubmitted={isSubmitted}
                    isUpdated={isUpdated && isOngoing}
                    triggerShrink={triggerShrink}
                />
                {!isSubmitted && (
                    <AssessmentActionSection
                        isOngoing={isOngoing}
                        id={id}
                        tests={tests}
                        companyName={companyName}
                        assessmentStatus={assessmentStatus}
                    />
                )}
            </Box>
        </Box>
    );
}

export default AssessmentSection;
