import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Stack, Typography } from '@talentmesh/core';
import { WarningAmberIcon } from '@talentmesh/icons';

interface WarningLabelProps {
    text: string;
}

function WarningLabel({ text }: WarningLabelProps): JSX.Element {
    const theme = useTheme();
    return (
        <Stack direction="row" alignContent="center" spacing={1}>
            <WarningAmberIcon />
            <Typography
                variant="body1"
                sx={{
                    fontWeight: 'bold',
                }}
                color={theme.palette.text.menuTitle}
            >
                {text}
            </Typography>
        </Stack>
    );
}

export default WarningLabel;
