import { useTheme } from '@mui/material';
import { Paper, Stack, Typography } from '@talentmesh/core';
import React from 'react';

export interface InformationHeaderProps {
    title: string;
    message: string;
}
function InformationHeader({ title, message }: InformationHeaderProps): JSX.Element {
    const theme = useTheme();

    return (
        <Paper
            data-cy="InformationHeader"
            elevation={0}
            sx={{
                padding: 2.5,
                border: `1px solid ${theme.palette.border.main}`,
                backgroundColor: theme.palette.background.aliciaBlue,
            }}
        >
            <Stack spacing={3}>
                <Typography variant="h3" color={theme.palette.secondary.main}>
                    {title}
                </Typography>
                <Typography variant="body1" color={theme.palette.secondary.main}>
                    {message}
                </Typography>
            </Stack>
        </Paper>
    );
}

export default InformationHeader;
