import React from 'react';
import { Stack, Typography } from '@talentmesh/core';
import { FormikTextFieldBase } from '@talentmesh/forms';
import { useTheme } from '@mui/material';
import NumberFormatter from './NumberFormatter';
import { RecruitmentInformationSalaryExpectation } from '../../../RecruitmentInformationUtils';
import useTMTranslation from '../../../../../../../Hooks/useTMTranslation';

function YearlySalaryRange(): JSX.Element {
    const theme = useTheme();
    const { tCommon } = useTMTranslation();

    return (
        <Stack spacing={theme.spacing(1)} flex={1}>
            <Typography color={theme.palette.text.menuTitle} variant="h6">
                {tCommon('ExpectedYearlySalaryRange')}
            </Typography>
            <Stack direction="row" spacing={theme.spacing(1.125)} alignItems="center">
                <FormikTextFieldBase
                    name={RecruitmentInformationSalaryExpectation.SalaryExpectationMin}
                    data-cy={RecruitmentInformationSalaryExpectation.SalaryExpectationMin}
                    InputProps={{
                        inputComponent: NumberFormatter,
                    }}
                />
                <Typography color={theme.palette.text.menuTitle} variant="h6">
                    -
                </Typography>
                <FormikTextFieldBase
                    name={RecruitmentInformationSalaryExpectation.SalaryExpectationMax}
                    data-cy={RecruitmentInformationSalaryExpectation.SalaryExpectationMax}
                    InputProps={{
                        inputComponent: NumberFormatter,
                    }}
                />
            </Stack>
        </Stack>
    );
}

export default YearlySalaryRange;
