import { useTheme } from '@mui/material';
import { Typography } from '@talentmesh/core';
import React from 'react';

interface CustomTitleProps {
    labelLeft: string;
    labelMiddle: string;
    labelRight: string;
}
const CustomTitle: React.FC<CustomTitleProps> = ({ labelLeft, labelMiddle, labelRight }) => {
    const theme = useTheme();
    return (
        <Typography variant="body1">
            <span>{labelLeft}</span>
            <span style={{ fontWeight: theme.typography.fontWeightBold }}>{` ${labelMiddle} `}</span>
            <span>{labelRight}</span>
        </Typography>
    );
};

export default CustomTitle;
