import { AppState, Auth0Provider } from '@auth0/auth0-react';
import React, { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { authProviderSettings, tokenStorage } from '../AppSettings';

interface Props {
    children?: ReactNode;
}

const Auth0ProviderWithNavigate = ({ children }: Props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const onRedirectCallback = (appState?: AppState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };
    let providerSettings;
    const search = new URLSearchParams(location.search);
    const hint = search.get('hint');
    const email = search.get('email');

    if (hint) {
        providerSettings = {
            ...authProviderSettings,
            hint,
        };
    } else {
        providerSettings = {
            ...authProviderSettings,
            hint: 'passwordless',
        };
    }

    if (email) {
        providerSettings = {
            ...providerSettings,
            email,
        };
    }

    return (
        <Auth0Provider
            {...providerSettings}
            onRedirectCallback={onRedirectCallback}
            cacheLocation={tokenStorage}
            useRefreshTokens
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithNavigate;
