import React from 'react';
import useTMTranslation from '../../../../../../Hooks/useTMTranslation';
import ApplicantInformationType from '../../../../../../Models/ApplicantInformationType';
import PhoneNumberFiled from './PhoneNumber/PhoneNumberField';

function ApplicantPhoneNumber(): JSX.Element {
    const { tCommon: t } = useTMTranslation();
    return <PhoneNumberFiled label={`${t('PhoneNumber')}*`} name={ApplicantInformationType.PhoneNumber} />;
}

export default ApplicantPhoneNumber;
