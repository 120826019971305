/* eslint-disable @typescript-eslint/lines-between-class-members */

// TODO: investigate/refactor to replace it with nameof()
export default class ProfileSettingsFieldsNames {
    static readonly FirstName = 'firstName';
    static readonly LastName = 'lastName';
    static readonly Location = 'location';
    static readonly IsWillingToRelocate = 'isWillingToRelocate';
    static readonly EducationLevel = 'educationLevel';
    static readonly EducationField = 'educationField';
    static readonly NoJobExperience = 'noJobExperience';
    static readonly JobExperience = 'jobExperience';
    static readonly YearsOfExperience = 'yearsOfExperience';
}
