import { QuestionDTO } from '../DTO/Questions/QuestionDTO';
import { Answer, mapFromAnswerDTO } from './Answer';
import QuestionType from './QuestionType';

export interface Question {
    id: string;
    questionText: string;
    answers: Answer[];
    questionType: QuestionType;
    blobFileURI: string;
    fileMimeType: string;
    topic: string;
    subTopic: string;
    likertAnswerGroupId: string;
}

export const DefaultQuestion: Question = {
    id: '',
    questionText: '',
    answers: [],
    questionType: QuestionType.Exact,
    blobFileURI: '',
    fileMimeType: '',
    topic: '',
    subTopic: '',
    likertAnswerGroupId: '',
};

export function mapFromQuestionDTO(dto: QuestionDTO): Question {
    return {
        id: dto.id,
        questionText: dto.questionText,
        answers: (dto.answers || []).map((item) => mapFromAnswerDTO(item)),
        questionType: dto.questionType,
        blobFileURI: dto.blobFileURI,
        fileMimeType: dto.fileMimeType,
        topic: dto.topic,
        subTopic: dto.subTopic,
        likertAnswerGroupId: dto.likertAnswerGroupId,
    };
}
