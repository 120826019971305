import React from 'react';
import { Link, Stack, theme, Typography } from '@talentmesh/core';
import { siteUrls } from '../AppSettings';
import useTMTranslation from '../Hooks/useTMTranslation';

const NotFoundPageInfo = () => {
    const { tCommon: t } = useTMTranslation();

    return (
        <>
            <Typography variant="h2" sx={{ color: theme.palette.secondary.main }}>
                {t('Found404')}
            </Typography>
            <Stack
                sx={{
                    borderRadius: theme.shape.smallBorderRadius,
                    backgroundColor: theme.palette.status.main,
                    marginTop: theme.spacing(2),
                    padding: theme.spacing(2),
                }}
                spacing={theme.spacing(2)}
            >
                <Typography variant="body1">
                    {`${t('PageNotFoundTextBeforeLink')} `}
                    <Link variant="body1" href={siteUrls.contactSupportUrl}>
                        {t('ContactUsCapitalized')}
                    </Link>
                    {` ${t('PageNotFoundTextAfterLink')}`}
                </Typography>
                <Typography>{t('LookingForAssessment')}</Typography>
            </Stack>
        </>
    );
};

export default NotFoundPageInfo;
