import React, { createContext, ReactNode, useContext, useState } from 'react';
import { generatePath } from 'react-router';
import { useAssessmentClient } from '../Hooks/ClientHooks';
import RoutePath from '../Routing/RoutePath';
import { CandidateAssessmentInfo } from '../Models/CandidateAssessmentInfo';
import RouteParam from '../Routing/RouteParam';

export interface AssessmentActionsContextState {
    // we don't really need this one anymore, just add end-point to get unfinished test id (task 3907)
    candidateAssessmentInfos: CandidateAssessmentInfo[];
    unfinishedTestPath: string;
    unfinishedTestCompany: string;
    activeAssessmentId: string;
}

export interface AssessmentActionsContextType extends AssessmentActionsContextState {
    // we don't really need this one anymore, just add end-point to get unfinished test id (task 3907)
    findUnfinishedTestAsync: (assessmentIdToExclude: string) => Promise<CandidateAssessmentInfo | null>;
    setActiveAssessmentId: (id: string) => void;
}

export const AssessmentActionsContext = createContext<AssessmentActionsContextType | undefined>(undefined);

const AssessmentActionsContextProvider = AssessmentActionsContext.Provider;

interface AssessmentActionsContextProps {
    children: ReactNode;
}

export const AssessmentActionsProvider = ({ children }: AssessmentActionsContextProps): JSX.Element => {
    const client = useAssessmentClient();

    const [state, setState] = useState<AssessmentActionsContextState>({
        candidateAssessmentInfos: [],
        unfinishedTestPath: '',
        unfinishedTestCompany: '',
        activeAssessmentId: '',
    });

    const setActiveAssessmentId = (id: string) => {
        setState((prev) => {
            return {
                ...prev,
                activeAssessmentId: id,
            };
        });
    };

    const setCandidateAssessmentInfos = (infos: CandidateAssessmentInfo[]) => {
        setState((prev) => {
            return {
                ...prev,
                candidateAssessmentInfos: infos,
            };
        });
    };

    const findUnfinishedTestAsync = async (assessmentIdToExclude: string): Promise<CandidateAssessmentInfo | null> => {
        const infos = await client.getCandidateAssessmentInfos();
        setCandidateAssessmentInfos(infos);
        const unfinishedTests = infos.filter(
            (x) => x.assessmentId !== assessmentIdToExclude && x.activeTestId !== null,
        );

        // We expect to have only one unfinished test at a time therefore we always return the first unfinished test
        const unfinishedTest = unfinishedTests.length > 0 ? unfinishedTests[0] : null;

        if (unfinishedTest !== null) {
            const { assessmentId, activeTestId, companyName } = unfinishedTest;
            const path = generatePath(RoutePath.AssessmentTest, {
                [RouteParam.AssessmentId]: assessmentId,
                [RouteParam.TestId]: activeTestId ?? '',
            });
            setState((prev) => {
                return {
                    ...prev,
                    unfinishedTestPath: path,
                    unfinishedTestCompany: companyName,
                };
            });
        }

        return unfinishedTest;
    };

    return (
        <AssessmentActionsContextProvider
            value={{
                ...state,

                setActiveAssessmentId,
                findUnfinishedTestAsync,
            }}
        >
            {children}
        </AssessmentActionsContextProvider>
    );
};

export function useAssessmentActionsContext() {
    const context = useContext(AssessmentActionsContext);
    if (!context) {
        throw new Error('useAssessmentActionsContext must be used within the AssessmentActionsContext.Provider');
    }
    return context;
}
