/* eslint-disable no-useless-return */
/* eslint-disable consistent-return */
import { fromCandidateTestMonitoring } from '../DTO/AntiCheat/CandidateTestMonitoringDTO';
import { TestInfoDTO } from '../DTO/Tests/TestInfoDTO';
import { TestStateDTO } from '../DTO/Tests/TestStateDTO';
import { CandidateTestMonitoring } from '../Models/CandidateTestMonitoringSettings';
import { DefaultTestInfoModel, mapFromTestInfoDTO, TestInfo } from '../Models/TestInfo';
import { DefaultTestState, mapFromTestStateDTO, TestState } from '../Models/TestState';
import { get, post, toJson } from '../Utils/HTTP/HttpUtils';
import ClientBase from './ClientBase';

export default class TestClient extends ClientBase {
    async getTestStateAsync(assessmentId: string, testId: string): Promise<TestState> {
        const response = await get<TestStateDTO>({
            path: this.addHost(`tests/${testId}/state?assessmentId=${assessmentId}`),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (response.ok && response.body) {
            return mapFromTestStateDTO(response.body);
        }
        return DefaultTestState;
    }

    async startTestAsync(assessmentId: string, testId: string, settings: CandidateTestMonitoring): Promise<void> {
        const dto = fromCandidateTestMonitoring(assessmentId, settings);
        await post({
            accessToken: await this.getAccessTokenSilently(),
            path: this.addHost(`tests/${testId}/start`),
            ...toJson(dto),
        });
    }

    async completeTestAsync(assessmentId: string, testId: string): Promise<TestInfo> {
        const path = this.addHost(`tests/${testId}/complete`);
        const response = await post<TestInfoDTO>({
            path,
            accessToken: await this.getAccessTokenSilently(),
            ...toJson({
                assessmentId,
            }),
        });
        if (response.ok && response.body) {
            return mapFromTestInfoDTO(response.body);
        }
        return DefaultTestInfoModel;
    }
}
