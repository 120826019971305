import React from 'react';
import { Stack, Typography, DropdownOption, Box } from '@talentmesh/core';
import { FormikDropdown } from '@talentmesh/forms';
import { useTheme } from '@mui/material/styles';
import BaseSection from './BaseSection';
import useTMTranslation from '../../../Hooks/useTMTranslation';
import mapLocaleCodeToLanguageName from '../../../Utils/mapLocaleCodeToLanguageName';
import { LocalizationContextValue } from '../../../Context/LocalizationContext';

const TestLanguageDropdownId = 'locale';

interface WelcomePageTestLanguageSectionProps extends Pick<LocalizationContextValue, 'availableTestLanguage'> {}

function WelcomePageTestLanguageSection({ availableTestLanguage }: WelcomePageTestLanguageSectionProps): JSX.Element {
    const theme = useTheme();
    const { tCommon: t, tTest } = useTMTranslation();

    const isLanguageSet = availableTestLanguage.length === 1;

    const label = tTest(
        isLanguageSet ? 'TheRecruitersHaveSetTheLanguageForThisTestToBe' : 'YouCanTakeTheTestsInYourPreferredLanguage',
    );

    const options: DropdownOption[] = availableTestLanguage.map((language) => {
        return { id: language, name: mapLocaleCodeToLanguageName(language, t) };
    });

    return (
        <BaseSection header={tTest('TestLanguage')}>
            <Stack
                direction="row"
                spacing={1.25}
                sx={{
                    paddingX: theme.spacing(2.5),
                    paddingTop: theme.spacing(1.25),
                    paddingBottom: theme.spacing(2.5),
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Typography variant="h5">{label}</Typography>
                {isLanguageSet ? (
                    <Typography
                        variant="body1"
                        sx={{
                            color: theme.palette.secondary.main,
                            border: `1px solid ${theme.palette.border.main}`,
                            padding: theme.spacing(1, 1.75),
                            borderRadius: theme.shape.smallBorderRadius,
                        }}
                    >
                        {options[0].name}
                    </Typography>
                ) : (
                    <Box sx={{ maxWidth: theme.spacing(30.5), width: '100%' }}>
                        <FormikDropdown
                            name={TestLanguageDropdownId}
                            data-cy={TestLanguageDropdownId}
                            options={options}
                        />
                    </Box>
                )}
            </Stack>
        </BaseSection>
    );
}

export default WelcomePageTestLanguageSection;
