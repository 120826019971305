import { useTheme } from '@mui/material';
import { Button } from '@talentmesh/core';
import { AddIcon } from '@talentmesh/icons';
import { useFieldArray, useFormContext } from 'react-hook-form';
import React, { useCallback, useEffect, useMemo } from 'react';
import {
    DefaultEducationFormValues,
    EducationFormValues,
} from '../../../MyAssessment/ProfileSettings/utils/ApplicantProfileUtils';
import ProfilePaper from '../Common/ProfilePaper';
import { Education } from './Education';
import { EducationListContextProvider, useEducationListContextValue } from './EducationListContext';
import EducationsFieldsNames from './EducationsFieldsNames';
import EducationListHeader from './EducationListHeader';
import useTMTranslation from '../../../../Hooks/useTMTranslation';
import isEducationEditRequired from './isEducationEditRequired';

const name = EducationsFieldsNames.EducationsName;

interface EducationListProps {
    defaultHasEducation: boolean;
    defaultIncompleteEducationCount: number;
}

function EducationList({ defaultHasEducation, defaultIncompleteEducationCount }: EducationListProps): JSX.Element {
    const theme = useTheme();
    const { tCommon: t } = useTMTranslation();

    const { control } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        control,
        name,
    });

    const contextValue = useEducationListContextValue(defaultHasEducation, defaultIncompleteEducationCount);
    const { hasEducation, editing, resetEditingSubformsCounter, incrementEditingSubformsCounter, setHasEducation } =
        contextValue;

    const handleToggleEducation = (value: boolean) => {
        setHasEducation(value);

        if (value && !fields.length) {
            append(DefaultEducationFormValues);
            incrementEditingSubformsCounter();
        }

        if (!value) {
            resetEditingSubformsCounter();
            remove();
        }
    };

    return (
        <EducationListContextProvider value={contextValue}>
            <ProfilePaper header={t('Education')}>
                <EducationListHeader onChange={handleToggleEducation} />

                {hasEducation && (
                    <>
                        {fields.map((field, index) => {
                            return (
                                <Education
                                    key={field.id}
                                    initialIsEdit={isEducationEditRequired(field as any as EducationFormValues)}
                                    index={index}
                                    onRemove={() => {
                                        remove(index);
                                    }}
                                    onCancel={() => {
                                        remove(index);
                                    }}
                                />
                            );
                        })}
                        <Button
                            startIcon={<AddIcon />}
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={() => {
                                append({ ...DefaultEducationFormValues });
                                incrementEditingSubformsCounter();
                            }}
                            disabled={editing}
                            sx={{
                                backgroundColor: theme.palette.common.white,
                                '&:disabled': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                        >
                            {t('AddAdditionalEducation')}
                        </Button>
                    </>
                )}
            </ProfilePaper>
        </EducationListContextProvider>
    );
}

export default EducationList;
