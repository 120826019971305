import { useEffect, useState } from 'react';
import { getPageSize } from '../Components/SkillsPageUtils';
import {
    DefaultStepperSkillsContextState,
    Step,
    StepperSkillsContextData,
    StepperSkillsContextState,
    StepStatuses,
} from './StepperSkillsContext';

function useStepperSkillsContextValue(): StepperSkillsContextData {
    const [contextState, setContextState] = useState<StepperSkillsContextState>(DefaultStepperSkillsContextState);
    const [visibleRange, setVisibleRange] = useState(0);

    useEffect(() => {
        return () => {
            window.onresize = null;
        };
    }, []);

    const getLastCompletedIndex = (initialValues: Array<Step>): number => {
        let lastCompletedIndex = 0;
        for (let i = 0; i < initialValues.length - 1; i++) {
            if (initialValues[i].status === StepStatuses.Answered) {
                lastCompletedIndex = i;
            }
        }
        return lastCompletedIndex;
    };

    const evaluateRange = (
        initialRange: number,
        lastCompletedIndex: number,
        initialValuesLength: number,
    ): Array<number> => {
        let start = lastCompletedIndex - initialRange / 2;
        let stepsLeft = 0;
        if (start < 0) {
            stepsLeft = -start;
            start = 0;
        }
        let end: number = lastCompletedIndex + initialRange / 2 + stepsLeft;
        if (end > initialValuesLength) {
            end = initialValuesLength;
        }
        return [start, end];
    };

    const loadSteps = (initialValues: Step[], initialRange: number) => {
        const lastCompletedIndex = getLastCompletedIndex(initialValues);
        const [start, end] = evaluateRange(initialRange, lastCompletedIndex, initialValues.length);
        setVisibleRange(initialRange);
        setContextState((prevState) => {
            const nextState = { ...prevState };

            nextState.completedCount = lastCompletedIndex + 1;
            nextState.activeIndex = lastCompletedIndex;
            nextState.visibleSteps = initialValues.slice(start, end);
            nextState.steps = [...initialValues];

            return nextState;
        });
        window.onresize = () => {
            const pageSize = Math.min(initialValues.length, getPageSize());
            loadSteps(initialValues, pageSize);
        };
    };

    const setActiveStep = (index: number, status: StepStatuses) => {
        setContextState((prevState) => {
            const { activeIndex, steps, visibleSteps } = prevState;
            const nextState = { ...prevState };

            nextState.steps[activeIndex].status = status;
            let offset = 0;
            if (index >= steps.length) {
                offset = -1;
            } else if (index < 0) {
                offset = 1;
            }
            nextState.activeIndex = index + offset;
            if (status === StepStatuses.Answered) {
                nextState.completedCount = prevState.completedCount + 1;
            }

            const firstVisibleStep = visibleSteps[0];
            const lastVisibleStep = visibleSteps[visibleSteps.length - 1];
            if (nextState.activeIndex > lastVisibleStep.index) {
                // let start = nextState.activeIndex;
                let start = Math.floor(nextState.activeIndex / visibleRange) * visibleRange;
                let end = steps.length;
                if (start + visibleRange > steps.length) {
                    start = steps.length - visibleRange;
                } else {
                    end = start + visibleRange;
                }
                nextState.visibleSteps = steps.slice(start, end);
            } else if (nextState.activeIndex < firstVisibleStep.index) {
                // let end = nextState.activeIndex + 1;
                let end = Math.ceil(nextState.activeIndex / visibleRange) * visibleRange;
                let start = 0;
                if (end - visibleRange < 0) {
                    end = visibleRange;
                } else {
                    start = end - visibleRange;
                }
                nextState.visibleSteps = steps.slice(start, end);
            }

            return nextState;
        });
    };

    const handleShiftLeft = () => {
        setContextState((prevState) => {
            const { steps, visibleSteps } = prevState;
            const nextState = { ...prevState };

            const firstVisibleStep = visibleSteps[0];

            let start = firstVisibleStep.index - visibleRange;
            if (start < 0) {
                start = 0;
            }

            nextState.visibleSteps = steps.slice(start, start + visibleRange);

            return nextState;
        });
    };

    const handleShiftRight = () => {
        setContextState((prevState) => {
            const { steps, visibleSteps } = prevState;
            const nextState = { ...prevState };

            const lastVisibleStep = visibleSteps[visibleSteps.length - 1];

            let end = lastVisibleStep.index + visibleRange;
            if (end > steps.length) {
                end = steps.length;
            }
            const start = end - visibleRange;
            nextState.visibleSteps = steps.slice(start, end);

            return nextState;
        });
    };

    return {
        contextState,

        loadSteps,
        setActiveStep,

        handleShiftLeft,
        handleShiftRight,
    };
}

export default useStepperSkillsContextValue;
