import { useTheme } from '@mui/material';
import { Box, Button, LoadingButton, Stack } from '@talentmesh/core';
import { ChevronLeftIcon, ChevronRightIcon } from '@talentmesh/icons';
import { Form, Formik, FormikProps } from 'formik';
import React, { useMemo, useRef } from 'react';
import { useOnBoardingContext } from '../../../../Context/OnBoardingContext';
import InformationHeader from '../ProfileInformationHeader';
import AdditionalInformationBlock from './Components/AdditionalInformationBlock';
import validationSchemaGenerator from './Components/Utility/ValidationSchemaGenerator';
import {
    informationFromRecruitmentInformationFormValues,
    RecruitmentInformationFormValues,
} from './RecruitmentInformationUtils';
import Constants from './Components/Constants';
import useTMTranslation from '../../../../Hooks/useTMTranslation';

interface RecruitmentInformationStepProps {
    handleBack: () => void;
}

function RecruitmentInformationStep({ handleBack }: RecruitmentInformationStepProps): JSX.Element {
    const theme = useTheme();
    const { tCommon: t } = useTMTranslation();

    const {
        info: { assessmentAdditionalInformation, companyName },
        onBoardingWrapper,
        isOnBoardingInProgress,
        applicantProfile,
        additionalInformationCache,
        setRecruitmentInformation,
    } = useOnBoardingContext();
    const ref = useRef<FormikProps<RecruitmentInformationFormValues>>(null);
    const {
        phoneNumber,
        reasonForApplying,
        isEmployed,
        cvFileName,
        reasonForLeaving,
        noticePeriod,
        validWorkPermit,
        whyWorkInStartup,
        rankRemunerationLearningBalance,
        structureImportance,
        personalProject,
        whereDoYouSeeYourself,
        salaryExpectation,
    } = additionalInformationCache;

    const isCurrentlyWorking = applicantProfile.experiences.some((experience) => experience.workingHere);

    let isEmployedInitialValue;
    if (isEmployed !== '') {
        // The case when the user navigate to step 2 filled in some values and then navigate back to step 1
        // and then navigate to step 2 again.
        isEmployedInitialValue = isEmployed;
    } else if (isCurrentlyWorking) {
        isEmployedInitialValue = Constants.isEmployed;
    } else {
        isEmployedInitialValue = Constants.isUnemployed;
    }

    const initialFormValues: RecruitmentInformationFormValues = {
        PhoneNumber: phoneNumber,
        cvFile: cvFileName,
        ReasonForApplying: reasonForApplying,
        IsEmployed: isEmployedInitialValue,
        ReasonForLeaving: reasonForLeaving,
        NoticePeriod: noticePeriod,
        ValidWorkPermit: validWorkPermit,
        WhyWorkInStartup: whyWorkInStartup,
        RankRemunerationLearningBalance: rankRemunerationLearningBalance,
        StructureImportance: structureImportance,
        PersonalProject: personalProject,
        WhereDoYouSeeYourself: whereDoYouSeeYourself,
        SalaryExpectation: salaryExpectation,
    };

    const submitHandler = async (values: RecruitmentInformationFormValues) => {
        onBoardingWrapper({
            applicantProfile,
            applicantInformation: informationFromRecruitmentInformationFormValues(
                values,
                assessmentAdditionalInformation,
            ),
        });
    };

    const handleBackWrapper = () => {
        if (ref.current) {
            setRecruitmentInformation(ref.current.values);
            handleBack();
        }
    };

    const validationScheme = useMemo(
        () => validationSchemaGenerator(t, assessmentAdditionalInformation),
        [assessmentAdditionalInformation],
    );

    return (
        <Formik
            initialValues={initialFormValues}
            onSubmit={submitHandler}
            validationSchema={validationScheme}
            enableReinitialize
            innerRef={ref}
        >
            <Form>
                <Stack alignContent="center" direction="column" spacing={theme.spacing(2.5)}>
                    <InformationHeader
                        title={t('ApplicantInformation')}
                        message={t('BeforeYouStart', { companyName })}
                    />

                    <AdditionalInformationBlock />

                    <Box display="flex" justifyContent="space-between">
                        <Button
                            variant="outlined"
                            color="secondary"
                            startIcon={<ChevronLeftIcon />}
                            onClick={handleBackWrapper}
                        >
                            {t('Back')}
                        </Button>
                        <LoadingButton
                            variant="contained"
                            size="large"
                            type="submit"
                            loading={isOnBoardingInProgress}
                            endIcon={<ChevronRightIcon />}
                        >
                            {t('SubmitApplicantInformation')}
                        </LoadingButton>
                    </Box>
                </Stack>
            </Form>
        </Formik>
    );
}

export default RecruitmentInformationStep;
