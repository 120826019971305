import React, { useEffect, useState } from 'react';
import useTMTranslation from '../../Hooks/useTMTranslation';
import ErrorDialog from './Components/ErrorDialog';
import ErrorQueryNames from './ErrorQueryNames';

function IdentityErrorPage(): JSX.Element {
    const [errorDescription, setErrorDescription] = useState<string>('');
    const { tCommon: t } = useTMTranslation();

    useEffect(() => {
        const error = new URLSearchParams(window.location.search).get(ErrorQueryNames.ErrorDescription);
        if (error) {
            setErrorDescription(error);
        }
    }, []);

    return <ErrorDialog errorTitle={t('AuthenticationError')} errorDescription={errorDescription} />;
}

export default IdentityErrorPage;
