import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import { Button, Dialog, Stack, Typography, Paper, CircularProgress } from '@talentmesh/core';
import useTMTranslation from '../../../../../Hooks/useTMTranslation';

interface TimesUpDialogProps {
    open: boolean;
    ok: () => void;
    actionAsync: () => Promise<void>;
}

function TimesUpDialog({ open, ok, actionAsync }: TimesUpDialogProps): JSX.Element {
    const theme = useTheme();
    const { tTest } = useTMTranslation();

    const [saving, setSaving] = useState(false);

    useEffect(() => {
        const doActionAsync = async () => {
            setSaving(true);
            await actionAsync();
            setSaving(false);
        };

        if (open) {
            doActionAsync();
        }
    }, [open]);

    return (
        <Dialog open={open}>
            <Paper
                sx={{
                    padding: theme.spacing(2),
                    width: theme.spacing(48),
                }}
            >
                <Stack direction="column" spacing={theme.spacing(2)}>
                    <Typography variant="h4" color={theme.palette.text.primary}>
                        {tTest('YouHaveRunOutOfTime')}
                    </Typography>

                    <Typography variant="body2" color={theme.palette.text.primary}>
                        {`${tTest('YourTestEnded')} ${tTest('QuestionsYouHaveAnsweredWillStillBeAssessed')}`}
                    </Typography>
                    {saving && (
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={4}>
                            <Typography variant="body2" color={theme.palette.text.hint}>
                                {tTest('SavingYourResponses')}
                            </Typography>
                            <CircularProgress />
                        </Stack>
                    )}
                    {!saving && (
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                            <Typography variant="caption" color={theme.palette.text.highlight}>
                                {tTest('YourResponsesHaveBeenSaved')}
                            </Typography>
                            <Button variant="contained" onClick={ok}>
                                {tTest('ExitTest')}
                            </Button>
                        </Stack>
                    )}
                </Stack>
            </Paper>
        </Dialog>
    );
}

export default TimesUpDialog;
