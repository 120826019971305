/* eslint-disable consistent-return */
import ContentTypes from '../ContentTypes';
import ErrorResponse, { handleHttpError } from './HttpError';

export interface HttpRequest {
    path: string;
    method?: string;
    body?: BodyInit;
    accessToken?: string;
    contentType?: undefined | string;
}

interface HttpResponse<T> {
    ok: boolean;
    body?: T;
}

export interface Strategy {
    body: BodyInit;
    contentType: string | undefined;
}

const setHeaders = (request: Request, config: HttpRequest): Request => {
    if (config.contentType) {
        request.headers.set('Content-Type', config.contentType);
    }
    if (config.accessToken) {
        request.headers.set('authorization', `bearer ${config.accessToken}`);
    }
    return request;
};

async function tryToJson(response: Response): Promise<any> {
    try {
        return await response.json();
    } catch (error) {
        return Promise.resolve(false);
    }
}

export const http = async <RESB>(config: HttpRequest): Promise<HttpResponse<RESB>> => {
    let request = new Request(config.path, {
        method: config.method || 'get',
        body: config.body,
    } as RequestInit);
    request = setHeaders(request, config);
    try {
        const response: Response = await fetch(request);
        if (response.ok) {
            const body: RESB = (await tryToJson(response)) || response.status === 200;
            return { ok: response.ok, body };
        } else {
            const text = await response.text();
            try {
                const errorResponse: ErrorResponse = JSON.parse(text);
                const error = handleHttpError(errorResponse);
                return Promise.reject(error);
            } catch (e) {
                return Promise.reject(new Error(text));
            }
        }
    } catch (error) {
        // catch network failures
        return Promise.reject(error);
    }
};
export async function get<RESB>(config: HttpRequest): Promise<HttpResponse<RESB>> {
    const configuration = { ...config, method: 'get' };
    return await http<RESB>(configuration);
}

export async function put<RESB, RESP = RESB>(config: HttpRequest): Promise<HttpResponse<RESP>> {
    const configuration = { ...config, method: 'put' };
    return await http<RESP>(configuration);
}

export async function post<RESB>(config: HttpRequest): Promise<HttpResponse<RESB>> {
    const configuration = { ...config, method: 'post' };
    return await http<RESB>(configuration);
}

export async function patch<RESB>(config: HttpRequest): Promise<HttpResponse<RESB>> {
    const configuration = { ...config, method: 'PATCH' };
    return await http<RESB>(configuration);
}

export async function httpDelete<RESB>(config: HttpRequest): Promise<HttpResponse<RESB>> {
    const configuration = { ...config, method: 'delete' };
    return await http<RESB>(configuration);
}

export function toJson<T>(input: T): Strategy {
    return {
        body: JSON.stringify(input),
        contentType: ContentTypes.ApplicationJson,
    };
}

function toFormDataHelper(input: any, form: FormData | undefined, namespace: string | undefined): FormData {
    const formData = form || new FormData();
    // eslint-disable-next-line no-restricted-syntax
    for (const property in input) {
        if (!input.hasOwnProperty(property)) {
            continue;
        }

        const propertyName = property.toString();
        const propertyValue = input[propertyName];

        const formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
        if (propertyValue instanceof Date) {
            formData.append(formKey, input[propertyName].toISOString());
        } else if (typeof propertyValue === 'object' && !(propertyValue instanceof File)) {
            toFormDataHelper(propertyValue, formData, formKey);
        } else if (propertyValue != null) {
            formData.append(formKey, propertyValue);
        }
    }
    return formData;
}

export function toFormData<T>(input: T): Strategy {
    return {
        body: toFormDataHelper(input, undefined, undefined),
        contentType: undefined,
    };
}
