import React from 'react';
import { Stack } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import TestInfoCard from '../../../TestInfo/TestInfoCard';
import { getLastCompletedTestIndex } from '../../../TestInfo/TestInfoUtil';
import { TestInfo, TestStates } from '../../../../../../../../../Models/TestInfo';
import { ApplicantAssessmentInfo } from '../../../../../../../../../Models/ApplicantAssessmentInfo';
import sortByTestInfo from '../../../../../../../../../Utils/sortBy';

interface TestInfoListProps extends Pick<ApplicantAssessmentInfo, 'tests'> {
    isUpdated: boolean;
    isSubmitted: boolean;
    triggerShrink: boolean;
}

function TestInfoList({ tests, isUpdated, isSubmitted, triggerShrink }: TestInfoListProps) {
    const theme = useTheme();
    const sortedTests: Array<TestInfo> = tests.sort(sortByTestInfo);
    const lastCompletedIndex = getLastCompletedTestIndex(sortedTests);

    return (
        <Stack
            direction="row"
            spacing={1.25}
            sx={{
                justifyContent: 'flex-start',
                paddingTop: theme.spacing(1.25),
            }}
        >
            {sortedTests.map(
                (
                    { testId, testType, testState, allocatedTimeMinutes, estimatedTimeMinutes }: TestInfo,
                    index: number,
                ) => (
                    <TestInfoCard
                        key={testId}
                        testType={testType}
                        testState={testState}
                        isSubmitted={isSubmitted}
                        allocatedTimeMinutes={allocatedTimeMinutes}
                        estimatedTimeMinutes={estimatedTimeMinutes}
                        compact={isSubmitted}
                        triggerShrink={triggerShrink}
                        triggerTransition={
                            (isUpdated && index === lastCompletedIndex) ||
                            (isUpdated && testState === TestStates.Active)
                        }
                    />
                ),
            )}
        </Stack>
    );
}

export default TestInfoList;
