import React from 'react';
import { Navigate, generatePath } from 'react-router';
import useAssessmentId from '../Hooks/useAssessmentId';
import RouteParam from '../Routing/RouteParam';
import RoutePath from '../Routing/RoutePath';
import withTalentMeshUserRequired from '../Routing/withTalentMeshUserRequired';

// The only purpose of this page is to maintain backward capability with older invitation/open links
// and redirect users to new URL
function ExecutePage(): JSX.Element {
    const assessmentId = useAssessmentId();

    return <Navigate to={generatePath(RoutePath.AssessmentOnboard, { [RouteParam.AssessmentId]: assessmentId })} />;
}

export default withTalentMeshUserRequired(ExecutePage);
