import React from 'react';
import { Button } from '@talentmesh/core';
import useTMTranslation from '../../../Hooks/useTMTranslation';

function WelcomeActions(): JSX.Element {
    const { tTest } = useTMTranslation();

    return (
        <Button
            variant="contained"
            sx={{
                width: 'fit-content',
            }}
            type="submit"
        >
            {tTest('IUnderstand')}
        </Button>
    );
}

export default WelcomeActions;
