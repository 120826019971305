import React from 'react';
import { Typography } from '@talentmesh/core';
import CustomTitle from '../../Components/CustomTitle';
import useTMTranslation from '../../../../../Hooks/useTMTranslation';

interface EditTitleProps {
    isLatestJob: boolean;
}

const EditTitle: React.FC<EditTitleProps> = ({ isLatestJob }) => {
    const { tCommon: t } = useTMTranslation();

    return (
        <>
            {isLatestJob && (
                <CustomTitle labelLeft={t('EnterYour')} labelMiddle={t('LatestJob')} labelRight={t('Experience')} />
            )}
            {!isLatestJob && <Typography variant="body1">{t('AddAdditionalJobExperienceOptional')}</Typography>}
        </>
    );
};

export default EditTitle;
