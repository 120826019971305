import React from 'react';
import { Stack, Typography } from '@talentmesh/core';
import { useTheme } from '@mui/material';
import PageSideNav from '../../../Components/Pages/PageSideNav';
import withTalentMeshUserRequired from '../../../Routing/withTalentMeshUserRequired';
import ProfileSettingsForm from './components/ProfileSettingsForm';
import withGooglePlacesAPICheck from '../../../Routing/withGooglePlacesAPICheck';
import useTMTranslation from '../../../Hooks/useTMTranslation';

const ProfileSettingsPage: React.FC = () => {
    const theme = useTheme();
    const { tCommon: t } = useTMTranslation();

    return (
        <PageSideNav>
            <Stack
                direction="column"
                sx={{
                    minWidth: theme.spacing(79),
                }}
            >
                <Typography
                    variant="h3"
                    color={theme.palette.text.hint}
                    mt={theme.spacing(4.75)}
                    mb={theme.spacing(2.5)}
                >
                    {t('ProfileSettings')}
                </Typography>
                <ProfileSettingsForm />
            </Stack>
        </PageSideNav>
    );
};

export default withGooglePlacesAPICheck(withTalentMeshUserRequired(ProfileSettingsPage));
