import React from 'react';
import { useTheme } from '@mui/material/styles';
import Avatar from '../../../../../../resources/Avatar.png';
import { ApplicantAssessmentInfo } from '../../../../../../../../../Models/ApplicantAssessmentInfo';
import useTMTranslation from '../../../../../../../../../Hooks/useTMTranslation';

interface CompanyLogoProps extends Pick<ApplicantAssessmentInfo, 'companyIconURL'> {}

function CompanyLogo({ companyIconURL }: CompanyLogoProps) {
    const theme = useTheme();
    const logoSize = theme.spacing(4.5);
    const { tCommon: t } = useTMTranslation();

    return (
        <img
            alt={t('CompanyLogo')}
            src={companyIconURL ?? Avatar}
            style={{
                width: logoSize,
                height: logoSize,
                borderRadius: '50%',
            }}
        />
    );
}

export default CompanyLogo;
