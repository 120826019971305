import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Stack, SwitchProps, Typography } from '@talentmesh/core';
import { FormikSwitch } from '@talentmesh/forms';

export interface PermissionToggleProps extends SwitchProps {
    header: string;
    disclaimer: string;
    name: string;
}

function PermissionToggle({ header, disclaimer, name, ...props }: PermissionToggleProps): JSX.Element {
    const theme = useTheme();

    return (
        <Stack
            spacing={1.25}
            sx={{
                width: theme.spacing(45),
                alignItems: 'flex-start',
                justifyContent: 'space-between',
            }}
        >
            <Stack spacing={0.5} sx={{ height: theme.spacing(11.75) }}>
                <Typography variant="h5" sx={{ color: theme.palette.text.menuTitle }}>
                    {header}
                </Typography>
                <Typography variant="body2">{disclaimer}</Typography>
            </Stack>
            <FormikSwitch name={name} {...props} />
        </Stack>
    );
}

export default PermissionToggle;
