import React from 'react';
import { ErrorPage } from '@talentmesh/core';
import { resourceBaseUrl } from '../../AppSettings';
import ForbiddenPageInfo from './ForbiddenPageInfo';
import useTMTranslation from '../../Hooks/useTMTranslation';

function ForbiddenPageContainer(): JSX.Element {
    const { tCommon: t } = useTMTranslation();

    return (
        <ErrorPage
            imageUrl={`${resourceBaseUrl}/general/403.svg`}
            imageAlt={t('AccessDenied')}
            imageHeight="100%"
            imageWidth="100%"
        >
            <ForbiddenPageInfo />
        </ErrorPage>
    );
}

export default ForbiddenPageContainer;
