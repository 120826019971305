import React from 'react';
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { Navigate, Outlet } from 'react-router';
import Auth0ProviderWithNavigate from '../Auth/Auth0ProviderWithNavigate';
import Auth0LoadingOverlay from './Auth0LoadingOverlay';
import { reactPlugin } from '../Telemetry/AppInsights';
import RoutePath from './RoutePath';

function ErrorPageRedirect() {
    return <Navigate replace to={RoutePath.Error} />;
}

const ProvisionApp = () => {
    return (
        <Auth0ProviderWithNavigate>
            <AppInsightsErrorBoundary appInsights={reactPlugin} onError={ErrorPageRedirect}>
                <AppInsightsContext.Provider value={reactPlugin}>
                    <Auth0LoadingOverlay>
                        <Outlet />
                    </Auth0LoadingOverlay>
                </AppInsightsContext.Provider>
            </AppInsightsErrorBoundary>
        </Auth0ProviderWithNavigate>
    );
};

export default ProvisionApp;
