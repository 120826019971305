import { ApplicantProfileDTO } from '../DTO/Assessments/ApplicantProfileDTO';
import CandidateDTO from '../DTO/Users/CandidateDTO';
import { mapApplicantProfileDTO2Model } from '../Pages/MyAssessment/ProfileSettings/utils/ApplicantProfileUtils';
import { ApplicantProfile, DefaultApplicantProfile } from './ApplicantProfile';

export type Role = 'Candidate';

// TODO: refactor back to this
// export default interface Candidate extends ApplicantProfile {
export default interface Candidate extends ApplicantProfile {
    id: string;
    role?: Role;
}

export const DefaultCandidate: Candidate = {
    ...DefaultApplicantProfile,
    id: '',
    role: 'Candidate',
};

export const mapCandidateDTO2Model = (dto: CandidateDTO): Candidate => {
    const profile = mapApplicantProfileDTO2Model(dto as ApplicantProfileDTO);

    return {
        ...profile,
        id: dto.id,
        role: dto.role,
    } as Candidate;
};
