import { useTheme } from '@mui/material/styles';
import React from 'react';
import { SpanTypography, Box, Link } from '@talentmesh/core';
import { siteUrls } from '../../../AppSettings';
import useTMTranslation from '../../../Hooks/useTMTranslation';

function CTTermsAndPrivacyPolicyDisclaimer(): JSX.Element {
    const theme = useTheme();
    const { tTest } = useTMTranslation();

    return (
        <Box>
            <SpanTypography color={theme.palette.text.primary} variant="body2">
                {`${tTest('YourPrivacyMattersToUs')} `}
                <Link target="_blank" href={siteUrls.termsCandidateUrl} color={theme.palette.text.highlight}>
                    {tTest('TermsAndConditions')}
                </Link>
                {` ${tTest('And')} `}
                <Link target="_blank" href={siteUrls.privacyPolicyUrl} color={theme.palette.text.highlight}>
                    {tTest('PrivacyPolicy')}
                </Link>
                .
            </SpanTypography>
        </Box>
    );
}

export default CTTermsAndPrivacyPolicyDisclaimer;
