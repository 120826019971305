import { Box } from '@talentmesh/core';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ExperienceFormValues } from '../../../MyAssessment/ProfileSettings/utils/ApplicantProfileUtils';
import { JobExperienceEdit } from './Edit/JobExperienceEdit';
import { useJobExperienceListContext } from './JobExperienceListContext';
import { JobExperienceView } from './View/JobExperienceView';
import JobExperiencesFieldsNames from './JobExperiencesFieldsNames';

export interface JobExperienceProps {
    index: number;
    initialIsEdit: boolean;
    onRemove: () => void;
    onCancel: () => void;
}

const name = JobExperiencesFieldsNames.ExperiencesName;

export function JobExperience({ index, initialIsEdit, onRemove, onCancel }: JobExperienceProps): JSX.Element {
    const [isInEdit, setIsInEdit] = useState(initialIsEdit);
    const [experienceSnapshot, setExperienceSnapshot] = useState<ExperienceFormValues | undefined>(undefined);
    const { decrementEditingSubformsCounter, incrementEditingSubformsCounter } = useJobExperienceListContext();

    const { trigger, setValue } = useFormContext();

    return (
        <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
            {isInEdit ? (
                <JobExperienceEdit
                    index={index}
                    onCancel={() => {
                        if (experienceSnapshot !== undefined) {
                            setValue(`${name}[${index}]`, experienceSnapshot);
                        } else {
                            onCancel();
                        }
                        setIsInEdit(false);
                        decrementEditingSubformsCounter();
                    }}
                    onSave={async () => {
                        const result = await trigger(`${name}[${index}]`);
                        setIsInEdit(!result);
                        if (result) {
                            decrementEditingSubformsCounter();
                        }
                    }}
                />
            ) : (
                <JobExperienceView
                    index={index}
                    onRemove={onRemove}
                    onEdit={(isEdit: boolean, experience: ExperienceFormValues) => {
                        setIsInEdit(isEdit);
                        setExperienceSnapshot(experience);
                        incrementEditingSubformsCounter();
                    }}
                />
            )}
        </Box>
    );
}
