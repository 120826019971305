import CreateSnapshotsSliceDTO from '../DTO/AntiCheat/CreateSnapshotsSliceDTO';
import { post, toFormData } from '../Utils/HTTP/HttpUtils';
import ClientBase from './ClientBase';

export default class SnapshotsClient extends ClientBase {
    apiPrefix = 'snapshots';

    createSlice = async (slice: CreateSnapshotsSliceDTO, testId: string): Promise<void> => {
        const accessToken = await this.getAccessTokenSilently();
        const path = this.addHost(`${this.apiPrefix}/${testId}`);
        const strategy = toFormData(slice);
        await post({
            path,
            body: strategy.body,
            accessToken,
            contentType: strategy.contentType,
        });
    };
}
