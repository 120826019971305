import React, { useEffect } from 'react';
import { LoadingButton, Stack } from '@talentmesh/core';
import { useFormikContext } from 'formik';
import { useTheme } from '@mui/material/styles';
import { CTMonitoringPanelFormValues } from './CTMonitoringTogglesForm';
import { useAssessmentActionsContext } from '../../../Context/AssessmentActionsContext';
import useTMTranslation from '../../../Hooks/useTMTranslation';

function AcceptRulesPanel(): JSX.Element {
    const theme = useTheme();
    const { tTest } = useTMTranslation();
    const { values, setFieldValue } = useFormikContext<CTMonitoringPanelFormValues>();
    const { activeAssessmentId } = useAssessmentActionsContext();

    useEffect(() => {
        setFieldValue('termsAccepted', true);
    }, []);

    return (
        <Stack direction="column" spacing={theme.spacing(3)} alignItems="center">
            <LoadingButton
                variant="contained"
                size="large"
                type="submit"
                disabled={!values.termsAccepted}
                loading={activeAssessmentId !== ''}
            >
                {tTest('StartTest')}
            </LoadingButton>
        </Stack>
    );
}

export default AcceptRulesPanel;
