import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import AssessmentsExecution, { assessmentExecutionRouter } from '../AssessmentsExecution';
import CandidateApplicationContextProvider from '../Context/CandidateApplicationContextProvider';
import Home from '../Home';
import ErrorPage from '../Pages/Errors/ErrorPage';
import IdentityErrorPage from '../Pages/Errors/IdentityErrorPage';
import ExecutePage from '../Pages/ExecutePage';
import ForbiddenPageContainer from '../Pages/ForbiddenPage/ForbiddenPageContainer';
import GetFullExperiencePage from '../Pages/FullExperience/GetFullExperiencePage';
import ProfileSettingsPage from '../Pages/MyAssessment/ProfileSettings/ProfileSettingsPage';
import NotFoundPageContainer from '../Pages/NotFoundPage/NotFoundPageContainer';
import RecruitmentClosedPage from '../Pages/RecruitmentClosed/RecruitmentClosedPage';
import VersionPage from '../Pages/VersionPage';
import ProtectedRoute from './ProtectedRoute';
import RoutePath from './RoutePath';
import ProvisionApp from './ProvisionApp';

const router = createBrowserRouter([
    {
        element: <ProvisionApp />,
        children: [
            {
                element: <CandidateApplicationContextProvider />,
                children: [
                    {
                        path: '*',
                        element: <NotFoundPageContainer />,
                    },
                    {
                        path: RoutePath.Home,
                        element: <Home />,
                    },
                    {
                        element: <ProtectedRoute />,
                        children: [
                            {
                                path: RoutePath.ProfileSettings,
                                element: <ProfileSettingsPage />,
                            },
                            {
                                path: RoutePath.Execute,
                                element: <ExecutePage />,
                            },
                        ],
                    },
                    {
                        path: RoutePath.IdentityError,
                        element: <IdentityErrorPage />,
                    },
                    {
                        path: RoutePath.Error,
                        element: <ErrorPage />,
                    },
                    {
                        path: RoutePath.Version,
                        element: <VersionPage />,
                    },
                    {
                        path: RoutePath.RecruitmentClosedPage,
                        element: <RecruitmentClosedPage />,
                    },
                    {
                        path: RoutePath.FullExperience,
                        element: <GetFullExperiencePage />,
                    },
                    {
                        path: RoutePath.NotFound,
                        element: <NotFoundPageContainer />,
                    },
                    {
                        path: RoutePath.Forbidden,
                        element: <ForbiddenPageContainer />,
                    },
                    {
                        path: RoutePath.Assessments,
                        element: <AssessmentsExecution />,
                        children: assessmentExecutionRouter,
                    },
                ],
            },
        ],
    },
]);

export default router;
