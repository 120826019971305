import { Theme } from '@mui/material/styles';
import { TestInfo } from '../../../../Models/TestInfo';
import { testTypeToString } from '../../../../Utils/mappings';
import { ApplicantStatus, ApplicantAssessmentInfoStatus } from '../../../../DTO/Assessments/ApplicantAssessmentInfoDTO';
import {
    Keyframe,
    nullAnimation,
    growAssessmentSection,
    shrinkAssessmentSection,
} from '../animations/JobApplicationAnimation';
import { TestResourceKey } from '../../../../i18n/byNamespace/i18nTestResourceKeys';
import { CommonResourceKey } from '../../../../i18n/byNamespace/i18nCommonResourceKeys';
import { TMTranslationGetter } from '../../../../Hooks/useTMTranslation';

export function getApplicantAssessmentStatus(
    applicantAssessmentInfoStatus: ApplicantAssessmentInfoStatus,
    t: TMTranslationGetter<CommonResourceKey>,
): string {
    switch (applicantAssessmentInfoStatus) {
        case ApplicantAssessmentInfoStatus.NotStarted:
            return t('NotStarted');
        case ApplicantAssessmentInfoStatus.AwaitingAssessment:
            return t('AwaitingAssessment');
        case ApplicantAssessmentInfoStatus.AssessmentOngoing:
            return t('AssessmentOngoing');
        case ApplicantAssessmentInfoStatus.Submitted:
            return t('Submitted');
        case ApplicantAssessmentInfoStatus.InvitedForInterview:
            return t('InvitedForInterview');
        case ApplicantAssessmentInfoStatus.Hired:
            return t('Hired');
        case ApplicantAssessmentInfoStatus.Rejected:
            return t('Rejected');
        default:
            return '';
    }
}

export function getApplicantAssessmentSubmittedState(
    applicantAssessmentInfoStatus: ApplicantAssessmentInfoStatus,
): boolean {
    switch (applicantAssessmentInfoStatus) {
        case ApplicantAssessmentInfoStatus.NotStarted:
        case ApplicantAssessmentInfoStatus.AwaitingAssessment:
        case ApplicantAssessmentInfoStatus.AssessmentOngoing:
        case ApplicantAssessmentInfoStatus.InvitedForInterview:
            return false;
        case ApplicantAssessmentInfoStatus.Submitted:
        case ApplicantAssessmentInfoStatus.Hired:
        case ApplicantAssessmentInfoStatus.Rejected:
        default:
            return true;
    }
}

export function mapApplicantStatusToApplicantAssessmentStatus(
    applicantStatus: ApplicantStatus,
): ApplicantAssessmentInfoStatus {
    switch (applicantStatus) {
        case 'AssessmentOpened':
            return ApplicantAssessmentInfoStatus.AwaitingAssessment;
        case 'AssessmentOngoing':
            return ApplicantAssessmentInfoStatus.AssessmentOngoing;
        case 'AssessmentCompleted':
        case 'AssessmentRetaken':
            return ApplicantAssessmentInfoStatus.Submitted;
        case 'Interviewing':
            return ApplicantAssessmentInfoStatus.InvitedForInterview;
        case 'Rejected':
            return ApplicantAssessmentInfoStatus.Rejected;
        case 'Hired':
            return ApplicantAssessmentInfoStatus.Hired;
        case 'Invited':
        default:
            return ApplicantAssessmentInfoStatus.NotStarted;
    }
}

export function getJobApplicationCardStatusColor(
    theme: Theme,
    applicantAssessmentInfoStatus: ApplicantAssessmentInfoStatus,
): string {
    switch (applicantAssessmentInfoStatus) {
        case ApplicantAssessmentInfoStatus.AwaitingAssessment:
        case ApplicantAssessmentInfoStatus.AssessmentOngoing:
            return theme.palette.charts.chart13;
        case ApplicantAssessmentInfoStatus.Submitted:
            return theme.palette.charts.chart3;
        case ApplicantAssessmentInfoStatus.InvitedForInterview:
        case ApplicantAssessmentInfoStatus.Hired:
            return theme.palette.charts.chart9;
        case ApplicantAssessmentInfoStatus.Rejected:
            return theme.palette.charts.chart10;
        case ApplicantAssessmentInfoStatus.NotStarted:
        default:
            return theme.palette.charts.chart12;
    }
}

export function getJobApplicationCardBackgroundColor(
    theme: Theme,
    applicantAssessmentInfoStatus: ApplicantAssessmentInfoStatus,
): string {
    switch (applicantAssessmentInfoStatus) {
        case ApplicantAssessmentInfoStatus.Hired:
        case ApplicantAssessmentInfoStatus.Rejected:
            return theme.palette.grey[200];
        case ApplicantAssessmentInfoStatus.NotStarted:
        case ApplicantAssessmentInfoStatus.AwaitingAssessment:
        case ApplicantAssessmentInfoStatus.AssessmentOngoing:
        case ApplicantAssessmentInfoStatus.Submitted:
        case ApplicantAssessmentInfoStatus.InvitedForInterview:
        default:
            return theme.palette.common.white;
    }
}

export function getTestActionButtonLabelByTestInfo(
    test: TestInfo,
    tCommon: TMTranslationGetter<CommonResourceKey>,
    tTest: TMTranslationGetter<TestResourceKey>,
): string {
    const { testType, startedAt } = test;
    const testTypeStr = testTypeToString(testType, tCommon).toLowerCase();

    return tTest(startedAt ? 'ContinueTestType' : 'StartTestType', { testName: testTypeStr });
}

export function getPreviousApplicantAssessmentStatus(
    applicantAssessmentInfoStatus: ApplicantAssessmentInfoStatus,
): ApplicantAssessmentInfoStatus {
    switch (applicantAssessmentInfoStatus) {
        case ApplicantAssessmentInfoStatus.AwaitingAssessment:
            return ApplicantAssessmentInfoStatus.NotStarted;
        case ApplicantAssessmentInfoStatus.AssessmentOngoing:
            return ApplicantAssessmentInfoStatus.AwaitingAssessment;
        case ApplicantAssessmentInfoStatus.Submitted:
            return ApplicantAssessmentInfoStatus.AssessmentOngoing;
        case ApplicantAssessmentInfoStatus.NotStarted:
        case ApplicantAssessmentInfoStatus.InvitedForInterview:
        case ApplicantAssessmentInfoStatus.Hired:
        case ApplicantAssessmentInfoStatus.Rejected:
        default:
            return applicantAssessmentInfoStatus;
    }
}

export function getStatusTransitionTrigger(applicantAssessmentInfoStatus: ApplicantAssessmentInfoStatus): boolean {
    switch (applicantAssessmentInfoStatus) {
        case ApplicantAssessmentInfoStatus.AwaitingAssessment:
        case ApplicantAssessmentInfoStatus.Submitted:
            return true;
        case ApplicantAssessmentInfoStatus.NotStarted:
        case ApplicantAssessmentInfoStatus.AssessmentOngoing:
        case ApplicantAssessmentInfoStatus.InvitedForInterview:
        case ApplicantAssessmentInfoStatus.Hired:
        case ApplicantAssessmentInfoStatus.Rejected:
        default:
            return false;
    }
}

export function formatJobApplicationFilterCount(label: string, count: number): string {
    return `${label} (${count})`;
}

export function getCardAnimationTrigger(applicantAssessmentInfoStatus: ApplicantAssessmentInfoStatus): boolean {
    switch (applicantAssessmentInfoStatus) {
        case ApplicantAssessmentInfoStatus.AwaitingAssessment:
        case ApplicantAssessmentInfoStatus.Submitted:
            return true;
        case ApplicantAssessmentInfoStatus.NotStarted:
        case ApplicantAssessmentInfoStatus.AssessmentOngoing:
        case ApplicantAssessmentInfoStatus.InvitedForInterview:
        case ApplicantAssessmentInfoStatus.Hired:
        case ApplicantAssessmentInfoStatus.Rejected:
        default:
            return false;
    }
}

export function getCardAnimationByStatus(
    theme: Theme,
    applicantAssessmentInfoStatus: ApplicantAssessmentInfoStatus,
): Keyframe {
    switch (applicantAssessmentInfoStatus) {
        case ApplicantAssessmentInfoStatus.AwaitingAssessment:
            return growAssessmentSection(theme);
        case ApplicantAssessmentInfoStatus.Submitted:
            return shrinkAssessmentSection(theme);
        case ApplicantAssessmentInfoStatus.NotStarted:
        case ApplicantAssessmentInfoStatus.AssessmentOngoing:
        case ApplicantAssessmentInfoStatus.InvitedForInterview:
        case ApplicantAssessmentInfoStatus.Hired:
        case ApplicantAssessmentInfoStatus.Rejected:
        default:
            return nullAnimation;
    }
}

export function getCardHeightByStatus(
    theme: Theme,
    applicantAssessmentInfoStatus: ApplicantAssessmentInfoStatus,
): string {
    switch (applicantAssessmentInfoStatus) {
        case ApplicantAssessmentInfoStatus.AwaitingAssessment:
            return theme.spacing(0);
        case ApplicantAssessmentInfoStatus.Submitted:
            return theme.spacing(17.85);
        case ApplicantAssessmentInfoStatus.NotStarted:
        case ApplicantAssessmentInfoStatus.AssessmentOngoing:
        case ApplicantAssessmentInfoStatus.InvitedForInterview:
        case ApplicantAssessmentInfoStatus.Hired:
        case ApplicantAssessmentInfoStatus.Rejected:
        default:
            return 'auto';
    }
}
