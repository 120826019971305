import React from 'react';
import { useNavigate } from 'react-router';
import { useAssessmentActionsContext } from '../../../../../../../Context/AssessmentActionsContext';
import { useDialogsContext } from '../../../../../../../Context/DialogsContext';
import { TestInfo } from '../../../../../../../Models/TestInfo';
import CompleteTestDialog from '../../../../../../../Components/Dialogs/CompleteTestDialog';
import TimerWarningDialog from '../../../../../../../Components/Dialogs/TimerWarningDialog';
import useTestActions from './useTestActions';

interface TestActionDialogsProps {
    id: string;
    activeTest: TestInfo | undefined;
}

function TestActionDialogs({ id, activeTest }: TestActionDialogsProps) {
    const navigate = useNavigate();
    const { completeTestDialogId, timerWarningDialogId, setCompleteTestDialogId, setTimerWarningDialogId } =
        useDialogsContext();
    const { unfinishedTestPath, unfinishedTestCompany, activeAssessmentId, setActiveAssessmentId } =
        useAssessmentActionsContext();
    const { safeStartTestAsync } = useTestActions({ id });

    return (
        <>
            <CompleteTestDialog
                open={completeTestDialogId === id}
                ok={() => {
                    navigate(unfinishedTestPath);
                }}
                cancel={() => {
                    setCompleteTestDialogId('');
                    setActiveAssessmentId('');
                }}
                companyName={unfinishedTestCompany}
            />
            <TimerWarningDialog
                open={timerWarningDialogId === id}
                loading={activeAssessmentId === id}
                activeTest={activeTest}
                ok={() => {
                    safeStartTestAsync(activeTest?.testId ?? '');
                }}
                cancel={() => {
                    setTimerWarningDialogId('');
                }}
            />
        </>
    );
}

export default TestActionDialogs;
