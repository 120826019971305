import { useTheme } from '@mui/material';
import { AutocompleteOption } from '@talentmesh/core';
import React from 'react';
import Body2Typography from './Body2Typography';
import GridItem from './GridItem';

interface GridValueProps {
    value: AutocompleteOption | string;
    xs?: number;
}
const GridValue: React.FC<GridValueProps> = ({ value, xs }) => {
    const theme = useTheme();
    return (
        <GridItem xs={xs ?? 8}>
            <Body2Typography color={theme.palette.secondary.main}>{getLabel(value)}</Body2Typography>
        </GridItem>
    );
};

function getLabel(value: AutocompleteOption | string): string {
    if (typeof value === 'string') {
        return value;
    }
    const option = value as AutocompleteOption;

    return option?.label;
}

export default GridValue;
