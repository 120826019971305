import { useEffect, useState } from 'react';
import { useUserContext } from '../../../../Context/UserContext';
import { ApplicantProfileFormValues, profileFromApplicantOnboardFormValues } from '../utils/ApplicantProfileUtils';

const useProfileSettingsForm = () => {
    const { refreshUserAsync, upsertUserAsync } = useUserContext();
    const [isLoading, setIsLoading] = useState(true);

    const submitHandler = async (values: ApplicantProfileFormValues) => {
        const input = profileFromApplicantOnboardFormValues(values);
        await upsertUserAsync(input);
    };

    const doRefreshUser = async () => {
        await refreshUserAsync();
        setIsLoading(false);
    };

    useEffect(() => {
        doRefreshUser();
    }, []);

    return { isLoading, submitHandler };
};

export default useProfileSettingsForm;
