import { useTheme } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Button, Stack, Typography } from '@talentmesh/core';
import { RHFAutocompleteAsync, RHFCheckbox } from '@talentmesh/forms';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ExperienceFormValues } from '../../../../MyAssessment/ProfileSettings/utils/ApplicantProfileUtils';
import ProfileSettingsLabelTooltip from '../../Components/ProfileSettingsLabelTooltip';
import { useSettingsClient } from '../../../../../Hooks/ClientHooks';
import EditTitle from './EditTitle';
import JobExperiencesFieldsNames from '../JobExperiencesFieldsNames';
import useTMTranslation from '../../../../../Hooks/useTMTranslation';
import CustomRHFTextField from '../../../../../Components/Forms/CustomRHFTextField';
import CustomRHFDatePicker from '../../../../../Components/Forms/CustomRHFDatePicker';

export interface JobExperienceEditProps {
    index: number;
    onCancel: () => void;
    onSave: () => void;
}

const name = JobExperiencesFieldsNames.ExperiencesName;

export function JobExperienceEdit({ index, onCancel, onSave }: JobExperienceEditProps): JSX.Element {
    const theme = useTheme();
    const { tCommon: t } = useTMTranslation();

    const { control } = useFormContext();
    const { workingHere }: ExperienceFormValues = useWatch({
        control,
        name: `${name}[${index}]`,
    });

    const client = useSettingsClient();

    const getAutocompleteOptions = async (search: string) => {
        return search === '' ? [] : await client.getJobExperiences(search);
    };

    return (
        <Stack sx={{ backgroundColor: theme.palette.background.aliciaBlue }} spacing={2}>
            <EditTitle isLatestJob={index === 0} />
            <CustomRHFTextField name={`${name}[${index}].title`} label={t('Title')} />

            <Stack direction="column" spacing={theme.spacing(1)} width="100%">
                <ProfileSettingsLabelTooltip
                    htmlFor="jobExperience"
                    tooltipTitle={t('JobExperienceTooltipTitle')}
                    tooltipDescription={<Typography variant="body2">{t('JobTypeTooltipContent')}</Typography>}
                >
                    {t('JobType')}
                </ProfileSettingsLabelTooltip>
                <RHFAutocompleteAsync
                    name={`${name}[${index}].jobExperience`}
                    data-cy="jobExperience"
                    getAutocompleteOptions={getAutocompleteOptions}
                />
            </Stack>

            <CustomRHFTextField name={`${name}[${index}].company`} label={t('Company')} />
            <RHFCheckbox name={`${name}[${index}].workingHere`} variant="subtitle2" label={t('CurrentlyWorkingHere')} />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction="row" spacing={2}>
                    <CustomRHFDatePicker name={`${name}[${index}].startDate`} label={t('StartDate')} />
                    <CustomRHFDatePicker
                        name={`${name}[${index}].endDate`}
                        label={t('EndDate')}
                        datePickerLabel={t('Present')}
                        disabled={workingHere}
                    />
                </Stack>
            </LocalizationProvider>

            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                {/* The addition/removal of the first element is controlled by yes/no checkbox */}
                {index !== 0 && (
                    <Button data-cy="jobExperienceCancel" onClick={onCancel}>
                        {t('Cancel')}
                    </Button>
                )}
                <Button data-cy="jobExperienceSave" variant="contained" onClick={onSave}>
                    {t('Save')}
                </Button>
            </Stack>
        </Stack>
    );
}
