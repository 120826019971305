import * as yup from 'yup';
import getYupSchemaInformation from './CandidateOnboardYupSchemaGetters';
import ApplicantInformationType from '../../../../../../Models/ApplicantInformationType';
import { CommonResourceKey } from '../../../../../../i18n/byNamespace/i18nCommonResourceKeys';
import { TMTranslationGetter } from '../../../../../../Hooks/useTMTranslation';

export default function validationSchemaGenerator(
    t: TMTranslationGetter<CommonResourceKey>,
    applicantInformation: ApplicantInformationType[],
) {
    let validationSchema = yup.object().shape({});

    for (let i = 0; i < applicantInformation.length; ++i) {
        validationSchema = validationSchema.concat(getYupSchemaInformation(t, applicantInformation[i]));
    }

    return validationSchema;
}
