import React from 'react';
import { FormikTextField } from '@talentmesh/forms';
import ApplicantInformationType from '../../../../../../Models/ApplicantInformationType';
import useTMTranslation from '../../../../../../Hooks/useTMTranslation';

function NoticePeriod(): JSX.Element {
    const { tCommon: t } = useTMTranslation();

    return <FormikTextField label={`${t('NoticePeriod')}*`} name={ApplicantInformationType.NoticePeriod} />;
}

export default NoticePeriod;
