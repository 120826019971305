import RadioGroup from '@mui/material/RadioGroup';
import { Stack } from '@talentmesh/core';
import React from 'react';
import useTMTranslation from '../../../../Hooks/useTMTranslation';
import Body1Typography from '../Components/Body1Typography';
import TMRadio from '../Components/TMRadio';
import { useEducationListContext } from './EducationListContext';

interface EducationListHeaderProps {
    onChange: (value: boolean) => void;
}

const EducationListHeader = ({ onChange }: EducationListHeaderProps) => {
    const { hasEducation } = useEducationListContext();
    const { tCommon: t } = useTMTranslation();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value === 'true';
        onChange(value);
    };

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack>
                <Body1Typography>{t('DoYouHaveAnEducation')}</Body1Typography>
                <RadioGroup row value={hasEducation} onChange={handleChange}>
                    <TMRadio data-cy="educationYes" value="true" label={t('Yes')} />
                    <TMRadio data-cy="educationNo" value="false" label={t('No')} />
                </RadioGroup>
            </Stack>
        </Stack>
    );
};

export default EducationListHeader;
