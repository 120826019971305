import { useTheme } from '@mui/material/styles';
import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { Container, Stack, TMAppBar, ToolbarWithLogo, Typography } from '@talentmesh/core';
import RoutePath from '../../../../Routing/RoutePath';
import { testTypeToName } from '../../../../Utils/mappings';
import { useTestContext } from '../../Contexts/TestContext';
import PersonalityBar from './PersonalityBar';
import useTMTranslation from '../../../../Hooks/useTMTranslation';

export interface PersonalityTestTopBarProps {}

export const PersonalityTestTopBar = forwardRef<HTMLDivElement, PersonalityTestTopBarProps>(
    function PersonalityTestTopBarRef(props, ref): JSX.Element {
        const theme = useTheme();
        const { tCommon: t } = useTMTranslation();

        const { test } = useTestContext();
        const commonBackgroundColor = theme.palette.table.main;

        return (
            <TMAppBar sx={{ backgroundColor: commonBackgroundColor, boxShadow: 'none' }} ref={ref}>
                <ToolbarWithLogo
                    logoClickURL={RoutePath.Home}
                    logoLinkComponent={Link}
                    backgroundColor={commonBackgroundColor}
                    sx={{
                        borderBottom: `1px solid ${theme.palette.border.main}`,
                        'div:first-of-type': { backgroundColor: commonBackgroundColor },
                    }}
                >
                    <Stack direction="row" spacing={theme.spacing(4)} sx={{ marginLeft: theme.spacing(4) }}>
                        <Typography variant="h5" color={theme.palette.text.primary} sx={{ display: 'flex' }}>
                            {testTypeToName(test.testType, t)}
                        </Typography>
                    </Stack>
                </ToolbarWithLogo>
                <Container
                    style={{
                        paddingTop: theme.spacing(3),
                        paddingBottom: theme.spacing(3),
                        paddingLeft: theme.spacing(15),
                        paddingRight: theme.spacing(15),
                        maxWidth: theme.spacing(180),
                    }}
                >
                    <PersonalityBar />
                </Container>
            </TMAppBar>
        );
    },
);
