import React from 'react';
import { Dialog } from '@talentmesh/core';
import CTMonitoringWarningDialogActions from './CTMonitoringWarningDialogActions';
import CTMonitoringWarningDialogBody from './CTMonitoringWarningDialogBody';
import CTMonitoringWarningDialogHeader from './CTMonitoringWarningDialogHeader';

interface CTMonitoringWarningDialogProps {
    onCancel: () => void;
    onConfirm: () => void;
    isOpen: boolean;
}

function CTMonitoringWarningDialog({ onCancel, onConfirm, isOpen }: CTMonitoringWarningDialogProps): JSX.Element {
    return (
        <Dialog open={isOpen} id={Math.random().toString()} keepMounted>
            <CTMonitoringWarningDialogHeader />
            <CTMonitoringWarningDialogBody />
            <CTMonitoringWarningDialogActions onCancel={onCancel} onConfirm={onConfirm} />
        </Dialog>
    );
}

export default CTMonitoringWarningDialog;
