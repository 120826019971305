import { useTheme } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Button, Stack, Typography } from '@talentmesh/core';
import { RHFAutocomplete, RHFAutocompleteAsync } from '@talentmesh/forms';
import React from 'react';
import EducationLevelOptionsGetter from '../../../../../Components/ProfileFields/Models/EducationLevelOptions';
import ProfileSettingsLabelTooltip from '../../Components/ProfileSettingsLabelTooltip';
import { useSettingsClient } from '../../../../../Hooks/ClientHooks';
import EditTitle from './EditTitle';
import EducationsFieldsNames from '../EducationsFieldsNames';
import CustomRHFTextField from '../../../../../Components/Forms/CustomRHFTextField';
import CustomRHFDatePicker from '../../../../../Components/Forms/CustomRHFDatePicker';
import useTMTranslation from '../../../../../Hooks/useTMTranslation';

export interface EducationEditProps {
    index: number;
    onCancel: () => void;
    onSave: () => void;
}

const name = EducationsFieldsNames.EducationsName;

export function EducationEdit({ index, onCancel, onSave }: EducationEditProps): JSX.Element {
    const theme = useTheme();
    const { tCommon: t } = useTMTranslation();

    const client = useSettingsClient();

    const getAutocompleteOptions = async (search: string) => {
        return search === '' ? [] : await client.getEducationFields(search);
    };

    return (
        <Stack sx={{ backgroundColor: theme.palette.background.aliciaBlue }} spacing={2}>
            <EditTitle isLatestEducation={index === 0} />
            <CustomRHFTextField name={`${name}[${index}].schoolName`} label={t('NameOfSchool')} />

            <Stack direction="column" spacing={theme.spacing(1)} width="100%">
                <ProfileSettingsLabelTooltip
                    htmlFor="educationField"
                    tooltipTitle={t('FieldOfEducationTooltipTitle')}
                    tooltipDescription={<Typography variant="body2">{t('FieldOfEducationTooltipContent')}</Typography>}
                >
                    {`${t('FieldOfEducation')}*`}
                </ProfileSettingsLabelTooltip>
                <RHFAutocompleteAsync
                    name={`${name}[${index}].educationField`}
                    getAutocompleteOptions={getAutocompleteOptions}
                />
            </Stack>

            <Stack direction="column" spacing={theme.spacing(1)} width="100%">
                <ProfileSettingsLabelTooltip
                    htmlFor="educationLevel"
                    tooltipTitle={t('HighestLevelOfEducationTooltipTitle')}
                    tooltipDescription={
                        <Typography variant="body2">{t('HighestLevelOfEducationTooltipContent')}</Typography>
                    }
                >
                    {`${t('LevelOfEducation')}*`}
                </ProfileSettingsLabelTooltip>
                <RHFAutocomplete name={`${name}[${index}].educationLevel`} options={EducationLevelOptionsGetter(t)} />
            </Stack>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction="row" spacing={2}>
                    <CustomRHFDatePicker name={`${name}[${index}].startDate`} label={t('StartDate')} />
                    <CustomRHFDatePicker name={`${name}[${index}].endDate`} label={t('EndDate')} />
                </Stack>
            </LocalizationProvider>

            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                {/* The addition/removal of the first element is controlled by yes/no checkbox */}
                {index !== 0 && (
                    <Button data-cy="educationCancel" onClick={onCancel}>
                        {t('Cancel')}
                    </Button>
                )}
                <Button data-cy="educationSave" variant="contained" onClick={onSave}>
                    {t('Save')}
                </Button>
            </Stack>
        </Stack>
    );
}
