import { useTheme } from '@mui/material';
import { Button, Stack, Typography } from '@talentmesh/core';
import { AutoAwesome } from '@talentmesh/icons';
import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useOnBoardingContext } from '../../../../../../Context/OnBoardingContext';
import useTMTranslation from '../../../../../../Hooks/useTMTranslation';
import ProfilePaper from '../../../../../Components/Profile/Common/ProfilePaper';
import CvFilePreview from './CvFilePreview/CvFilePreview';
import { ApplicantProfileFormValues } from '../../../../../MyAssessment/ProfileSettings/utils/ApplicantProfileUtils';
import ProcessingState from '../../../../../../Models/ProcessingState';

const CvUpload = () => {
    const inputFileRef = useRef<HTMLInputElement>(null);
    const theme = useTheme();
    const { tCommon } = useTMTranslation();
    const form = useFormContext<ApplicantProfileFormValues>();
    const { cvFileValidationError, uploadAndParseCv, cvProcessingState, currentFilename } = useOnBoardingContext();

    useEffect(() => {
        // upload cv && initial value (when going forward & back)
        if (currentFilename) {
            form.setValue('cvFile', currentFilename, { shouldValidate: true });
        }
    }, [currentFilename]);

    const onFileUploadAsync = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.files) {
            const file = event.currentTarget.files[0];
            await uploadAndParseCv(file);
        }
    };

    return (
        <ProfilePaper header={tCommon('EffortlesslyParseYourResumeWithAI')}>
            <Stack spacing={2.5}>
                <Stack direction="row" spacing={1.25} sx={{ placeItems: 'center' }}>
                    <Button
                        fullWidth
                        data-cy="cvFileButton"
                        startIcon={<AutoAwesome />}
                        variant="contained"
                        color="primary"
                        disabled={cvProcessingState === ProcessingState.Processing || currentFilename != null}
                        onClick={() => inputFileRef.current?.click()}
                    >
                        {tCommon('ImportYourCV')}
                        <input
                            hidden
                            data-cy="cvFile"
                            ref={inputFileRef}
                            type="file"
                            onChange={onFileUploadAsync}
                            accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf"
                        />
                    </Button>
                    <Typography variant="body2">{tCommon('SimplifyYourApplicationProcessUpload')}</Typography>
                </Stack>
                {currentFilename && <CvFilePreview />}
            </Stack>
            {form.formState.errors.cvFile?.message && !cvFileValidationError && (
                <Typography variant="caption" color={theme.palette.error.main}>
                    {form.formState.errors.cvFile?.message}
                </Typography>
            )}
            {cvFileValidationError && (
                <Typography variant="caption" color={theme.palette.error.main}>
                    {cvFileValidationError}
                </Typography>
            )}
        </ProfilePaper>
    );
};

export default CvUpload;
