import * as yup from 'yup';
import libphonenumber from 'google-libphonenumber';
import ApplicantInformationType from '../../../../../../Models/ApplicantInformationType';
import Constants from '../Constants';
import ValidationErrors from '../../../../../../Utils/ValidationErrors';
import { CommonResourceKey } from '../../../../../../i18n/byNamespace/i18nCommonResourceKeys';
import { TMTranslationGetter } from '../../../../../../Hooks/useTMTranslation';

export const MAX_SALARY = 9999999999999998; // this is maximum number allowed by eslint

function getYupSchemaInformation(t: TMTranslationGetter<CommonResourceKey>, type: ApplicantInformationType) {
    const {
        InvalidInputError,
        EmptyFieldError,
        NegativeValueError,
        SalaryExceedsSystemLimitError,
        IncorrectSalaryLimitsError,
        Required,
        PhoneNumberIsInvalid,
        FieldShouldNotExceed,
    } = ValidationErrors(t);

    switch (type) {
        case ApplicantInformationType.PhoneNumber:
            return yup.object({
                PhoneNumber: yup
                    .string()
                    .test('valid-phone-number', PhoneNumberIsInvalid, (value) => {
                        if (!value) {
                            return true; // empty values are allowed, let required() handle it
                        }

                        try {
                            const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
                            const phoneNumber = phoneUtil.parse(value, undefined);
                            return phoneUtil.isValidNumber(phoneNumber);
                        } catch (err) {
                            return false;
                        }
                    }) // Not the best solution, but it seems like where is no "good" way to make TypeScript supprt yup.addMethod()
                    .required(Required),
            });
        case ApplicantInformationType.NoticePeriod:
            return yup.object({
                NoticePeriod: yup.string().when('IsEmployed', {
                    is: Constants.isEmployed,
                    then: yup
                        .string()
                        .required(Required)
                        .max(Constants.textFieldSmallCapacity, FieldShouldNotExceed(Constants.textFieldSmallCapacity)),
                    otherwise: yup.string().notRequired(),
                }),
            });
        case ApplicantInformationType.ReasonForApplying:
            return yup.object({
                ReasonForApplying: yup.string().required(Required).max(Constants.textFieldLargeCapacity),
            });
        case ApplicantInformationType.ReasonForLeaving:
            return yup.object({
                ReasonForLeaving: yup
                    .string()
                    .when('IsEmployed', {
                        is: Constants.isEmployed,
                        then: yup.string().required(Required),
                        otherwise: yup.string().notRequired(),
                    })
                    .max(Constants.textFieldLargeCapacity),
            });
        case ApplicantInformationType.IsEmployed:
            return yup.object({ IsEmployed: yup.string().required() });
        case ApplicantInformationType.ValidWorkPermit:
            return yup.object({
                ValidWorkPermit: yup.string().required(Required).max(Constants.yesNoCapacity),
            });
        case ApplicantInformationType.WhyWorkInStartup:
            return yup.object({
                WhyWorkInStartup: yup.string().required(Required).max(Constants.textFieldLargeCapacity),
            });
        case ApplicantInformationType.RankRemunerationLearningBalance:
            return yup.object({
                RankRemunerationLearningBalance: yup.string().required(Required).max(Constants.textFieldLargeCapacity),
            });
        case ApplicantInformationType.StructureImportance:
            return yup.object({
                StructureImportance: yup.string().required(Required).max(Constants.textFieldLargeCapacity),
            });
        case ApplicantInformationType.PersonalProject:
            return yup.object({
                PersonalProject: yup.string().required(Required).max(Constants.textFieldLargeCapacity),
            });
        case ApplicantInformationType.WhereDoYouSeeYourself:
            return yup.object({
                WhereDoYouSeeYourself: yup.string().required(Required).max(Constants.textFieldLargeCapacity),
            });
        case ApplicantInformationType.SalaryExpectation:
            return yup.object({
                SalaryExpectation: yup.object({
                    min: yup
                        .number()
                        .typeError(InvalidInputError)
                        .positive(NegativeValueError)
                        .required(EmptyFieldError)
                        .max(MAX_SALARY, SalaryExceedsSystemLimitError), // this is max number before system complains about precision
                    max: yup
                        .number()
                        .typeError(InvalidInputError)
                        .positive(NegativeValueError)
                        .required(EmptyFieldError)
                        .max(MAX_SALARY, SalaryExceedsSystemLimitError) // this is max number before system complains about precision
                        .when('min', (salaryExpectationMin, schema) => {
                            return schema.test({
                                name: 'maxGreaterThanMin',
                                test(value?: number) {
                                    if (salaryExpectationMin !== undefined && value !== undefined) {
                                        return value >= salaryExpectationMin;
                                    }
                                    return true; // If either field is not present, don't perform the test.
                                },
                                message: IncorrectSalaryLimitsError,
                            });
                        }),
                    currency: yup.object().shape({
                        id: yup.string(),
                        label: yup.string().required(EmptyFieldError),
                    }),
                }),
            });
        default:
            return yup.object({});
    }
}

export default getYupSchemaInformation;
