import { CommonResourceKey } from './byNamespace/i18nCommonResourceKeys';
import { TestResourceKey } from './byNamespace/i18nTestResourceKeys';

export const CommonLanguageCodes = ['en', 'de', 'it', 'fr', 'es', 'pt'] as const;

export type CommonLanguageCode = (typeof CommonLanguageCodes)[number];

export type TMSupportedLanguage = Record<CommonLanguageCode, string>;

export const TMSupportedLanguages: TMSupportedLanguage = {
    en: 'English',
    de: 'Deutsch',
    it: 'Italian',
    fr: 'French',
    es: 'Spanish',
    pt: 'Portuguese',
};

export const TMLSupportedLanguageCode: Array<CommonLanguageCode> = [...CommonLanguageCodes];

export const TMFallbackLanguageCode: CommonLanguageCode = 'en';

export const TMTranslationNamespaces = ['common', 'test'] as const;

export type TMTranslationNamespace = (typeof TMTranslationNamespaces)[number];

export const DefaultTMTranslationNamespace: TMTranslationNamespace = 'common';

export type TMResourceKey = CommonResourceKey | TestResourceKey;
