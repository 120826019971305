import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Stack } from '@talentmesh/core';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import PermissionToggle from './PermissionToggle';
import { CTMonitoringPanelFormValues } from './CTMonitoringTogglesForm';
import { useScreenSharingContext } from '../../../Context/ScreenSharingContext';
import useTMTranslation from '../../../Hooks/useTMTranslation';

function CTMonitoringTogglesPanel(): JSX.Element {
    const { tTest } = useTMTranslation();
    const { values, setFieldValue } = useFormikContext<CTMonitoringPanelFormValues>();
    const { isFullScreenEnabled, isScreenInvigilationEnabled } = values;
    const { setMedia } = useScreenSharingContext();
    const { trackException } = useAppInsightsContext();

    const manageFullScreen = async (checked: boolean) => {
        try {
            if (checked && !document.fullscreenElement) {
                document.documentElement.requestFullscreen();
            } else if (!checked && document.fullscreenElement) {
                await document.exitFullscreen();
            }
            // eslint-disable-next-line no-empty
        } catch (e: any) {
            // SHIT CODE
            // need to figure out how to mock module function in Cypress (see mock-in-bundle)
            // for now have to check if code runs under it
            if (!window.hasOwnProperty('Cypress')) {
                trackException({ exception: e });
            }
        }
    };

    const manageScreenInvigilation = async (checked: boolean) => {
        let media: MediaStream | undefined;
        if (checked) {
            try {
                media = await navigator.mediaDevices.getDisplayMedia();
            } catch {
                setFieldValue('isScreenInvigilationEnabled', false);
            }
        }

        setMedia(media);
    };

    useEffect(() => {
        const doUseEffect = async () => {
            await manageFullScreen(values.isFullScreenEnabled);
        };
        doUseEffect();
    }, [isFullScreenEnabled]);

    useEffect(() => {
        const doManageInvigilation = async () => {
            await manageScreenInvigilation(values.isScreenInvigilationEnabled);
        };
        doManageInvigilation();
    }, [isScreenInvigilationEnabled]);

    return (
        <Stack direction="row" spacing={2.5} sx={{ alignItems: 'flex-start' }}>
            <PermissionToggle
                header={tTest('EnableScreenSnapshots')}
                disclaimer={tTest('EnableScreenSnapshotsDisclaimer')}
                name="isScreenInvigilationEnabled"
            />
            <PermissionToggle
                header={tTest('EnableWebcamSnapshots')}
                disclaimer={tTest('EnableWebcamSnapshotsDisclaimer')}
                name="isWebcamEnabled"
            />
            <PermissionToggle
                header={tTest('FullscreenMode')}
                disclaimer={tTest('FullscreenModeDisclaimer')}
                name="isFullScreenEnabled"
                checked={document.fullscreenElement !== null || document.fullscreenElement !== undefined}
            />
        </Stack>
    );
}

export default CTMonitoringTogglesPanel;
