import { useTheme } from '@mui/material/styles';
import { Stack, TextField } from '@talentmesh/core';
import React from 'react';
import { RHFTextField } from '@talentmesh/forms';
import ProfileSettingsFieldsNames from '../Components/ProfileSettingsFieldsNames';
import ProfilePaper from '../Common/ProfilePaper';
import LocationField from './LocationField';
import { useUserContext } from '../../../../Context/UserContext';
import useTMTranslation from '../../../../Hooks/useTMTranslation';

interface NameLocationProps {
    showEmail?: boolean;
}

const NameAndLocation: React.FC<NameLocationProps> = ({ showEmail }) => {
    const theme = useTheme();
    const { user } = useUserContext();
    const { tCommon: t } = useTMTranslation();

    return (
        <ProfilePaper header={t('NameAndLocation')}>
            <Stack spacing={2}>
                <Stack direction="row" spacing={theme.spacing(3)} justifyContent="space-around">
                    <RHFTextField label={t('FirstName')} name={ProfileSettingsFieldsNames.FirstName} fullWidth />
                    <RHFTextField label={t('LastName')} name={ProfileSettingsFieldsNames.LastName} fullWidth />
                </Stack>
                {showEmail && <TextField value={user.email} data-cy="email" label={t('Email')} disabled fullWidth />}
                <LocationField />
            </Stack>
        </ProfilePaper>
    );
};

export default NameAndLocation;
