/* eslint-disable max-classes-per-file */

/**
 * Error response follows RFC7807.
 *
 * @docs https://datatracker.ietf.org/doc/html/rfc7807
 */
export default interface ErrorResponse {
    detail: string;
    instance: string;
    status: number;
    title: string;
    type: string;
    [key: string]: any;
}

export class HttpError extends Error {
    constructor(
        public title: string,
        public status: number,
        public type: string,
        public instance?: string,
        public detail?: string,
    ) {
        super(title);
    }
}

export function handleHttpError(payload: ErrorResponse): HttpError {
    return new HttpError(payload.title, payload.status, payload.type, payload.instance, payload.detail);
}
