import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { ApplicantAssessmentInfo } from '../../../../../../../Models/ApplicantAssessmentInfo';
import RoutePath from '../../../../../../../Routing/RoutePath';
import RouteParam from '../../../../../../../Routing/RouteParam';
import TestActionButton from './TestActionButton';
import SearchParam from '../../../../../../../Routing/SearchParam';
import useTMTranslation from '../../../../../../../Hooks/useTMTranslation';

interface OnBoardTestButtonProps extends Pick<ApplicantAssessmentInfo, 'id'> {}

function OnBoardTestButton({ id }: OnBoardTestButtonProps) {
    const navigate = useNavigate();
    const { tCommon: t } = useTMTranslation();

    const clickHandler = async () => {
        const path = generatePath(RoutePath.AssessmentOnboard, {
            [RouteParam.AssessmentId]: id,
        });
        navigate(path, { state: { redirect: `${RoutePath.Assessments}?${SearchParam.AssessmentId}=${id}` } });
    };

    return <TestActionButton id={id} onClick={clickHandler} label={t('ProvideApplicantInformation')} />;
}

export default OnBoardTestButton;
