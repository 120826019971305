import { EducationFormValues } from '../../../MyAssessment/ProfileSettings/utils/ApplicantProfileUtils';
import { isValidDate, isEmptyAutocompleteOption, isEmptyEducationLevel } from '../utils';

function isEducationEditRequired(form: EducationFormValues): boolean {
    return (
        form.schoolName === '' ||
        isEmptyAutocompleteOption(form.educationField) ||
        isEmptyEducationLevel(form.educationLevel) ||
        !isValidDate(form.startDate) ||
        !isValidDate(form.endDate) ||
        (form.endDate !== null && form.startDate !== null && form.endDate < form.startDate)
    );
}

export default isEducationEditRequired;
