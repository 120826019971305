import React from 'react';
import { Outlet } from 'react-router';
import NotificationSystem from '../Components/NotificationSystem/NotificationSystem';
import { LocalizationContextProvider } from './LocalizationContext';
import { UserProvider } from './UserContext';

function CandidateApplicationContextProvider(): JSX.Element {
    return (
        <UserProvider>
            <NotificationSystem>
                <LocalizationContextProvider>
                    <Outlet />
                </LocalizationContextProvider>
            </NotificationSystem>
        </UserProvider>
    );
}

export default CandidateApplicationContextProvider;
