import React from 'react';
import { Paper, Stack } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import { Question } from '../../../../Models/Question';
import SkillsButtonsPanel from './SkillsButtonsPanel';
import TestAnswers from './TestAnswers';

interface ActionsPanelProps {
    question: Question;
}

function ActionsPanel({ question }: ActionsPanelProps): JSX.Element {
    const theme = useTheme();
    return (
        <Paper square elevation={0} sx={{ padding: 3, paddingTop: 2, height: '100%', maxWidth: theme.spacing(70) }}>
            <Stack direction="column" justifyContent="space-between">
                <TestAnswers questionType={question.questionType} answers={question.answers} />
                <SkillsButtonsPanel question={question} />
            </Stack>
        </Paper>
    );
}

export default ActionsPanel;
