import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@talentmesh/core';
import JobApplicationSearch from './components/JobApplicationSearch';
import useInternalTranslation from '../../../../../Hooks/useTMTranslation';

function JobApplicationHeader() {
    const theme = useTheme();
    const { tCommon: t } = useInternalTranslation();

    return (
        <Box data-cy="JobApplicationHeader" sx={{ marginTop: theme.spacing(4.75) }}>
            <Stack direction="row" sx={{ alignItems: 'end', justifyContent: 'space-between', width: '100%' }}>
                <Typography variant="h3" sx={{ color: theme.palette.secondary.main }}>
                    {t('JobApplications')}
                </Typography>
                <JobApplicationSearch />
            </Stack>
        </Box>
    );
}

export default JobApplicationHeader;
