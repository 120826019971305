import React, { useEffect, useState } from 'react';
import useTestActions from './useTestActions';
import { getTestActionButtonLabelByTestInfo } from '../../../../utils/JobApplicationUtil';
import { TestInfo, TestStates } from '../../../../../../../Models/TestInfo';
import { ApplicantAssessmentInfo } from '../../../../../../../Models/ApplicantAssessmentInfo';
import TestActionButton from './TestActionButton';
import { useCandidateTestMonitoringContext } from '../../../../../../../Context/CandidateTestMonitoringContext';
import TestActionDialogs from './TestActionDialogs';
import useNavigation from '../../../../../../../Hooks/useNavigation';
import useTMTranslation from '../../../../../../../Hooks/useTMTranslation';

interface TestActionButtonProps
    extends Pick<ApplicantAssessmentInfo, 'id' | 'tests' | 'companyName' | 'assessmentStatus'> {
    isOngoing: boolean;
}

function StartTestButton({ id, isOngoing, tests, companyName, assessmentStatus }: TestActionButtonProps) {
    const { navigateToWelcomePage, navigateToMonitoringPage, navigateToTestPage, navigateToRecruitmentClosedPage } =
        useNavigation();
    const [activeTest, setActiveTest] = useState<TestInfo | undefined>(undefined);
    const { startTestHandlerAsync } = useTestActions({ id });
    const { areSettingsRequired } = useCandidateTestMonitoringContext();
    const { tCommon, tTest } = useTMTranslation();

    const clickHandler = async () => {
        if (assessmentStatus === 'Closed') {
            navigateToRecruitmentClosedPage();
        } else if (!isOngoing) {
            navigateToWelcomePage(id, activeTest, companyName);
        } else if (activeTest?.startedAt !== null) {
            navigateToTestPage(id, activeTest?.testId ?? '');
        } else if (areSettingsRequired(id)) {
            navigateToMonitoringPage(id, activeTest, companyName);
        } else {
            startTestHandlerAsync(activeTest);
        }
    };

    const actionLabel = getTestActionButtonLabelByTestInfo(activeTest ?? tests[tests.length - 1], tCommon, tTest);

    useEffect(() => {
        const test = tests.find((x) => x.testState === TestStates.Active);
        if (test) {
            setActiveTest(test);
        } else {
            setActiveTest(undefined);
        }
    }, [tests]);

    return (
        <>
            <TestActionButton id={id} onClick={clickHandler} label={actionLabel} />
            <TestActionDialogs id={id} activeTest={activeTest} />
        </>
    );
}

export default StartTestButton;
