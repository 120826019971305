import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { LoadingOverlay } from '@talentmesh/core';
import { useScreenSharingContext } from '../../Context/ScreenSharingContext';
import { TestPageParams } from './Models/TestPageParams';
import ProcessingState from '../../Models/ProcessingState';
import { TestTypes } from '../../Models/Configuration';
import { TestContext, useTestContext } from './Contexts/TestContext';
import SkillsTestPage from './Skills/SkillsTestPage';
import PersonalityTestPage from './Personality/PersonalityTestPage';
import useTestContextValue from './Contexts/UseTestContextValue';
import useSnapshots from '../../Snapshots/UseSnapshots';
import { useQuestionConfigurationClient, useTestsClient } from '../../Hooks/ClientHooks';
import Constants from '../../Models/Constants';
import withTalentMeshUserRequired from '../../Routing/withTalentMeshUserRequired';
import { useNotificationContext } from '../../Context/NotificationContext';
import useNavigation from '../../Hooks/useNavigation';
import useTMTranslation from '../../Hooks/useTMTranslation';
import { useLocalizationContext } from '../../Context/LocalizationContext';

function TestPageInner(): JSX.Element {
    const { test } = useTestContext();
    return (
        <>
            {(test.testType === TestTypes.Skills || test.testType === TestTypes.Aptitude) && <SkillsTestPage />}
            {(test.testType === TestTypes.Personality || test.testType === TestTypes.EmotionalIntelligence) && (
                <PersonalityTestPage />
            )}
        </>
    );
}

function TestPage(): JSX.Element {
    const { assessmentId, testId } = useParams<TestPageParams>();
    const testContextValue = useTestContextValue();
    const { test, processingState, setProcessingState, setTest, setLikert, setLikertGroups, setAssessmentId } =
        testContextValue;
    const { media } = useScreenSharingContext();
    const { showFailToaster, showSuccessToaster } = useNotificationContext();
    useSnapshots(test, media);
    const questionsConfigurationClient = useQuestionConfigurationClient();
    const testClient = useTestsClient();
    const { replaceWithErrorPage } = useNavigation();
    const { tTest, tCommon } = useTMTranslation();
    const { switchToTestLanguage } = useLocalizationContext();
    const [loadingTest, setLoadingTest] = useState(false);

    const doGetTest = async () => {
        try {
            setLoadingTest(true);
            setProcessingState(ProcessingState.Processing);
            setAssessmentId(assessmentId!);

            const testState = await testClient.getTestStateAsync(assessmentId!, testId!);
            switchToTestLanguage(testState.locale);
            setTest(testState);

            const likertAnswerGroups = await questionsConfigurationClient.getLikertAnswerGroups(testState.locale);
            if (likertAnswerGroups.length > 0) {
                const likertGroup = likertAnswerGroups.find((x) => x.groupName === Constants.StronglyAgreeGroupName);
                setLikertGroups(likertAnswerGroups);
                if (likertGroup) {
                    setLikert(likertGroup);
                }
            }
            setProcessingState(ProcessingState.Success);
            setLoadingTest(false);
        } catch {
            replaceWithErrorPage(true);
        }
    };

    useEffect(() => {
        doGetTest();
    }, []);

    useEffect(() => {
        if (processingState === ProcessingState.Error) {
            showFailToaster(tCommon('SomethingWentWrongPleaseTryAgain'));
        }
    }, [processingState, showFailToaster]);

    useEffect(() => {
        const onNetworkRestore = () => {
            showSuccessToaster(tTest('NetworkReestablishedPleaseContinueOnWithTheTest'), tTest('ConnectionRestored'));
            window.removeEventListener('online', onNetworkRestore);
        };
        const onNetworkError = () => {
            showFailToaster(tTest('NetworkErrorOccurredPleaseCheckYourInternetandTryAgain'), tTest('ConnectionLost'));
            window.addEventListener('online', onNetworkRestore);
        };

        window.addEventListener('offline', onNetworkError);

        return () => {
            window.removeEventListener('offline', onNetworkError);
            window.removeEventListener('online', onNetworkRestore);
        };
    }, [showFailToaster]);

    return (
        <TestContext.Provider value={testContextValue}>
            {loadingTest ? <LoadingOverlay /> : <TestPageInner />}
        </TestContext.Provider>
    );
}

export default withTalentMeshUserRequired(TestPage);
