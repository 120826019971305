import { mapApplicantProfile2DTO } from '../DTO/Assessments/ApplicantProfileDTO';
import { ApplicantAppSettingsDTO, DefaultApplicantAppSettingsDTO } from '../DTO/Users/ApplicantAppSettingsDTO';
import CandidateDTO from '../DTO/Users/CandidateDTO';
import { ApplicantAppSettings, mapApplicantAppSettingsDTOtoApplicantAppSettings } from '../Models/ApplicantAppSettings';
import { ApplicantProfile } from '../Models/ApplicantProfile';
import Candidate, { DefaultCandidate, mapCandidateDTO2Model } from '../Models/Candidate';
import ContentTypes from '../Utils/ContentTypes';
import { get, post, put } from '../Utils/HTTP/HttpUtils';
import ClientBase from './ClientBase';

export default class UsersClient extends ClientBase {
    getUser = async (): Promise<Candidate> => {
        const result = await get<CandidateDTO>({
            path: this.addHost(`users/candidates`),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (result.ok && result.body) {
            return mapCandidateDTO2Model(result.body);
        }
        return DefaultCandidate;
    };

    upsertUser = async (input: ApplicantProfile): Promise<Candidate> => {
        const dto = mapApplicantProfile2DTO(input);
        const result = await put<CandidateDTO>({
            path: this.addHost('users/candidates'),
            body: JSON.stringify(dto),
            contentType: ContentTypes.ApplicationJson,
            accessToken: await this.getAccessTokenSilently(),
        });
        if (result.ok && result.body) {
            return mapCandidateDTO2Model(result.body);
        }
        return DefaultCandidate;
    };

    saveAppLanguageAsync = async (locale: ApplicantAppSettings): Promise<void> => {
        await post<ApplicantAppSettingsDTO>({
            path: this.addHost('users/applicant-app-settings'),
            body: JSON.stringify(locale),
            contentType: ContentTypes.ApplicationJson,
            accessToken: await this.getAccessTokenSilently(),
        });
    };

    getAppLanguage = async (): Promise<ApplicantAppSettings> => {
        const { ok, body } = await get<ApplicantAppSettingsDTO>({
            path: this.addHost('users/applicant-app-settings'),
            accessToken: await this.getAccessTokenSilently(),
        });

        if (ok && body) {
            return mapApplicantAppSettingsDTOtoApplicantAppSettings(body);
        }

        return DefaultApplicantAppSettingsDTO;
    };
}
