import { useEffect } from 'react';
import { useBlocker } from 'react-router';

interface PromptProps {
    message: string;
    when: boolean;
}

const Prompt = ({ message, when }: PromptProps) => {
    const blocker = useBlocker(when);

    useEffect(() => {
        if (blocker.state === 'blocked') {
            const confirmation = window.confirm(message); // eslint-disable-line no-alert

            if (confirmation) {
                blocker.proceed();
            } else {
                blocker.reset();
            }
        }
    }, [blocker.state]);

    useEffect(() => {
        window.onbeforeunload = (event) => {
            event.preventDefault();
            if (when) {
                return '';
            }

            return undefined;
        };

        return () => {
            window.onbeforeunload = null;
        };
    }, [when]);

    return null;
};

export default Prompt;
