import { generatePath, useNavigate } from 'react-router';
import RoutePath from '../Routing/RoutePath';
import RouteParam from '../Routing/RouteParam';
import SearchParam from '../Routing/SearchParam';
import { TestInfo } from '../Models/TestInfo';
import ErrorQueryNames from '../Pages/Errors/ErrorQueryNames';

interface UseNavigationInterface {
    navigateToJobApplicationsPage: (assessmentId?: string) => void;
    replaceWithJobApplicationsPage: () => void;
    navigateToWelcomePage: (assessmentId: string, activeTest: TestInfo | undefined, companyName: string) => void;
    navigateToMonitoringPage: (assessmentId: string, activeTest: TestInfo, companyName: string) => void;
    navigateToOnBoardingPage: (assessmentId: string) => void;
    navigateToTestPage: (assessmentId: string, testId: string) => void;
    navigateToRecruitmentClosedPage: () => void;
    replaceWithRecruitmentClosedPage: () => void;
    replaceWithErrorPage: (showBackButton: boolean) => void;
}

function useNavigation(): UseNavigationInterface {
    const navigate = useNavigate();

    const navigateToJobApplicationsPage = (assessmentId?: string) => {
        let path = RoutePath.Assessments;
        if (assessmentId) {
            path = `${path}?${SearchParam.AssessmentId}=${assessmentId}`;
        }
        navigate(path);
    };

    const replaceWithJobApplicationsPage = () => {
        navigate(RoutePath.Assessments);
    };

    const navigateToWelcomePage = (assessmentId: string, activeTest: TestInfo | undefined, companyName: string) => {
        const path = {
            pathname: generatePath(RoutePath.AssessmentWelcome, {
                [RouteParam.AssessmentId]: assessmentId,
            }),
        };
        navigate(path, { state: { activeTest, companyName } });
    };

    const navigateToMonitoringPage = (assessmentId: string, activeTest: TestInfo, companyName: string) => {
        const path = {
            pathname: generatePath(RoutePath.AssessmentCTMonitoringPage, {
                [RouteParam.AssessmentId]: assessmentId,
            }),
        };
        navigate(path, { state: { activeTest, companyName } });
    };

    const navigateToOnBoardingPage = (assessmentId: string) => {
        const path = generatePath(RoutePath.AssessmentOnboard, {
            [RouteParam.AssessmentId]: assessmentId,
        });
        navigate(path);
    };

    const navigateToTestPage = (assessmentId: string, testId: string) => {
        const path = generatePath(RoutePath.AssessmentTest, {
            [RouteParam.AssessmentId]: assessmentId,
            [RouteParam.TestId]: testId,
        });
        navigate(path);
    };

    const navigateToRecruitmentClosedPage = () => {
        navigate(RoutePath.RecruitmentClosedPage);
    };

    const replaceWithRecruitmentClosedPage = () => {
        navigate(RoutePath.RecruitmentClosedPage, { replace: true });
    };

    const replaceWithErrorPage = (showBackButton: boolean) => {
        let path = RoutePath.Error;
        if (showBackButton) {
            path = `${path}?${ErrorQueryNames.ShowBackButton}=true`;
        }
        navigate(path, { replace: true });
    };

    return {
        navigateToJobApplicationsPage,
        replaceWithJobApplicationsPage,
        navigateToWelcomePage,
        navigateToMonitoringPage,
        navigateToOnBoardingPage,
        navigateToTestPage,
        navigateToRecruitmentClosedPage,
        replaceWithRecruitmentClosedPage,
        replaceWithErrorPage,
    };
}

export default useNavigation;
