import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, TMTopBar, Typography } from '@talentmesh/core';
import Constants from '../../../../Models/Constants';
import RoutePath from '../../../../Routing/RoutePath';
import { testTypeToName } from '../../../../Utils/mappings';
import { useTestContext } from '../../Contexts/TestContext';
import { CircularProgressWithLabel } from './CircularProgressWithLabel';
import { getDelta, getElapsed } from './SkillsPageUtils';
import useTMTranslation from '../../../../Hooks/useTMTranslation';

function SkillsTestTopBar(): JSX.Element {
    const [progress, setProgress] = useState(0);
    const theme = useTheme();
    const { tCommon: t } = useTMTranslation();
    const { test, elapsedTime, setElapsedTime } = useTestContext();
    const { timeLeftSeconds, testType, allocatedTimeSeconds } = test;

    const makeStep = (finish: Date) => {
        const now = new Date();
        const elapsed = getElapsed(now, finish);
        setElapsedTime(elapsed);
        if (allocatedTimeSeconds) {
            const delta = getDelta(now, finish);
            setProgress((100 * (allocatedTimeSeconds - delta)) / allocatedTimeSeconds);
        }
    };

    const dispose = (timer: number | undefined) => {
        if (timer) {
            clearTimeout(timer);
        }
    };

    useEffect(() => {
        let timer: number | undefined;
        if (timeLeftSeconds) {
            const finish = new Date();
            finish.setSeconds(finish.getSeconds() + timeLeftSeconds);
            timer = window.setInterval(makeStep, 1000, finish);
        } else if (timeLeftSeconds === 0) {
            setElapsedTime(Constants.TimeOut);
        }

        return () => {
            dispose(timer);
        };
    }, []);

    return (
        <TMTopBar
            position="sticky"
            logoClickURL={RoutePath.Home}
            logoLinkComponent={Link}
            sx={{ 'div:first-of-type': { backgroundColor: theme.palette.table.main } }}
        >
            <Box sx={{ paddingLeft: theme.spacing(4) }}>
                <Typography
                    variant="h5"
                    color={theme.palette.text.primary}
                    sx={{
                        display: 'flex',
                    }}
                >
                    {testTypeToName(testType, t)}
                </Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ paddingRight: theme.spacing(4) }}>
                <CircularProgressWithLabel elapsedTime={elapsedTime} progress={progress} />
            </Box>
        </TMTopBar>
    );
}

export default SkillsTestTopBar;

export const SkillsTestTopBarHeight = '90px';
