import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { Box, Card, SpanTypography } from '@talentmesh/core';
import { AssignmentIcon, LogoutOutlined, OpenInNewIcon, PersonIcon } from '@talentmesh/icons';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { siteUrls } from '../../AppSettings';
import { useUserContext } from '../../Context/UserContext';
import RoutePath from '../../Routing/RoutePath';
import NavTab from './NavTab';
import VerticalNavTabs from './VerticalNavTabs';
import useTMTranslation from '../../Hooks/useTMTranslation';

interface SideNavigationProps {
    sx?: SxProps<Theme>;
}

const SideNavigation = ({ sx }: SideNavigationProps) => {
    const theme = useTheme();
    const location = useLocation();
    const { user, logOut } = useUserContext();
    const { tCommon: t } = useTMTranslation();

    return (
        <Card
            elevation={4}
            sx={{ p: theme.spacing(2.5), marginY: theme.spacing(1.25), ...sx }}
            data-cy="SideNavigation"
        >
            <Box sx={{ marginBottom: theme.spacing(1.25), textAlign: 'center' }}>
                <SpanTypography variant="body1" color={theme.palette.text.hint}>
                    {`${t('Welcome')} `}
                </SpanTypography>
                <SpanTypography variant="h5" style={{ color: theme.palette.text.hint }}>
                    {user.firstName}
                </SpanTypography>
            </Box>
            <VerticalNavTabs
                textColor="secondary"
                value={location.pathname}
                sx={{ '& .MuiTab-root': { textAlign: 'left' } }}
            >
                <NavTab
                    icon={<AssignmentIcon />}
                    label={t('JobApplications')}
                    component={Link}
                    to={RoutePath.Assessments}
                    value={RoutePath.Assessments}
                />
                <NavTab
                    icon={<PersonIcon />}
                    label={t('ProfileSettings')}
                    component={Link}
                    to={RoutePath.ProfileSettings}
                    value={RoutePath.ProfileSettings}
                />
                <NavTab
                    icon={<OpenInNewIcon />}
                    label={`${t('NeedHelp')} ${t('ContactUsCapitalized')}`}
                    component="a"
                    href={siteUrls.contactSupportUrl}
                    target="_blank"
                />
                <NavTab icon={<LogoutOutlined />} label={t('LogOut')} component="button" onClick={() => logOut()} />
            </VerticalNavTabs>
        </Card>
    );
};

export default SideNavigation;
