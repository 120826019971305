import React from 'react';
import { Typography } from '@talentmesh/core';
import CustomTitle from '../../Components/CustomTitle';
import useTMTranslation from '../../../../../Hooks/useTMTranslation';

interface EditTitleProps {
    isLatestEducation: boolean;
}

const EditTitle: React.FC<EditTitleProps> = ({ isLatestEducation }) => {
    const { tCommon: t } = useTMTranslation();

    return (
        <>
            {isLatestEducation && (
                <CustomTitle labelLeft={t('EnterYour')} labelMiddle={t('HighestLevel')} labelRight={t('OfEducation')} />
            )}
            {!isLatestEducation && <Typography variant="body1">{t('AddAdditionalDegreeOptional')}</Typography>}
        </>
    );
};

export default EditTitle;
