import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Stack, Box, SpanTypography } from '@talentmesh/core';
import useCTMonitoringPageRouteState from './useCTMonitoringPageRouteState';
import useTMTranslation from '../../../Hooks/useTMTranslation';

function CTMonitoringPageHeader(): JSX.Element {
    const theme = useTheme();
    const { tTest } = useTMTranslation();

    const { companyName: companyNameState } = useCTMonitoringPageRouteState();

    return (
        <Stack spacing={1.25}>
            <Box>
                <SpanTypography variant="h4" sx={{ color: theme.palette.primary.main }}>
                    {tTest('PleaseEnableIdentificationFeatures')}
                </SpanTypography>
                <SpanTypography variant="h4" sx={{ color: theme.palette.secondary.main }}>
                    {` ${tTest('BeforeStartingTheTest').toLocaleLowerCase()}`}
                </SpanTypography>
            </Box>
            <Box>
                <SpanTypography variant="body2" sx={{ color: theme.palette.text.primary }}>
                    {tTest('WeRecommend')}
                </SpanTypography>
                <SpanTypography
                    variant="body2"
                    sx={{ color: theme.palette.primary.main, fontWeight: theme.typography.fontWeightBold }}
                >
                    {tTest('ThisWillEnableRecruiters', { companyName: companyNameState ?? '' })}
                </SpanTypography>
                <SpanTypography variant="body2" sx={{ color: theme.palette.text.primary }}>{` ${tTest(
                    'YouMayChooseNotToTurnOn',
                )}`}</SpanTypography>
            </Box>
        </Stack>
    );
}

export default CTMonitoringPageHeader;
