import React, { ReactNode } from 'react';
import PageFailedLoading from './PageFailedLoading';
import PageLoading from './PageLoading';

interface PageContentLoadingProps {
    isLoading: boolean;
    loadingFailed?: boolean;
    children: ReactNode;
}

const PageContentLoading = ({ isLoading, loadingFailed, children }: PageContentLoadingProps): JSX.Element => {
    if (isLoading) {
        return <PageLoading />;
    }

    if (loadingFailed) {
        return <PageFailedLoading />;
    }

    return <>{children}</>;
};

export default PageContentLoading;
