import { Box } from '@talentmesh/core';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { EducationFormValues } from '../../../MyAssessment/ProfileSettings/utils/ApplicantProfileUtils';
import { EducationEdit } from './Edit/EducationEdit';
import { EducationsView } from './View/EducationsView';
import EducationsFieldsNames from './EducationsFieldsNames';
import { useEducationListContext } from './EducationListContext';

export interface EducationProps {
    index: number;
    initialIsEdit: boolean;
    onRemove: () => void;
    onCancel: () => void;
}

const name = EducationsFieldsNames.EducationsName;

export function Education({ index, initialIsEdit, onRemove, onCancel }: EducationProps): JSX.Element {
    const [isInEdit, setIsInEdit] = useState(initialIsEdit);
    const [educationSnapshot, setEducationSnapshot] = useState<EducationFormValues | undefined>(undefined);
    const { decrementEditingSubformsCounter, incrementEditingSubformsCounter } = useEducationListContext();

    const { trigger, setValue } = useFormContext();

    return (
        <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
            {isInEdit ? (
                <EducationEdit
                    index={index}
                    onCancel={() => {
                        if (educationSnapshot !== undefined) {
                            setValue(`${name}[${index}]`, educationSnapshot);
                        } else {
                            onCancel();
                        }
                        setIsInEdit(false);
                        decrementEditingSubformsCounter();
                    }}
                    onSave={async () => {
                        const result = await trigger(`${name}[${index}]`);
                        setIsInEdit(!result);
                        if (result) {
                            decrementEditingSubformsCounter();
                        }
                    }}
                />
            ) : (
                <EducationsView
                    index={index}
                    onRemove={onRemove}
                    onEdit={(isEdit: boolean, experience: EducationFormValues) => {
                        setIsInEdit(isEdit);
                        setEducationSnapshot(experience);
                        incrementEditingSubformsCounter();
                    }}
                />
            )}
        </Box>
    );
}
