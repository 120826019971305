import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography } from '@talentmesh/core';

interface RoundedNumberProps {
    value: number;
}

function RoundedNumber({ value }: RoundedNumberProps): JSX.Element {
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
                width: '100%',
                maxWidth: theme.spacing(3),
                height: theme.spacing(3),
                borderRadius: theme.shape.mediumBorderRadius,
                border: '1px solid',
                borderColor: theme.palette.primary.main,
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.common.white,
            }}
        >
            <Typography variant="body2">{value}</Typography>
        </Stack>
    );
}

export default RoundedNumber;
