import { useField } from 'formik';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@talentmesh/core';
import ApplicantInformationType from '../../../../../Models/ApplicantInformationType';
import ProfilePaper from '../../../../Components/Profile/Common/ProfilePaper';
import { useOnBoardingContext } from '../../../../../Context/OnBoardingContext';
import ApplicantPhoneNumber from './AdditionalInformationBlock/ApplicantPhoneNumber';
import IsEmployedDropdown from './AdditionalInformationBlock/IsEmployedDropdown';
import NoticePeriod from './AdditionalInformationBlock/NoticePeriod';
import ReasonForApplying from './AdditionalInformationBlock/ReasonForApplying';
import ReasonForLeaving from './AdditionalInformationBlock/ReasonForLeaving';
import Constants from './Constants';
import ValidWorkPermit from './AdditionalInformationBlock/ValidWorkPermit';
import WhyWorkInStartup from './AdditionalInformationBlock/WhyWorkInStartup';
import RankRemunerationLearningBalance from './AdditionalInformationBlock/RankRemunerationLearningBalance';
import StructureImportance from './AdditionalInformationBlock/StructureImportance';
import PersonalProject from './AdditionalInformationBlock/PersonalProject';
import WhereDoYouSeeYourself from './AdditionalInformationBlock/WhereDoYouSeeYourself';
import useTMTranslation from '../../../../../Hooks/useTMTranslation';
import Salary from './AdditionalInformationBlock/SalaryExpectation/Salary';

function AdditionalInformationBlock(): JSX.Element {
    const theme = useTheme();
    const { tCommon: t } = useTMTranslation();
    const { info } = useOnBoardingContext();
    const { assessmentAdditionalInformation } = info;

    const hasItem = (type: ApplicantInformationType) => assessmentAdditionalInformation.includes(type);
    const [field] = useField(ApplicantInformationType.IsEmployed);
    const isEmployedChecked = field.value === Constants.isEmployed;

    return (
        <ProfilePaper header={t('GeneralInformation')}>
            <Stack alignContent="center" direction="column" spacing={theme.spacing(2.5)}>
                {hasItem(ApplicantInformationType.PhoneNumber) && <ApplicantPhoneNumber />}
                {hasItem(ApplicantInformationType.ReasonForApplying) && <ReasonForApplying />}
                {(hasItem(ApplicantInformationType.ReasonForLeaving) ||
                    hasItem(ApplicantInformationType.NoticePeriod)) && <IsEmployedDropdown />}
                {hasItem(ApplicantInformationType.ReasonForLeaving) && isEmployedChecked && <ReasonForLeaving />}
                {hasItem(ApplicantInformationType.NoticePeriod) && isEmployedChecked && <NoticePeriod />}
                {hasItem(ApplicantInformationType.ValidWorkPermit) && <ValidWorkPermit />}
                {hasItem(ApplicantInformationType.SalaryExpectation) && <Salary />}
                {hasItem(ApplicantInformationType.WhyWorkInStartup) && <WhyWorkInStartup />}
                {hasItem(ApplicantInformationType.RankRemunerationLearningBalance) && (
                    <RankRemunerationLearningBalance />
                )}
                {hasItem(ApplicantInformationType.StructureImportance) && <StructureImportance />}
                {hasItem(ApplicantInformationType.PersonalProject) && <PersonalProject />}
                {hasItem(ApplicantInformationType.WhereDoYouSeeYourself) && <WhereDoYouSeeYourself />}
            </Stack>
        </ProfilePaper>
    );
}

export default AdditionalInformationBlock;
