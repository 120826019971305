import { useAssessmentClient, useTestsClient } from '../../../../../../../Hooks/ClientHooks';
import { ApplicantAssessmentInfo } from '../../../../../../../Models/ApplicantAssessmentInfo';
import { useAssessmentActionsContext } from '../../../../../../../Context/AssessmentActionsContext';
import { useCandidateTestMonitoringContext } from '../../../../../../../Context/CandidateTestMonitoringContext';
import { TestInfo } from '../../../../../../../Models/TestInfo';
import { useDialogsContext } from '../../../../../../../Context/DialogsContext';
import useNavigation from '../../../../../../../Hooks/useNavigation';

interface UseTestActionsInterface {
    safeStartTestAsync: (testId: string) => Promise<void>;
    startTestHandlerAsync: (activeTest: TestInfo | undefined) => Promise<void>;
}

interface TestActionsProps extends Pick<ApplicantAssessmentInfo, 'id'> {}

function useTestActions({ id }: TestActionsProps): UseTestActionsInterface {
    const testClient = useTestsClient();
    const { getSettingsByAssessmentId } = useCandidateTestMonitoringContext();

    const { setActiveAssessmentId, findUnfinishedTestAsync } = useAssessmentActionsContext();
    const { setCompleteTestDialogId, setTimerWarningDialogId } = useDialogsContext();
    const assessmentClient = useAssessmentClient();
    const { navigateToTestPage, replaceWithRecruitmentClosedPage, replaceWithErrorPage } = useNavigation();

    const isAssessmentOpenAsync = async () => {
        try {
            const assessmentStatus = await assessmentClient.getAssessmentStatusAsync(id);
            if (assessmentStatus === 'Closed') {
                replaceWithRecruitmentClosedPage();
                return false;
            }
        } catch (exception: any) {
            // if subscription of assessment owner is canceled => handle it
            if (exception && exception.status === 403 && exception.type === 'canceled_subscription') {
                replaceWithRecruitmentClosedPage();
                return false;
            }
        }
        return true;
    };

    const startTestAsync = async (assessmentId: string, testId: string) => {
        const settings = getSettingsByAssessmentId(assessmentId);
        if (settings) {
            await testClient.startTestAsync(assessmentId, testId, settings);
        } else {
            setActiveAssessmentId('');
            replaceWithErrorPage(true);
        }
    };

    const safeStartTestInnerAsync = async (testId: string) => {
        try {
            await startTestAsync(id, testId);

            navigateToTestPage(id, testId);
        } catch (error: any) {
            // TODO: write proper handling for different errors
            replaceWithErrorPage(true);
        }
    };

    const safeStartTestAsync = async (testId: string) => {
        setActiveAssessmentId(id);
        const unfinishedTest = await findUnfinishedTestAsync(id);

        if (unfinishedTest) {
            setCompleteTestDialogId(id);
        } else if (await isAssessmentOpenAsync()) {
            await safeStartTestInnerAsync(testId);
        }
    };

    const startTestHandlerAsync = async (activeTest: TestInfo | undefined) => {
        if (activeTest?.allocatedTimeMinutes) {
            setTimerWarningDialogId(id);
        } else {
            setActiveAssessmentId(id);
            await safeStartTestAsync(activeTest?.testId ?? '');
        }
    };

    return {
        safeStartTestAsync,
        startTestHandlerAsync,
    };
}

export default useTestActions;
