export interface ExceptionEventLog {
    code: number;
    message: string;
    name: string;
    type: string;
}

export function mapToExceptionEventLog(event: any, type: string): ExceptionEventLog {
    return {
        code: event?.code,
        message: event?.message,
        name: event?.name,
        type,
    };
}
