import React from 'react';
import { useTheme } from '@mui/material';
import { Box } from '@talentmesh/core';
import PdfSmallSVG from './PdfSmallIcon.svg';

function PdfSmallIcon(): JSX.Element {
    const theme = useTheme();
    return (
        <Box
            sx={{
                width: theme.spacing(7),
                height: theme.spacing(9),
            }}
        >
            <object
                width="100%"
                height="100%"
                type="image/svg+xml"
                data={PdfSmallSVG}
                aria-label="Pdf image"
                data-cy="PdfSmallIcon"
            />
        </Box>
    );
}

export default PdfSmallIcon;
