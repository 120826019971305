import { useTheme } from '@mui/material/styles';
import React from 'react';
import { ContainerBackground, BrandedCardContainer, Stack, Typography, Link, Button, Box } from '@talentmesh/core';
import { siteUrls } from '../../../AppSettings';
import useNavigation from '../../../Hooks/useNavigation';
import useTMTranslation from '../../../Hooks/useTMTranslation';

interface ErrorPageProps {
    errorTitle?: string;
    errorDescription?: string;
    showBackButton?: boolean;
}

const ErrorDialog = ({ errorTitle, errorDescription, showBackButton = false }: ErrorPageProps) => {
    const theme = useTheme();
    const { replaceWithJobApplicationsPage } = useNavigation();
    const { tCommon: t } = useTMTranslation();

    const fallbackErrorTitle = errorTitle ?? t('OopsError');
    const fallbackErrorDescription = errorDescription ?? t('SomethingWentWrong');

    const handleClick = () => {
        replaceWithJobApplicationsPage();
    };

    return (
        <ContainerBackground>
            <Stack
                spacing={theme.spacing(3)}
                alignSelf={{ xs: 'start', sm: 'start', md: 'center' }}
                margin={{ xs: theme.spacing(2), sm: theme.spacing(2), md: 0 }}
            >
                <BrandedCardContainer header={fallbackErrorTitle}>
                    <Typography variant="body1" color={theme.palette.text.primary}>
                        {fallbackErrorDescription}
                    </Typography>
                </BrandedCardContainer>
                <Typography sx={{ textAlign: 'center', color: theme.palette.text.primary }}>
                    {`${t('StillHavingIssues')} `}
                    <Link
                        variant="body1"
                        href={siteUrls.contactSupportUrl}
                        mainColor={theme.palette.text.highlight}
                        highlightColor={theme.palette.text.highlight}
                    >
                        {t('ContactUsCapitalized')}
                    </Link>
                    {` ${t('ForAssistance')}`}
                </Typography>
                <Box width="100%" display="flex" justifyContent="center">
                    {showBackButton && (
                        <Button
                            variant="contained"
                            onClick={handleClick}
                            sx={{
                                width: 'fit-content',
                            }}
                        >
                            {t('BackToJobApplications')}
                        </Button>
                    )}
                </Box>
            </Stack>
        </ContainerBackground>
    );
};

export default ErrorDialog;
