import { AutocompleteOption, emptyAutocompleteOption } from '@talentmesh/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { InformationAnswer } from '../../../../Models/AdditionalInfo';
import ApplicantInformationType from '../../../../Models/ApplicantInformationType';
import { ApplicantProfile, DefaultApplicantProfile } from '../../../../Models/ApplicantProfile';
import { EducationField } from '../../../../Models/EducationField';
import { EducationLevel, HighestLevelEducations } from '../../../../Models/EducationLevel';
import { JobExperience } from '../../../../Models/JobExperience';
import { LocationDetails } from '../../../../Models/LocationDetails';
import { informationFromFormValues } from '../../../../Utils/informationFromFormValues';
import { JobExperienceDTO } from '../../../../DTO/Users/JobExperienceDTO';
import { EducationFieldDTO } from '../../../../DTO/Users/EducationFieldDTO';
import { TMTranslationGetter } from '../../../../Hooks/useTMTranslation';
import { CommonResourceKey } from '../../../../i18n/byNamespace/i18nCommonResourceKeys';
import { ApplicantProfileDTO } from '../../../../DTO/Assessments/ApplicantProfileDTO';
import autoMapping from '../../../../Utils/autoMapping';
import EducationLevelOptionsGetter from '../../../../Components/ProfileFields/Models/EducationLevelOptions';
import { DefaultEducation, Education } from '../../../../Models/Education';
import { DefaultExperience, Experience } from '../../../../Models/Experience';

dayjs.extend(utc);

export type ExperienceFormValues = {
    title: string;
    jobExperience: AutocompleteOption | string;
    company: string;
    workingHere: boolean;
    startDate: Date | null;
    endDate: Date | null;
};

export const DefaultExperienceFormValues: ExperienceFormValues = {
    title: '',
    jobExperience: emptyAutocompleteOption,
    company: '',
    workingHere: false,
    startDate: null,
    endDate: null,
};

export type EducationFormValues = {
    schoolName: string;
    educationLevel: AutocompleteOption | string;
    educationField: AutocompleteOption | string;
    startDate: Date | null;
    endDate: Date | null;
};

export const DefaultEducationFormValues: EducationFormValues = {
    educationLevel: emptyAutocompleteOption,
    educationField: emptyAutocompleteOption,
    endDate: null,
    schoolName: '',
    startDate: null,
};

export interface ApplicantProfileFormValues {
    // profile information
    firstName: string;
    lastName: string;
    email: string;
    location: LocationDetails | null;
    isWillingToRelocate: boolean;
    experiences: ExperienceFormValues[];
    educations: EducationFormValues[];
    cvFile: string;
}

export const DefaultApplicantOnboardFormValues: ApplicantProfileFormValues = {
    // profile information
    firstName: '',
    lastName: '',
    email: '',
    location: null,
    isWillingToRelocate: false,
    experiences: [],
    educations: [],
    cvFile: '',
};

export function mapApplicantProfileDTO2Model(dto: ApplicantProfileDTO): ApplicantProfile {
    const model = autoMapping(dto, DefaultApplicantProfile);

    if (model.firstName == null) {
        model.firstName = '';
    }

    if (model.lastName == null) {
        model.lastName = '';
    }

    if (model.isWillingToRelocate == null) {
        model.isWillingToRelocate = false;
    }
    const { experiences, educations } = dto;

    model.experiences = experiences.map((x) => autoMapping(x, DefaultExperience));
    model.educations = educations.map((x) => autoMapping(x, DefaultEducation));

    return model;
}

const mapOptionToEducationLevel = (option: AutocompleteOption): EducationLevel => {
    const level = (option?.id as HighestLevelEducations) || 'UserDefined';
    const userDefined = level === 'UserDefined' ? option.label : undefined;

    return {
        level,
        userDefined,
    };
};

const mapOptionToEducationField = (option: AutocompleteOption): EducationField => {
    const id = Number(option?.id);
    // We have to preserve the label to support back and forth navigation between steps
    const userDefined = option.label;
    return {
        id,
        userDefined,
    };
};

const mapOptionToJobExperience = (option: AutocompleteOption): JobExperience => {
    const id = Number(option?.id);
    // We have to preserve the label to support back and forth navigation between steps
    const userDefined = option.label;
    return {
        id,
        userDefined,
    };
};

export function profileFromFormValues(form: ApplicantProfileFormValues): ApplicantProfile {
    const result = autoMapping(form, DefaultApplicantProfile);
    const { experiences, educations } = form;

    result.experiences = experiences.map((x) => {
        const experience = autoMapping(x, DefaultExperience);
        experience.jobExperience = mapOptionToJobExperience(x.jobExperience as AutocompleteOption);
        return experience;
    });

    result.educations = educations.map((x) => {
        const education = autoMapping(x, DefaultEducation);
        education.educationLevel = mapOptionToEducationLevel(x.educationLevel as AutocompleteOption);
        education.educationField = mapOptionToEducationField(x.educationField as AutocompleteOption);
        return education;
    });

    return result;
}

const mapEducationLevelToOption = (
    educationLevel: EducationLevel,
    t: TMTranslationGetter<CommonResourceKey>,
): AutocompleteOption | string => {
    let option: AutocompleteOption = { ...emptyAutocompleteOption };

    const { level, userDefined } = educationLevel;
    if (level != null || userDefined != null) {
        const levelLabel =
            level === 'UserDefined' ? userDefined : EducationLevelOptionsGetter(t).find((x) => x.id === level)?.label;

        option = {
            id: level,
            label: levelLabel || '',
        };
    }

    return option;
};

const mapEducationFieldToOption = (educationField: EducationField): AutocompleteOption | string => {
    let option: AutocompleteOption = { ...emptyAutocompleteOption };

    const { id, userDefined } = educationField;
    if (id != null || userDefined != null) {
        option = {
            id: id?.toString(),
            label: userDefined || '',
        };
    }

    return option;
};

const mapJobExperienceToOption = (jobExperience: JobExperience): AutocompleteOption | string => {
    let option: AutocompleteOption = { ...emptyAutocompleteOption };

    const { id, userDefined } = jobExperience;
    if (id != null || userDefined != null) {
        option = {
            id: id?.toString(),
            label: userDefined || '',
        };
    }

    return option;
};

export function fromExperiencesToExperiencesFormValues(experiences: Experience[]): ExperienceFormValues[] {
    return experiences.map((x) => {
        const experience = autoMapping(x, DefaultExperienceFormValues);
        experience.startDate = dayjs.utc(experience.startDate) as unknown as Date;
        experience.endDate = experience.endDate ? (dayjs.utc(experience.endDate) as unknown as Date) : null;
        experience.jobExperience = mapJobExperienceToOption(x.jobExperience);
        return experience;
    });
}

export function fromEducationsToEducationsFormValues(
    educations: Education[],
    t: TMTranslationGetter<CommonResourceKey>,
): EducationFormValues[] {
    return educations.map((x) => {
        const education = autoMapping(x, DefaultEducationFormValues);
        education.startDate = dayjs.utc(education.startDate) as unknown as Date;
        education.endDate = dayjs.utc(education.endDate) as unknown as Date;
        education.educationLevel = mapEducationLevelToOption(x.educationLevel, t);
        education.educationField = mapEducationFieldToOption(x.educationField);
        return education;
    });
}

export function formValuesFromProfile(
    profile: ApplicantProfile,
    defaultFormValues: ApplicantProfileFormValues,
    t: TMTranslationGetter<CommonResourceKey>,
): ApplicantProfileFormValues {
    const result = autoMapping(profile, defaultFormValues);

    const { experiences, educations } = profile;

    result.experiences = fromExperiencesToExperiencesFormValues(experiences);

    result.educations = fromEducationsToEducationsFormValues(educations, t);

    return result;
}

export const profileFromApplicantOnboardFormValues = (form: ApplicantProfileFormValues): ApplicantProfile => {
    return profileFromFormValues(form);
};

export const applicantOnboardFormValuesFromCandidate = (
    profile: ApplicantProfile,
    t: TMTranslationGetter<CommonResourceKey>,
): ApplicantProfileFormValues => {
    return formValuesFromProfile(profile, DefaultApplicantOnboardFormValues, t);
};

export const informationFromApplicantOnboardFormValues = (
    form: ApplicantProfileFormValues,
    applicantInformation: ApplicantInformationType[],
): Array<InformationAnswer<ApplicantInformationType>> => {
    return informationFromFormValues(form, applicantInformation);
};

export const mapExperienceToDTO = (experience: JobExperience): JobExperienceDTO => {
    const userDefined = experience.id === 0 ? experience.userDefined : undefined;
    return {
        id: experience.id,
        userDefined,
    };
};

export const mapEducationFieldToDTO = (education: EducationField): EducationFieldDTO => {
    const userDefined = education.id === 0 ? education.userDefined : undefined;
    return {
        id: education.id,
        userDefined,
    };
};
