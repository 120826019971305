import { useTheme } from '@mui/material/styles';
import { Box, MenuButton, MenuItemWithValueProps, Stack, TMTopBar } from '@talentmesh/core';
import { LogoutOutlined } from '@talentmesh/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import LanguageMenu from './LanguageMenu';
import { useUserContext } from '../Context/UserContext';
import RoutePath from '../Routing/RoutePath';
import useTMTranslation from '../Hooks/useTMTranslation';

const TopBar = () => {
    const theme = useTheme();
    const { user, logOut } = useUserContext();
    const { tCommon: t } = useTMTranslation();

    const logoutHandler = () => {
        logOut();
    };

    const menuButtonItems: MenuItemWithValueProps[] = [
        { menuItemLabel: t('LogOut'), onClick: logoutHandler, icon: <LogoutOutlined color="secondary" /> },
    ];

    const userInitials = user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : '';

    return (
        <TMTopBar
            position="relative"
            logoClickURL={RoutePath.Home}
            logoLinkComponent={Link}
            sx={{ 'div:first-of-type': { backgroundColor: theme.palette.table.main } }}
        >
            <Box sx={{ flexGrow: 1 }} />
            <Stack spacing={1.25} direction="row" sx={{ marginRight: theme.spacing(7) }}>
                <LanguageMenu />
                <MenuButton label={userInitials} menuItems={menuButtonItems} />
            </Stack>
        </TMTopBar>
    );
};

export default TopBar;
