import { Auth0ProviderOptions } from '@auth0/auth0-react';
import { CacheLocation } from '@auth0/auth0-spa-js';

export const assessmentsServerURL = process.env.REACT_APP_API_SERVER;
export const assessmentsApiUrl = `${assessmentsServerURL}/api/`;
export const resourceBaseUrl = process.env.REACT_APP_RESOURCE_BASE_URL;

export const tokenStorage = (process.env.REACT_APP_TOKEN_STORAGE as CacheLocation) || 'memory';

export const authProviderSettings: Auth0ProviderOptions = {
    domain: process.env.REACT_APP_AUTH_DOMAIN ?? '',
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID ?? '',
    redirectUri: window.location.origin,
    audience: process.env.REACT_APP_AUTH_AUDIENCE ?? '',
    connection_hint: 'External-Accounts',
};

export const ApplicationInsightsSettings = {
    connectionString: process.env.REACT_APP_AI_CONNECTION_STRING ?? '',
    cloudRole: 'Candidate App',
};

export const SnapshotsTimeouts = {
    minDelayMinutes: 1,
    maxDelayMinutes: 3,
};

export const siteUrls = {
    termsCandidateUrl:
        process.env.REACT_APP_TERMS_CANDIDATE ?? 'https://www.talentmesh-st.com/terms-and-conditions/candidate',
    privacyPolicyUrl: process.env.REACT_APP_PRIVACY ?? 'https://talentmesh.com/privacy',
    contactSupportUrl: process.env.REACT_APP_CONTACT ?? 'https://talentmesh.com/contact',
    homePageUrl: process.env.REACT_APP_HOME ?? 'https://talentmesh.com',
};
