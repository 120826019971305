import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { PersonalityTestTopBar } from './Components/PersonalityTestTopBar';
import PersonalityQuestionsList from './Components/PersonalityQuestionsList';
import { FullPage } from '../../../Components/Pages/Page';
import { useTestContext } from '../Contexts/TestContext';
import { StepperPersonalityContext } from './Contexts/StepperPersonalityContext';
import useStepperPersonalityContextValue from './Contexts/UseStepperPersonalityContextValue';

function PersonalityTestPage(): JSX.Element {
    const theme = useTheme();

    const { test } = useTestContext();
    const stepperPersonalityContextValue = useStepperPersonalityContextValue();
    const { initialize } = stepperPersonalityContextValue;

    useEffect(() => {
        initialize(test.questions.length, test.lastAnsweredQuestionIndex);
    }, [test]);

    const topBarRef = useRef<HTMLDivElement>(null);

    function getMarginTop(defaultValue: string): string {
        const topBar = topBarRef.current;
        return topBar ? `${topBar.clientHeight}` : defaultValue;
    }

    return (
        <StepperPersonalityContext.Provider value={stepperPersonalityContextValue}>
            <FullPage topBar={<PersonalityTestTopBar ref={topBarRef} />}>
                <div
                    style={{
                        marginTop: getMarginTop(theme.spacing(15)),
                        marginBottom: theme.spacing(1),
                    }}
                >
                    <PersonalityQuestionsList />
                </div>
            </FullPage>
        </StepperPersonalityContext.Provider>
    );
}

export default PersonalityTestPage;
