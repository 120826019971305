import { useTheme } from '@mui/material';
import React from 'react';
import { Stack, StatusChip, Typography } from '@talentmesh/core';
import WarningLabel from '../../../Components/WarningLabel';
import useTMTranslation from '../../../Hooks/useTMTranslation';

function CTMonitoringWarningDialogBody(): JSX.Element {
    const theme = useTheme();
    const { tTest } = useTMTranslation();

    return (
        <Stack
            direction="column"
            spacing={theme.spacing(2)}
            sx={{
                padding: theme.spacing(2),
                borderColor: theme.palette.border.main,
                borderRadius: theme.shape.mediumBorderRadius,
                borderWidth: '1px',
                borderStyle: 'solid',
                margin: theme.spacing(2),
            }}
        >
            <StatusChip
                status="warning"
                label={<WarningLabel text={tTest('CTMonitoringFeaturesWarningLabel')} />}
                sx={{
                    display: 'flex',
                    width: theme.spacing(40),
                }}
            />
            <Typography variant="body1" color={theme.palette.text.primary}>
                {tTest('CTMonitoringFeaturesWarning')}
            </Typography>
        </Stack>
    );
}

export default CTMonitoringWarningDialogBody;
