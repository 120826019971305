import React from 'react';
import { Box } from '@talentmesh/core';
import DialogTypography from '../../../../../../Components/Dialogs/DialogTypography';
import useTMTranslation from '../../../../../../Hooks/useTMTranslation';

interface DialogBodyTextProps {
    timeLeft: string;
    unansweredQuestionsCount: number;
}

function DialogBodyText({ timeLeft, unansweredQuestionsCount }: DialogBodyTextProps): JSX.Element {
    const { tTest } = useTMTranslation();
    const questionsLeftWarning =
        unansweredQuestionsCount === 1 ? `${tTest('QuestionLeftWarning3')} ` : `${tTest('QuestionsLeftWarning3')} `;

    return (
        <Box>
            <DialogTypography text={`${tTest('QuestionsLeftWarning1')} `} />
            <DialogTypography text={`${timeLeft} `} bold />
            <DialogTypography text={`${tTest('QuestionsLeftWarning2')} `} />
            <DialogTypography text={`${unansweredQuestionsCount.toString()} `} bold />
            <DialogTypography text={questionsLeftWarning} />
            <DialogTypography text={tTest('QuestionsLeftWarning4')} italic />
        </Box>
    );
}

export default DialogBodyText;
