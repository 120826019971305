import { useTheme } from '@mui/material';
import React from 'react';
import { Stack } from '@talentmesh/core';
import { RHFAutocompleteLocation, RHFCheckbox } from '@talentmesh/forms';
import ProfileSettingsFieldsNames from '../Components/ProfileSettingsFieldsNames';
import useTMTranslation from '../../../../Hooks/useTMTranslation';

const LocationField: React.FC = () => {
    const theme = useTheme();
    const { tCommon: t } = useTMTranslation();

    if (
        (window as any).google === undefined ||
        (window as any).google.maps === undefined ||
        (window as any).google.maps.places === undefined
    ) {
        return null;
    }

    return (
        <Stack spacing={theme.spacing(1.25)} color={theme.palette.text.menuTitle}>
            <RHFAutocompleteLocation
                name={ProfileSettingsFieldsNames.Location}
                label={`${t('Location')}*`}
                noOptionsText={t('NoLocations')}
            />
            <RHFCheckbox
                label={t('WillingToRelocate')}
                name={ProfileSettingsFieldsNames.IsWillingToRelocate}
                data-cy={ProfileSettingsFieldsNames.IsWillingToRelocate}
                variant="h6"
            />
        </Stack>
    );
};

export default LocationField;
