import React from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import AssessmentExecutionContextProvider from './Context/AssessmentExecutionContextProvider';
import AssessmentWelcomePage from './Pages/AssessmentWelcomePage/AssessmentWelcomePage';
import CandidateOnboardPage from './Pages/CandidateOnboard/CandidateOnboardPage';
import CandidateTestMonitoringPage from './Pages/CandidateTestMonitoringPage/CandidateTestMonitoringPage';
import JobApplicationPage from './Pages/MyAssessment/JobApplication/JobApplicationPage';
import TestPage from './Pages/Tests/TestPage';
import ProtectedRoute from './Routing/ProtectedRoute';
import RoutePath from './Routing/RoutePath';

export const assessmentExecutionRouter: RouteObject[] = [
    {
        element: <ProtectedRoute />,
        children: [
            {
                index: true,
                element: <JobApplicationPage />,
            },

            {
                path: RoutePath.AssessmentOnboard,
                element: <CandidateOnboardPage />,
            },

            {
                path: RoutePath.AssessmentWelcome,
                element: <AssessmentWelcomePage />,
            },

            {
                path: RoutePath.AssessmentCTMonitoringPage,
                element: <CandidateTestMonitoringPage />,
            },

            {
                path: RoutePath.AssessmentTest,
                element: <TestPage />,
            },
        ],
    },
];

function AssessmentsExecution() {
    return (
        <AssessmentExecutionContextProvider>
            <Outlet />
        </AssessmentExecutionContextProvider>
    );
}

export default AssessmentsExecution;
