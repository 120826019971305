import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@talentmesh/core';
import { Form, Formik } from 'formik';
import AcceptRulesPanel from './AcceptRulesPanel';
import CTMonitoringTogglesPanel from './CTMonitoringTogglesPanel';
import CTMonitoringProgressPanel from './CTMonitoringProgressPanel';
import CTTermsAndPrivacyPolicyDisclaimer from './CTTermsAndPrivacyPolicyDisclaimer';
import VideoPreviewPanel from './VideoPreviewPanel';
import { CandidateTestMonitoring, DefaultCTMonitoring } from '../../../Models/CandidateTestMonitoringSettings';
import { useCandidateTestMonitoringContext } from '../../../Context/CandidateTestMonitoringContext';
import useAssessmentId from '../../../Hooks/useAssessmentId';
import useTestActions from '../../MyAssessment/JobApplication/components/JobApplicationCard/components/TestAction/useTestActions';
import CTMonitoringDialogs from '../CTMonitoringDialogs/CTMonitoringDialogs';
import { useDialogsContext } from '../../../Context/DialogsContext';
import useCTMonitoringPageRouteState from './useCTMonitoringPageRouteState';

export interface CTMonitoringPanelFormValues extends CandidateTestMonitoring {
    termsAccepted: boolean;
}

function CTMonitoringTogglesForm(): JSX.Element {
    const theme = useTheme();
    const { updateSettingsMap } = useCandidateTestMonitoringContext();
    const id = useAssessmentId();
    const { startTestHandlerAsync } = useTestActions({
        id,
    });
    const { activeTest } = useCTMonitoringPageRouteState();
    const { setEnableCTMonitoringDialogId } = useDialogsContext();

    const submitSettingsHandler = async (formValues: CTMonitoringPanelFormValues) => {
        updateSettingsMap(id, formValues as CandidateTestMonitoring);
        if (!(formValues.isFullScreenEnabled && formValues.isScreenInvigilationEnabled && formValues.isWebcamEnabled)) {
            setEnableCTMonitoringDialogId(id);
        } else {
            startTestHandlerAsync(activeTest);
        }
    };

    return (
        <>
            <Formik initialValues={{ ...DefaultCTMonitoring, termsAccepted: false }} onSubmit={submitSettingsHandler}>
                <Form>
                    <Stack alignItems="center" spacing={2.5}>
                        <Stack
                            spacing={3.75}
                            sx={{
                                border: `1px solid ${theme.palette.border.main}`,
                                borderRadius: theme.shape.mediumBorderRadius,
                                backgroundColor: 'white',
                                padding: theme.spacing(2.5),
                                width: '100%',
                            }}
                        >
                            <CTMonitoringTogglesPanel />
                            <CTMonitoringProgressPanel />
                            <VideoPreviewPanel />
                        </Stack>
                        <CTTermsAndPrivacyPolicyDisclaimer />
                        <AcceptRulesPanel />
                    </Stack>
                </Form>
            </Formik>
            <CTMonitoringDialogs id={id} activeTest={activeTest} />
        </>
    );
}

export default CTMonitoringTogglesForm;
