import { Theme } from '@mui/material/styles';
import { TestInfo, TestStates } from '../../../../../../../Models/TestInfo';

export function getBorderByTestState(theme: Theme, state?: TestStates): string {
    switch (state) {
        case TestStates.Active:
            return `1px solid ${theme.palette.primary.main}`;
        case TestStates.Completed:
            return `1px solid ${theme.palette.success.main}`;
        case TestStates.Inactive:
            return `1px solid ${theme.palette.border.main}`;
        default:
            return `1px solid ${theme.palette.grey[400]}`;
    }
}

export function getPreviousTestInfoTestState(state: TestStates): TestStates {
    switch (state) {
        case TestStates.Active:
            return TestStates.Inactive;
        case TestStates.Completed:
            return TestStates.Active;
        case TestStates.Inactive:
        default:
            return state;
    }
}

export function getLastCompletedTestIndex(tests: Array<TestInfo>): number {
    let lastCompletedIndex: number = -1;

    for (let index: number = 0; index < tests.length; index++) {
        if (tests[index].testState === TestStates.Completed) {
            lastCompletedIndex = index;
        }
    }

    return lastCompletedIndex;
}
