import { useTheme } from '@mui/material/styles';
import { Paper, PaperProps } from '@talentmesh/core';
import React from 'react';

interface RowPaperProps extends PaperProps {
    isDisabled: boolean;
}

function RowPaper({ isDisabled, ...props }: RowPaperProps): JSX.Element {
    const theme = useTheme();

    return (
        <Paper
            {...props}
            square
            elevation={0}
            sx={{
                paddingTop: 8,
                paddingBottom: 8,
                paddingLeft: 0,
                paddingRight: 0,
                backgroundColor: isDisabled ? theme.palette.table.main : theme.palette.common.white,
            }}
        />
    );
}

export default RowPaper;
