import { createContext, useContext } from 'react';

export interface StepperPersonalityContextState {
    percentage: number;

    activeIndex: number;
    completedIndex: number;

    activeStep: number;
    completedStep: number;
}

export const maxSteps = 12;

export const DefaultStepperPersonalityContextState: StepperPersonalityContextState = {
    percentage: 0,

    activeIndex: 0,
    completedIndex: -1,

    activeStep: 0,
    completedStep: -1,
};

export interface StepperPersonalityContextData {
    count: number;
    steps: number[];

    contextState: StepperPersonalityContextState;
    initialize: (count: number, completedIndex: number) => void;

    handleNext: () => void;
    handleBack: () => void;
    handleComplete: () => void;
}

export const StepperPersonalityContext = createContext<StepperPersonalityContextData | undefined>(undefined);

export function useStepperPersonalityContext(): StepperPersonalityContextData {
    const stepperContext = useContext(StepperPersonalityContext);
    if (!stepperContext) {
        throw new Error('useStepperPersonalityContext must be used within the StepperPersonalityContext.Provider');
    }
    return stepperContext;
}
