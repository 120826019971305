import { useAuth0 } from '@auth0/auth0-react';
import SettingsClient from '../Clients/SettingsClient';
import SnapshotsClient from '../Clients/SnapshotsClient';
import AssessmentClient from '../Clients/AssessmentClient';
import AnswersClient from '../Clients/AnswersClient';
import QuestionsConfigurationClient from '../Clients/QuestionsConfigurationClient';
import TestClient from '../Clients/TestClient';
import UsersClient from '../Clients/UsersClient';
import QuestionStatsClient from '../Clients/QuestionStatsClient';
import TestMonitoringClient from '../Clients/TestMonitoringClient';
import OnBoardingClient from '../Clients/OnBoardingClient';
import BlobClient from '../Clients/BlobClient';
import ApplicantAssessmentsClient from '../Clients/ApplicantAssessmentsClient';
import AiClient from '../Clients/AiClient';

const useAssessmentClient = (): AssessmentClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new AssessmentClient(getAccessTokenSilently);
};

const useQuestionConfigurationClient = (): QuestionsConfigurationClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new QuestionsConfigurationClient(getAccessTokenSilently);
};

const useTestsClient = (): TestClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new TestClient(getAccessTokenSilently);
};

const useUsersClient = (): UsersClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new UsersClient(getAccessTokenSilently);
};

const useSnapshotsClient = (): SnapshotsClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new SnapshotsClient(getAccessTokenSilently);
};

const useAnswersClient = (): AnswersClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new AnswersClient(getAccessTokenSilently);
};

const useStatsClient = (): QuestionStatsClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new QuestionStatsClient(getAccessTokenSilently);
};

const useTestMonitoringClient = (): TestMonitoringClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new TestMonitoringClient(getAccessTokenSilently);
};

const useOnBoardingClient = (): OnBoardingClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new OnBoardingClient(getAccessTokenSilently);
};

const useBlobClient = (): BlobClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new BlobClient(getAccessTokenSilently);
};

const useSettingsClient = (): SettingsClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new SettingsClient(getAccessTokenSilently);
};

const useApplicantAssessmentsClient = (): ApplicantAssessmentsClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new ApplicantAssessmentsClient(getAccessTokenSilently);
};

const useAiClient = (): AiClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new AiClient(getAccessTokenSilently);
};

export {
    useBlobClient,
    useAssessmentClient,
    useQuestionConfigurationClient,
    useTestsClient,
    useUsersClient,
    useSnapshotsClient,
    useAnswersClient,
    useStatsClient,
    useTestMonitoringClient,
    useOnBoardingClient,
    useSettingsClient,
    useApplicantAssessmentsClient,
    useAiClient,
};
