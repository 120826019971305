import React from 'react';
import useTMTranslation from '../../Hooks/useTMTranslation';
import ErrorDialog from './Components/ErrorDialog';
import ErrorQueryNames from './ErrorQueryNames';

function getFromQuery(queryParameterName: string) {
    return new URLSearchParams(window.location.search).get(queryParameterName);
}

function ErrorPage(): JSX.Element {
    const titleFromQuery = getFromQuery(ErrorQueryNames.ErrorTitle);
    const descriptionFromQuery = getFromQuery(ErrorQueryNames.ErrorDescription);
    const showBackButton = getFromQuery(ErrorQueryNames.ShowBackButton) === 'true';
    const { tCommon: t } = useTMTranslation();

    const title = titleFromQuery || t('OopsError');
    const description = descriptionFromQuery || t('SomethingWentWrong');

    return <ErrorDialog errorTitle={title} errorDescription={description} showBackButton={showBackButton} />;
}

export default ErrorPage;
