import React from 'react';
import { Label, Stack } from '@talentmesh/core';
import { FormikAutocompleteDropdown } from '@talentmesh/forms';
import { RecruitmentInformationSalaryExpectation } from '../../../RecruitmentInformationUtils';
import currencies from '../../../../../../../Utils/Currency/currencies';
import useTMTranslation from '../../../../../../../Hooks/useTMTranslation';

function CurrencyAutocomplete(): JSX.Element {
    const { tCommon } = useTMTranslation();

    return (
        <Stack flex={1}>
            <Label>{tCommon('Currency')}</Label>
            <FormikAutocompleteDropdown
                name={RecruitmentInformationSalaryExpectation.SalaryExpectationCurrency}
                data-cy={RecruitmentInformationSalaryExpectation.SalaryExpectationCurrency}
                autocompleteOptions={currencies}
                disableClearable
                freeSolo={false}
                disableErrorMessage
            />
        </Stack>
    );
}

export default CurrencyAutocomplete;
