import QuestionType from '../../Models/QuestionType';

export interface SaveAnswerDTO {
    assessmentId: string;
    testId: string;
    questionId: string;
    questionType: QuestionType;
    answer: CandidateAnswerDTO;
    startedAt: string;
    endedAt: string;
}

export interface CandidateAnswerDTO {
    answerId: string;
    answerText: string;
}

export const DefaultCandidateAnswerDTO: CandidateAnswerDTO = {
    answerId: '',
    answerText: '',
};
