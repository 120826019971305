import { useRef, useState } from 'react';
import { useLocalizationContext } from '../../../Context/LocalizationContext';
import { CandidateAnswerDTO, DefaultCandidateAnswerDTO } from '../../../DTO/Questions/SaveAnswerDTO';
import { useAnswersClient, useStatsClient, useTestsClient } from '../../../Hooks/ClientHooks';
import useNavigation from '../../../Hooks/useNavigation';
import { LikertAnswerGroupData } from '../../../Models/AssessmentData';
import ProcessingState from '../../../Models/ProcessingState';
import QuestionType from '../../../Models/QuestionType';
import { DefaultTestState, TestState } from '../../../Models/TestState';
import DefaultLikertAnswerGroupData from '../Models/DefaultLikertAnswerGroupData';
import { TestContextData } from './TestContext';

function useTestContextValue(): TestContextData {
    const [assessmentId, setAssessmentId] = useState('');
    const [test, setTest] = useState<TestState>(DefaultTestState);
    const [likert, setLikert] = useState<LikertAnswerGroupData>(DefaultLikertAnswerGroupData);
    const [likertGroups, setLikertGroups] = useState<LikertAnswerGroupData[]>([]);
    const [elapsedTime, setElapsedTime] = useState<string>('');
    const { navigateToJobApplicationsPage } = useNavigation();
    const testClient = useTestsClient();
    const { switchToAppLanguage } = useLocalizationContext();

    const [processingState, setProcessingState] = useState<ProcessingState>(ProcessingState.Success);

    const startedAt = useRef(new Date().toJSON());

    const resetStartTime = () => {
        const now = new Date();
        startedAt.current = now.toJSON();
    };

    const answersClient = useAnswersClient();
    const statsClient = useStatsClient();

    const saveAnswerAsync = async (questionId: string, questionType: QuestionType, answer: string): Promise<void> => {
        setProcessingState(ProcessingState.Processing);

        let answerDto = DefaultCandidateAnswerDTO;

        const now = new Date();

        switch (questionType) {
            case QuestionType.Choice:
            case QuestionType.Likert:
                answerDto = {
                    answerId: answer,
                } as CandidateAnswerDTO;
                break;
            case QuestionType.Exact:
                answerDto = {
                    answerText: answer,
                } as CandidateAnswerDTO;
                break;
            default:
                throw new Error('Unknown question type');
        }

        await answersClient.saveAnswerAsync({
            assessmentId,
            testId: test.testId,
            questionId,
            questionType,
            answer: answerDto,
            startedAt: startedAt.current,
            endedAt: now.toJSON(),
        });

        setTest((prevState) => {
            prevState.candidateAnswers.set(questionId, answer);
            return prevState;
        });

        setProcessingState(ProcessingState.Success);
    };

    const saveStatsAsync = async (questionId: string, questionType: QuestionType): Promise<void> => {
        setProcessingState(ProcessingState.Processing);

        const now = new Date();

        await statsClient.saveStatsAsync({
            assessmentId,
            testId: test.testId,
            questionId,
            questionType,
            startedAt: startedAt.current,
            endedAt: now.toJSON(),
        });

        setProcessingState(ProcessingState.Success);

        resetStartTime();
    };

    const completeTestAsync = async () => {
        setProcessingState(ProcessingState.Processing);
        await testClient.completeTestAsync(assessmentId, test.testId);
        setProcessingState(ProcessingState.Success);
    };

    const unansweredQuestionsCount = () => {
        return test.questions.length - test.candidateAnswers.size;
    };

    const redirectAfterTestEnd = () => {
        switchToAppLanguage();
        navigateToJobApplicationsPage(assessmentId);
    };

    return {
        assessmentId,
        setAssessmentId,

        test,
        setTest,

        likert,
        setLikert,

        likertGroups,
        setLikertGroups,

        processingState,
        setProcessingState,

        elapsedTime,
        setElapsedTime,

        saveAnswerAsync,
        saveStatsAsync,
        unansweredQuestionsCount,
        completeTestAsync,
        redirectAfterTestEnd,

        resetStartTime,
    };
}

export default useTestContextValue;
