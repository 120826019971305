import React from 'react';
import { Stack } from '@talentmesh/core';
import RuleItem from './RuleItem';
import useTMTranslation, { TMTranslationGetter } from '../../../Hooks/useTMTranslation';
import { TestResourceKey } from '../../../i18n/byNamespace/i18nTestResourceKeys';
import BaseSection from './BaseSection';

interface Rule {
    id: number;
    header: string;
    body: string;
}

type RulesGetterType = (t: TMTranslationGetter<TestResourceKey>) => Array<Rule>;

const rulesGetter: RulesGetterType = (t) => [
    {
        id: 0,
        header: t('TakeAssessmentOnYourOwn'),
        body: t('NoOneShouldBeAccessingYou'),
    },
    {
        id: 1,
        header: t('RespondToQuestionsTruthfully'),
        body: t('DoNotOverthink'),
    },
    {
        id: 2,
        header: t('DontUseExternalSourcesForAnswers'),
        body: t('DontTryFindAnswers'),
    },
];

function RulesSection(): JSX.Element {
    const { tTest } = useTMTranslation();

    return (
        <BaseSection header={tTest('TestInstructions')}>
            <Stack direction="row" spacing={2.5}>
                {rulesGetter(tTest).map((item, index) => (
                    <RuleItem key={item.id} value={index + 1} header={item.header} body={item.body} />
                ))}
            </Stack>
        </BaseSection>
    );
}

export default RulesSection;
