import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Stack, CircularTimer } from '@talentmesh/core';

export interface CircularProgressWithLabelProps {
    elapsedTime: string;
    progress?: number;
}

export function CircularProgressWithLabel({ elapsedTime, progress }: CircularProgressWithLabelProps) {
    const theme = useTheme();
    return (
        <Stack direction="row" spacing={theme.spacing(1)} sx={{ minHeight: theme.spacing(3) }}>
            <CircularTimer elapsedTime={elapsedTime} progress={progress} />
        </Stack>
    );
}
