import React from 'react';
import { Stack } from '@talentmesh/core';
import JobApplicationList from './components/JobApplicationList/JobApplicationList';
import JobApplicationHeader from './components/JobApplicationHeader/JobApplicationHeader';
import LoadingContent from '../../../Components/LoadingContent';
import { useApplicantAssessmentInfoContext } from '../../../Context/ApplicantAssessmentInfoContext';

function JobApplicationPageContent() {
    const { initialTabLoading } = useApplicantAssessmentInfoContext();

    return (
        <Stack sx={{ width: '100%' }}>
            <JobApplicationHeader />
            {initialTabLoading ? <LoadingContent /> : <JobApplicationList />}
        </Stack>
    );
}

export default JobApplicationPageContent;
