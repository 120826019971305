import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
    PersonalityConnector,
    PersonalityStepLabel,
    PersonalityStepper,
    Stack,
    Step,
    Typography,
} from '@talentmesh/core';
import { useStepperPersonalityContext } from '../Contexts/StepperPersonalityContext';

function PersonalityBar(): JSX.Element {
    const theme = useTheme();
    const { contextState, steps } = useStepperPersonalityContext();
    const { percentage, activeStep, completedStep } = contextState;

    return (
        <Stack direction="row" justifyContent="center" alignItems="center">
            <PersonalityStepper activeStep={activeStep} connector={<PersonalityConnector />} sx={{ width: '100%' }}>
                {steps.map((step) => (
                    <Step key={step} completed={step <= completedStep}>
                        <PersonalityStepLabel active={step === activeStep} completed={step <= completedStep} />
                    </Step>
                ))}
            </PersonalityStepper>
            <Typography variant="h4" color={theme.palette.text.primary}>
                {percentage}%
            </Typography>
        </Stack>
    );
}

export default PersonalityBar;
