import { TestInfoDTO } from '../DTO/Tests/TestInfoDTO';
import { TestTypes } from './Configuration';

export enum TestStates {
    Active = 'Active',
    Inactive = 'Inactive',
    Completed = 'Completed',
}

export interface TestInfo {
    testId: string;
    name: string;
    description: string;
    totalQuestionsCount: number;
    estimatedTimeMinutes: number;
    allocatedTimeMinutes?: number;
    testState: TestStates;
    testType: TestTypes;
    startedAt?: Date;
    completedAt?: Date;
}

export const DefaultTestInfoModel: TestInfo = {
    testId: '',
    name: '',
    description: '',
    testState: TestStates.Inactive,
    allocatedTimeMinutes: 0,
    estimatedTimeMinutes: 0,
    totalQuestionsCount: 0,
    testType: TestTypes.None,
};

export function mapFromTestInfoDTO(dto: TestInfoDTO): TestInfo {
    return {
        testId: dto.id,
        name: dto.testType,
        description: '',
        testType: dto.testType,
        testState: dto.testState,
        startedAt: dto.startedAt,
        completedAt: dto.completedAt,
        allocatedTimeMinutes: dto.allocatedTimeMinutes,
        estimatedTimeMinutes: dto.estimatedTimeMinutes,
        totalQuestionsCount: dto.totalQuestionsCount,
    };
}
