import { CandidateTestMonitoring } from '../../Models/CandidateTestMonitoringSettings';

export interface CandidateTestMonitoringDTO {
    assessmentId: string;
    isWebcamEnabled: boolean;
    isFullScreenEnabled: boolean;
    isScreenInvigilationEnabled: boolean;
}

export function fromCandidateTestMonitoring(
    assessmentId: string,
    input: CandidateTestMonitoring,
): CandidateTestMonitoringDTO {
    return {
        assessmentId,
        isWebcamEnabled: input.isWebcamEnabled,
        isScreenInvigilationEnabled: input.isScreenInvigilationEnabled,
        isFullScreenEnabled: input.isFullScreenEnabled,
    };
}
