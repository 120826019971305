import React from 'react';
import { Stack, Typography } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';

interface TestInfoLabelProps {
    label: string;
    value: string;
}

function TestInfoLabel({ label, value }: TestInfoLabelProps): JSX.Element {
    const theme = useTheme();

    const formattedLabel = `${label}: `;

    return (
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Typography variant="body2" sx={{ color: theme.palette.secondary.main }}>
                {formattedLabel}
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.secondary.main }}>
                {value}
            </Typography>
        </Stack>
    );
}

export default TestInfoLabel;
