import { ExperienceFormValues } from '../../../MyAssessment/ProfileSettings/utils/ApplicantProfileUtils';
import { isValidDate, isEmptyAutocompleteOption } from '../utils';

function isExperienceEditRequired(form: ExperienceFormValues): boolean {
    return (
        form.title === '' ||
        form.company === '' ||
        isEmptyAutocompleteOption(form.jobExperience) ||
        !isValidDate(form.startDate) ||
        !isValidDate(form.endDate, form.workingHere) ||
        (form.workingHere && form.endDate !== null) ||
        (form.endDate !== null && form.startDate !== null && form.endDate < form.startDate)
    );
}

export default isExperienceEditRequired;
