import { fromCandidateTestMonitoring } from '../DTO/AntiCheat/CandidateTestMonitoringDTO';
import { CandidateTestMonitoring } from '../Models/CandidateTestMonitoringSettings';
import { post, toJson } from '../Utils/HTTP/HttpUtils';
import ClientBase from './ClientBase';

export default class TestMonitoringClient extends ClientBase {
    async updateSettingsAsync(assessmentId: string, testId: string, settings: CandidateTestMonitoring): Promise<void> {
        const dto = fromCandidateTestMonitoring(assessmentId, settings);
        await post({
            accessToken: await this.getAccessTokenSilently(),
            path: this.addHost(`monitoring/${testId}/settings`),
            ...toJson(dto),
        });
    }
}
