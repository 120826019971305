import { useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { Button, QuestionStepper, Stack, TestStep, TestStepButton } from '@talentmesh/core';
import { ChevronLeftIcon, ChevronRightIcon } from '@talentmesh/icons';
import { getFieldValue } from '@talentmesh/forms';
import { DefaultQuestion } from '../../../../Models/Question';
import { useTestContext } from '../../Contexts/TestContext';
import { StepStatuses, useStepperSkillsContext } from '../Contexts/StepperSkillsContext';

function SkillsBar(): JSX.Element {
    const { test, saveAnswerAsync, saveStatsAsync } = useTestContext();
    const { questions } = test;
    const { contextState, setActiveStep, handleShiftLeft, handleShiftRight } = useStepperSkillsContext();
    const formik = useFormikContext<string>();

    const { activeIndex, visibleSteps } = contextState;
    const activeStep = visibleSteps.findIndex((x) => x.index === activeIndex);

    const theme = useTheme();

    const answerFieldName = 'answer';

    const isAnswered = () => {
        return getFieldValue(formik, answerFieldName) !== '';
    };

    const answerQuestionAsync = async () => {
        const question = questions[activeIndex] || DefaultQuestion;
        if (isAnswered()) {
            const answer = getFieldValue(formik, answerFieldName);
            await saveAnswerAsync(question.id, question.questionType, answer);
        } else {
            await saveStatsAsync(question.id, question.questionType);
        }
    };

    const saveAnswerAndChangeQuestionHandlerAsync = async (index: number) => {
        await answerQuestionAsync();
        setActiveStep(index, isAnswered() ? StepStatuses.Answered : StepStatuses.Visited);
    };

    return (
        <Stack direction="row" spacing={theme.spacing(1)} justifyContent="center">
            <Button
                variant="text"
                startIcon={<ChevronLeftIcon />}
                color="secondary"
                onClick={() => handleShiftLeft()}
            />
            <QuestionStepper nonLinear activeStep={activeStep}>
                {visibleSteps.map((step) => (
                    <TestStep key={step.index} disabled={step.status === StepStatuses.None && step.index > activeIndex}>
                        <TestStepButton
                            completed={step.status === StepStatuses.Answered}
                            icon={step.index + 1}
                            onClick={() => saveAnswerAndChangeQuestionHandlerAsync(step.index)}
                        />
                    </TestStep>
                ))}
            </QuestionStepper>
            <Button
                variant="text"
                endIcon={<ChevronRightIcon />}
                color="secondary"
                onClick={() => handleShiftRight()}
            />
        </Stack>
    );
}

export default SkillsBar;
