import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useTheme } from '@mui/material/styles';
import { Grid, LoadingBackdrop } from '@talentmesh/core';
import TestQuestion from './Components/TestQuestion';
import SkillsTestTopBar, { SkillsTestTopBarHeight } from './Components/SkillsTestTopBar';
import { FullPage } from '../../../Components/Pages/Page';
import { useTestContext } from '../Contexts/TestContext';
import useStepperSkillsContextValue from './Contexts/UseStepperSkillsContextValue';
import { Step, StepperSkillsContext, StepStatuses } from './Contexts/StepperSkillsContext';
import { DefaultQuestion, Question } from '../../../Models/Question';
import { getPageSize } from './Components/SkillsPageUtils';
import ActionsPanel from './Components/ActionsPanel';
import GridColumn from './Components/GridColumn';
import SkillsBar from './Components/SkillsBar';
import ProcessingState from '../../../Models/ProcessingState';

function SkillsTestPage(): JSX.Element {
    const theme = useTheme();
    const { test, processingState, resetStartTime } = useTestContext();
    const { questions } = test;

    const stepperContextValue = useStepperSkillsContextValue();
    const { contextState, loadSteps } = stepperContextValue;
    const { activeIndex } = contextState;

    const [candidateAnswers, setCandidateAnswers] = useState<Map<string, string>>(new Map<string, string>());
    const [answer, setAnswer] = useState('');

    const [question, setQuestion] = useState<Question>(DefaultQuestion);

    useEffect(() => {
        setCandidateAnswers(test.candidateAnswers);
        const initialSteps = [];
        for (let i = 0; i < questions.length; i++) {
            const q = questions[i];
            const step: Step = {
                index: i,
                status: test.candidateAnswers.has(q.id) ? StepStatuses.Answered : StepStatuses.None,
            };

            initialSteps.push(step);

            if (step.status === StepStatuses.Answered && i > 0) {
                const prevStep = initialSteps[i - 1];
                if (prevStep.status === StepStatuses.None) {
                    prevStep.status = StepStatuses.Visited;
                }
            }
        }
        const size = getPageSize();
        loadSteps(initialSteps, Math.min(size, questions.length));
    }, [test]);

    useEffect(() => {
        const q = questions[activeIndex] || DefaultQuestion;
        setQuestion(q);

        const a = candidateAnswers.get(q.id) || '';
        setAnswer(a);

        resetStartTime();
    }, [activeIndex]);

    return (
        <StepperSkillsContext.Provider value={stepperContextValue}>
            <Formik
                initialValues={{ answer }}
                enableReinitialize
                validateOnBlur={false}
                onSubmit={() => {}}
                validate={(values) => {
                    setAnswer(values.answer);
                }}
            >
                <FullPage topBar={<SkillsTestTopBar />} topBarHeight={SkillsTestTopBarHeight}>
                    <LoadingBackdrop open={processingState === ProcessingState.Processing} />
                    <div
                        style={{
                            paddingTop: theme.spacing(1),
                            paddingBottom: theme.spacing(1),
                            paddingLeft: theme.spacing(3),
                            paddingRight: theme.spacing(3),
                            backgroundColor: theme.palette.statusChip.main,
                        }}
                    >
                        <SkillsBar />
                    </div>
                    {question && (
                        <Grid container sx={{ height: '100%' }}>
                            <GridColumn left>
                                <TestQuestion
                                    text={question.questionText}
                                    index={activeIndex + 1}
                                    total={test.questions.length}
                                    src={question.blobFileURI}
                                    fileMimeType={question.fileMimeType}
                                />
                            </GridColumn>
                            <GridColumn right>
                                <ActionsPanel question={question} />
                            </GridColumn>
                        </Grid>
                    )}
                </FullPage>
            </Formik>
        </StepperSkillsContext.Provider>
    );
}

export default SkillsTestPage;
