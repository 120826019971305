import React, { createContext, ReactNode, useState, useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AdditionalInfo } from '../Models/AdditionalInfo';
import { ApplicantOnBoardingInfo, DefaultApplicantOnBoardingInfo } from '../Models/ApplicantOnBoardingInfo';
import { useAiClient, useBlobClient, useOnBoardingClient } from '../Hooks/ClientHooks';
import { ApplicantProfile, DefaultApplicantProfile } from '../Models/ApplicantProfile';
import ProcessingState from '../Models/ProcessingState';
import useAssessmentId from '../Hooks/useAssessmentId';
import SalaryExpectation from '../Models/SalaryExpectation';
import { RecruitmentInformationFormValues } from '../Pages/CandidateOnboard/Steps/RecruitmentInformation/RecruitmentInformationUtils';
import useTMTranslation from '../Hooks/useTMTranslation';
import useNavigation from '../Hooks/useNavigation';
import { useUserContext } from './UserContext';
import {
    EducationFormValues,
    ExperienceFormValues,
    fromEducationsToEducationsFormValues,
    fromExperiencesToExperiencesFormValues,
} from '../Pages/MyAssessment/ProfileSettings/utils/ApplicantProfileUtils';
import { fromEducationParsedDTOsToEducationsFormValues } from '../DTO/Users/EducationDTO';
import { fromExperienceParsedDTOsToExperiencesFormValues } from '../DTO/Users/ExperienceDTO';

export interface OnBoardingContextInterface extends OnBoardingContextProviderState {
    onBoardApplicant: (additionalInfo: AdditionalInfo) => Promise<void>;
    onBoardingWrapper: (additionalInfo: AdditionalInfo) => Promise<void>;
    setApplicantProfile: (value: ApplicantProfile) => void;
    uploadAndParseCv: (file: File) => Promise<void>;
    deleteCv: () => Promise<void>;
    setRecruitmentInformation: (values: RecruitmentInformationFormValues) => void;
    setCvParsingState: (state: ProcessingState) => void;
    parseCvAsync: (bUrl: string | undefined) => Promise<void>;
}

export const OnBoardingContext = createContext<OnBoardingContextInterface | undefined>(undefined);
const OnBoardingProvider = OnBoardingContext.Provider;

interface OnBoardingContextProviderProps {
    children: ReactNode;
}

interface AdditionalInformationFormCache {
    phoneNumber: string;
    reasonForApplying: string;
    isEmployed: string;
    reasonForLeaving: string;
    noticePeriod: string;
    validWorkPermit: string;
    whyWorkInStartup: string;
    rankRemunerationLearningBalance: string;
    structureImportance: string;
    personalProject: string;
    whereDoYouSeeYourself: string;
    cvFileName: string;
    salaryExpectation: SalaryExpectation;
}

interface OnBoardingContextProviderState {
    info: ApplicantOnBoardingInfo;
    applicantProfile: ApplicantProfile;
    experiences: ExperienceFormValues[];
    educations: EducationFormValues[];
    processingState: ProcessingState;
    isUploadInProgress: boolean;
    isOnBoardingInProgress: boolean;
    currentFilename?: string;
    fileSize?: number;
    cvFileValidationError?: string;
    additionalInformationCache: AdditionalInformationFormCache;
    cvProcessingState: ProcessingState;
    blobUrl?: string;
}

/* TODO: re-factor OnBoardingContextProvider (task 3934) */
export function OnBoardingContextProvider({ children }: OnBoardingContextProviderProps): JSX.Element {
    const client = useOnBoardingClient();
    const [state, setState] = useState<OnBoardingContextProviderState>({
        info: DefaultApplicantOnBoardingInfo,
        applicantProfile: DefaultApplicantProfile,
        experiences: [],
        educations: [],
        processingState: ProcessingState.Processing,
        isUploadInProgress: false,
        isOnBoardingInProgress: false,
        currentFilename: undefined,
        fileSize: undefined,
        cvFileValidationError: undefined,
        cvProcessingState: ProcessingState.Idle,
        additionalInformationCache: {
            phoneNumber: '',
            reasonForApplying: '',
            isEmployed: '',
            reasonForLeaving: '',
            noticePeriod: '',
            validWorkPermit: '',
            whyWorkInStartup: '',
            rankRemunerationLearningBalance: '',
            structureImportance: '',
            personalProject: '',
            whereDoYouSeeYourself: '',
            cvFileName: '',
            salaryExpectation: {
                min: '',
                max: '',
                currency: {
                    id: '',
                    label: '',
                },
            },
        },
        blobUrl: undefined,
    });
    const blobClient = useBlobClient();
    const aiClient = useAiClient();
    const assessmentId = useAssessmentId(); // we retrieve id from hook, thus changing id in URl will trigger change id here and fire useEffect()
    const { tCommon: t } = useTMTranslation();
    const { getAccessTokenSilently } = useAuth0();

    const { navigateToJobApplicationsPage, replaceWithErrorPage } = useNavigation();
    const { loadUserAsync } = useUserContext();

    const {
        info,
        applicantProfile,
        experiences,
        educations,
        processingState,
        isUploadInProgress,
        isOnBoardingInProgress,
        currentFilename,
        fileSize,
        cvFileValidationError,
        cvProcessingState,
        additionalInformationCache,
        blobUrl,
    } = state;

    useEffect(() => {
        const doGetInfo = async () => {
            try {
                if (assessmentId !== '') {
                    const onboardingInfo = await client.getOnboardingInfoAsync(assessmentId);
                    setState({
                        ...state,
                        info: onboardingInfo,
                        applicantProfile: { ...onboardingInfo.applicantProfile, experiences: [], educations: [] }, // initialize applicantProfile using the data from backend, except experiences and educations
                        processingState: ProcessingState.Success,
                    });
                }
            } catch (error) {
                setState({ ...state, processingState: ProcessingState.Error });
            }
        };
        doGetInfo();
    }, [assessmentId]);

    const deleteCv = async () => {
        await blobClient.deleteCvAsync(info.assessmentId);
        setState((prev) => {
            return {
                ...prev,
                currentFilename: undefined,
                fileSize: undefined,
                cvFileValidationError: undefined,
                cvProcessingState: ProcessingState.Idle,
                experiences: [],
                educations: [],
                applicantProfile: {
                    ...prev.applicantProfile,
                    experiences: [],
                    educations: [],
                },
                blobUrl: undefined,
            };
        });
    };

    const validateCvFile = (file: File): boolean => {
        if (file.size > 5 * 1024 * 1024) {
            setState((prev) => {
                return { ...prev, cvFileValidationError: t('FileSizeTooLarge') };
            });
            return false;
        }
        if (!['pdf', 'docx'].includes(file.name.split('.').at(-1) || '')) {
            setState((prev) => {
                return { ...prev, cvFileValidationError: t('UnsupportedFileFormat') };
            });
            return false;
        }
        return true;
    };

    const uploadCv = async (file: File): Promise<string | undefined> => {
        try {
            setState({
                ...state,
                isUploadInProgress: true,
                cvProcessingState: ProcessingState.Processing,
                currentFilename: file.name,
                cvFileValidationError: undefined,
            });
            const bUrl = await blobClient.uploadCvAsync(info.assessmentId, file);

            setState((prev) => {
                return { ...prev, fileSize: file.size, isUploadInProgress: false, blobUrl: bUrl };
            });

            return bUrl;
        } catch (error: any) {
            if (error.response.status === 400) {
                setState((prev) => {
                    return {
                        ...prev,
                        isUploadInProgress: false,
                        cvProcessingState: ProcessingState.Idle,
                        cvFileValidationError: t('FileIsCorrupted'),
                        currentFilename: undefined,
                        fileSize: undefined,
                    };
                });
            }

            return undefined;
        }
    };

    const parseCvAsync = async (bUrl: string | undefined = undefined) => {
        try {
            if (bUrl !== undefined) {
                if (cvProcessingState !== ProcessingState.Processing) {
                    setCvParsingState(ProcessingState.Processing);
                }

                const cvInfo = await aiClient.parseCvAsync(bUrl);

                setState((prev) => {
                    return {
                        ...prev,
                        experiences: fromExperienceParsedDTOsToExperiencesFormValues(cvInfo.experiences),
                        educations: fromEducationParsedDTOsToEducationsFormValues(cvInfo.educations, t),
                        cvProcessingState: ProcessingState.Success,
                    };
                });
            }
        } catch {
            setCvParsingState(ProcessingState.Error);
        }
    };

    const uploadAndParseCv = async (file: File) => {
        if (validateCvFile(file)) {
            const bUrl = await uploadCv(file);
            await parseCvAsync(bUrl);
        }
    };

    const onBoardApplicant = async (additionalInfo: AdditionalInfo): Promise<void> => {
        try {
            setState({ ...state, processingState: ProcessingState.Processing });
            await client.onBoardApplicantAsync(info.assessmentId, additionalInfo);
            setState((prev) => {
                return {
                    ...prev,
                    processingState: ProcessingState.Success,
                };
            });
        } catch (error) {
            setState({ ...state, processingState: ProcessingState.Error });
            // rethrow error to be caught by the caller
            throw error;
        }
    };

    const onBoardingWrapper = async (additionalInfo: AdditionalInfo): Promise<void> => {
        try {
            await getAccessTokenSilently({ ignoreCache: true });
            setState({ ...state, isOnBoardingInProgress: true });

            await onBoardApplicant(additionalInfo);
            // order is important
            // 1. navigate from page
            // 2. load user once again in case first & last name has changed
            // to reflect this in side menu
            navigateToJobApplicationsPage(assessmentId);
            await loadUserAsync();
        } catch {
            replaceWithErrorPage(true);
        }
    };

    const setApplicantProfile = (value: ApplicantProfile) => {
        setState((prev) => {
            return {
                ...prev,
                applicantProfile: value,
                experiences: fromExperiencesToExperiencesFormValues(value.experiences),
                educations: fromEducationsToEducationsFormValues(value.educations, t),
            };
        });
    };

    const setRecruitmentInformation = (values: RecruitmentInformationFormValues) => {
        setState((prev) => {
            return {
                ...prev,
                additionalInformationCache: {
                    phoneNumber: values.PhoneNumber,
                    reasonForApplying: values.ReasonForApplying,
                    isEmployed: values.IsEmployed,
                    reasonForLeaving: values.ReasonForLeaving,
                    noticePeriod: values.NoticePeriod,
                    validWorkPermit: values.ValidWorkPermit,
                    whyWorkInStartup: values.WhyWorkInStartup,
                    rankRemunerationLearningBalance: values.RankRemunerationLearningBalance,
                    structureImportance: values.StructureImportance,
                    personalProject: values.PersonalProject,
                    whereDoYouSeeYourself: values.WhereDoYouSeeYourself,
                    cvFileName: values.cvFile,
                    salaryExpectation: values.SalaryExpectation,
                },
            };
        });
    };

    const setCvParsingState = (newState: ProcessingState) => {
        setState((prev) => {
            return { ...prev, cvProcessingState: newState };
        });
    };

    return (
        <OnBoardingProvider
            value={{
                info,
                applicantProfile,
                experiences,
                educations,
                processingState,
                onBoardApplicant,
                onBoardingWrapper,
                setApplicantProfile,
                uploadAndParseCv,
                isUploadInProgress,
                isOnBoardingInProgress,
                currentFilename,
                deleteCv,
                fileSize,
                cvFileValidationError,
                cvProcessingState,
                additionalInformationCache,
                setRecruitmentInformation,
                setCvParsingState,
                parseCvAsync,
                blobUrl,
            }}
        >
            {children}
        </OnBoardingProvider>
    );
}

export function useOnBoardingContext() {
    const context = useContext(OnBoardingContext);
    if (!context) {
        throw new Error('OnBoarding context should be used inside OnBoardingContextProvider');
    }
    return context;
}
