import React from 'react';
import { Stack, InfiniteScroll } from '@talentmesh/core';
import EndOfJobApplicationList from './components/EndOfJobApplicationList';
import JobApplicationCard from '../JobApplicationCard/JobApplicationCard';
import { useApplicantAssessmentInfoContext } from '../../../../../Context/ApplicantAssessmentInfoContext';
import JobApplicationFilter from '../JobApplicationHeader/components/JobApplicationFilter';

function JobApplicationList() {
    const { assessments, totalCount, loadAssessmentAsync, lastUpdatedAssessmentId } =
        useApplicantAssessmentInfoContext();

    return (
        <>
            <JobApplicationFilter />
            <InfiniteScroll
                next={async () => await loadAssessmentAsync(assessments.length)}
                hasMore={assessments.length < totalCount}
                endMessage={<EndOfJobApplicationList />}
            >
                <Stack data-cy="JobApplicationList" spacing={2.5} sx={{ width: '100%' }}>
                    {assessments.map((assessment) => (
                        <JobApplicationCard
                            key={assessment.id}
                            assessment={assessment}
                            isUpdated={lastUpdatedAssessmentId === assessment.id}
                        />
                    ))}
                </Stack>
            </InfiniteScroll>
        </>
    );
}

export default JobApplicationList;
