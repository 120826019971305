import { useTheme } from '@mui/material';
import { Grid, Paper, Stack } from '@talentmesh/core';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ExperienceFormValues } from '../../../../MyAssessment/ProfileSettings/utils/ApplicantProfileUtils';
import profilePaperPadding from '../../Common/Consts';
import { getPeriod } from '../../Common/Utils';
import GridItem from '../../Components/GridItem';
import GridLabel from '../../Components/GridLabel';
import GridValue from '../../Components/GridValue';
import Toolbar from './Components/Toolbar';
import JobExperiencesFieldsNames from '../JobExperiencesFieldsNames';
import useTMTranslation from '../../../../../Hooks/useTMTranslation';

export interface JobExperienceViewProps {
    index: number;
    onRemove: () => void;
    onEdit: (isInEdit: boolean, experience: ExperienceFormValues) => void;
}
const name = JobExperiencesFieldsNames.ExperiencesName;

export function JobExperienceView({ index, onRemove, onEdit }: JobExperienceViewProps): JSX.Element {
    const theme = useTheme();
    const { tCommon: t } = useTMTranslation();
    const { control } = useFormContext();
    const { title, jobExperience, company, startDate, endDate }: ExperienceFormValues = useWatch({
        control,
        name: `${name}.${index}`,
    });

    return (
        <Paper
            elevation={0}
            square
            sx={{
                backgroundColor: theme.palette.grey[200],
                borderTop: `1px solid ${theme.palette.border.main}`,
                borderBottom: `1px solid ${theme.palette.border.main}`,
                marginLeft: -profilePaperPadding,
                marginRight: -profilePaperPadding,
                paddingBottom: profilePaperPadding,
            }}
            data-cy={`JobExperienceView-${index}`}
        >
            <Paper
                elevation={0}
                square
                sx={{
                    paddingTop: profilePaperPadding,
                    paddingLeft: profilePaperPadding,
                    paddingRight: profilePaperPadding,
                    backgroundColor: 'transparent',
                }}
            >
                <Stack spacing={1}>
                    <Grid container>
                        <GridLabel label={t('Title')} />
                        <GridValue value={title} xs={6} />
                        <GridItem xs={2} alignItems="center" justifyContent="flex-end">
                            <Toolbar index={index} onRemove={onRemove} onEdit={onEdit} />
                        </GridItem>

                        <GridLabel label={t('JobType')} />
                        <GridValue value={jobExperience} />

                        <GridLabel label={t('Company')} />
                        <GridValue value={company} />

                        {startDate && (
                            <>
                                <GridLabel label={t('Period')} />
                                <GridValue
                                    value={getPeriod(new Date(startDate), endDate ? new Date(endDate) : t('Present'))}
                                />
                            </>
                        )}
                    </Grid>
                </Stack>
            </Paper>
        </Paper>
    );
}
