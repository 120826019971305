import { useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { Stack, Typography, LoadingOverlay } from '@talentmesh/core';
import { Form, Formik } from 'formik';
import { Location, Navigate, useLocation } from 'react-router';
import * as yup from 'yup';
import Page from '../../Components/Pages/Page';
import WelcomePageRulesSection from './Components/WelcomePageRulesSection';
import WelcomePageAction from './Components/WelcomePageAction';
import useTMTranslation from '../../Hooks/useTMTranslation';
import WelcomePageTestLanguageSection from './Components/WelcomePageTestLanguageSection';
import { RecruitmentLanguage } from './Components/RecruitmentLanguage';
import useNavigation from '../../Hooks/useNavigation';
import useAssessmentId from '../../Hooks/useAssessmentId';
import RoutePath from '../../Routing/RoutePath';
import { TestInfo } from '../../Models/TestInfo';
import { useLocalizationContext } from '../../Context/LocalizationContext';

export interface WelcomePageRouteState {
    activeTest?: TestInfo;
    companyName?: string;
}

function AssessmentWelcomePage(): JSX.Element {
    const theme = useTheme();
    const { tTest, current } = useTMTranslation();
    const { navigateToMonitoringPage } = useNavigation();
    const {
        activeLanguage,
        updateTestLanguage,
        availableTestLanguage,
        setAvailableTestLanguage,
        fetchingTestLanguageOptions,
    } = useLocalizationContext();

    const assessmentId = useAssessmentId();
    const location = useLocation() as Location<WelcomePageRouteState>;
    const activeTest = location.state?.activeTest;
    const companyName = location.state?.companyName;

    useEffect(() => {
        if (current.language) {
            setAvailableTestLanguage(assessmentId);
        }
    }, [current.language]);

    if (activeTest === undefined || companyName === undefined) {
        return <Navigate to={RoutePath.Assessments} />;
    }

    const handleSubmit = async (formValues: RecruitmentLanguage) => {
        await updateTestLanguage(assessmentId, formValues.locale);
        navigateToMonitoringPage(assessmentId, activeTest, companyName);
    };

    if (fetchingTestLanguageOptions || availableTestLanguage[0] === '') {
        return <LoadingOverlay />;
    }

    // check if the language is predefined (length of 1) or a list of options
    const isApplicantSelect = availableTestLanguage.length > 1;

    const getMatchingLocaleIndex = availableTestLanguage.find((value) => value === activeLanguage);
    const initialLocale = getMatchingLocaleIndex ?? availableTestLanguage[0];

    const initialFormValues: RecruitmentLanguage = {
        locale: initialLocale,
    };

    const validationSchema = yup.object().shape({
        locale: yup.string().required(''),
    });

    return (
        <Formik
            initialValues={initialFormValues}
            onSubmit={handleSubmit}
            validationSchema={isApplicantSelect ? validationSchema : undefined}
        >
            <Form>
                <Page containerProps={{ disableGutters: true }}>
                    <Stack alignItems="center" spacing={2.5} marginTop={3.75}>
                        <Typography
                            variant="h4"
                            sx={{ textAlign: 'left', width: '100%', color: theme.palette.secondary.light }}
                        >
                            {tTest('BeforeStartingTheTestPleaseNoteTheTestLanguage')}
                        </Typography>
                        <WelcomePageTestLanguageSection availableTestLanguage={availableTestLanguage} />
                        <WelcomePageRulesSection />
                        <WelcomePageAction />
                    </Stack>
                </Page>
            </Form>
        </Formik>
    );
}

export default AssessmentWelcomePage;
