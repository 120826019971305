const magicTouchPadNumber = 1.0416667461395264;

export const isTouchpad = (deltaY: number, wheelDeltaY: number) => {
    const dy = Math.abs(deltaY);
    const wdy = Math.abs(wheelDeltaY);
    // pc || mac || hp
    return dy === wdy || dy * 3 === wdy || (dy / magicTouchPadNumber) % 1 === 0;
};

export const isMouse = (deltaY: number, wheelDeltaY: number) => {
    const dy = Math.abs(deltaY);
    const wdy = Math.abs(wheelDeltaY);
    const n = dy / 100;
    // pc || mac
    return wdy === n * 120 || (dy % 1 > 0 && !((dy / magicTouchPadNumber) % 1 === 0));
};
