import React from 'react';
import useProfileSettingsForm from './useProfileSettingsForm';
import LoadingContent from '../../../../Components/LoadingContent';
import ProfileSettingsFormInner from './ProfileSettingsFormInner';

const ProfileSettingsForm: React.FC = () => {
    const { isLoading } = useProfileSettingsForm();

    if (isLoading) {
        return <LoadingContent />;
    } else {
        return <ProfileSettingsFormInner />;
    }
};

export default ProfileSettingsForm;
