import React from 'react';
import { Typography, Stack } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import TestPurposeTooltip from './TestPurposeTooltip';
import useTMTranslation from '../../../../../../../Hooks/useTMTranslation';

function TestPurpose() {
    const theme = useTheme();
    const { tCommon: t } = useTMTranslation();

    return (
        <Stack direction="row" spacing={0.625} sx={{ alignItems: 'center' }}>
            <Typography variant="body2" sx={{ color: theme.palette.secondary.main }}>
                {t('WhatIsThePurposeOfTheTalentAssessment')}
            </Typography>
            <TestPurposeTooltip />
        </Stack>
    );
}

export default TestPurpose;
