/* eslint-disable react/no-danger */
import React from 'react';
import DOMPurify from 'dompurify';

interface HtmlViewerProps {
    html: string;
}

const HtmlViewer = ({ html }: HtmlViewerProps) => {
    return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }} />;
};

export default HtmlViewer;
