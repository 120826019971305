import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Paper, Stack, Typography } from '@talentmesh/core';
import TestInfoLabel from './TestInfoLabel';
import TestInfoCheckIcon from './TestInfoCheckIcon';
import { customEaseCurve, shrinkTestInfoCard } from '../../../../animations/JobApplicationAnimation';
import { getBorderByTestState, getPreviousTestInfoTestState } from './TestInfoUtil';
import { testTypeToString } from '../../../../../../../Utils/mappings';
import { TestStates, TestInfo } from '../../../../../../../Models/TestInfo';
import useTMTranslation from '../../../../../../../Hooks/useTMTranslation';

interface TestInfoCardProps
    extends Pick<TestInfo, 'estimatedTimeMinutes' | 'allocatedTimeMinutes' | 'testState' | 'testType'> {
    triggerTransition: boolean;
    triggerShrink: boolean;
    isSubmitted: boolean;
    compact?: boolean;
}

function TestInfoCard({
    compact,
    testType,
    triggerTransition,
    triggerShrink,
    testState,
    isSubmitted,
    estimatedTimeMinutes,
    allocatedTimeMinutes,
}: TestInfoCardProps): JSX.Element {
    const theme = useTheme();
    const { tCommon: t } = useTMTranslation();

    const isCompleted = testState === TestStates.Completed;

    const [state, setState] = useState<TestStates>(
        triggerTransition ? getPreviousTestInfoTestState(testState) : testState,
    );

    useEffect(() => {
        if (triggerTransition) {
            const updateState = setTimeout(
                () => {
                    setState(testState);
                },
                isCompleted ? 600 : 1200,
            );

            return () => clearTimeout(updateState);
        }

        return undefined;
    }, []);

    return (
        <Box
            sx={{
                width: '100%',
                position: 'relative',
                maxWidth: theme.spacing(33.75),
                minWidth: theme.spacing(25),
            }}
        >
            <Paper
                elevation={state === TestStates.Active && !isSubmitted ? 5 : 0}
                sx={{
                    backgroundColor: theme.palette.common.white,
                    borderRadius: theme.shape.mediumBorderRadius,
                    width: '100%',
                    position: 'relative',
                    height: theme.spacing(compact && !triggerShrink ? 5.75 : 12.5),
                    maxWidth: theme.spacing(33.75),
                    overflow: 'hidden',
                    padding: theme.spacing(1.25),
                    minWidth: theme.spacing(25),
                    border: getBorderByTestState(theme, isSubmitted && !isCompleted ? undefined : state),
                    transitionProperty: triggerTransition ? 'border-color, box-shadow' : 'none',
                    transitionDuration: '.3s',
                    animationName: triggerShrink ? `${shrinkTestInfoCard(theme)}` : 'none',
                    animationDuration: '.3s',
                    animationDelay: '.9s',
                    animationTimingFunction: customEaseCurve,
                    animationFillMode: 'forwards',
                }}
            >
                <Stack spacing={1.25}>
                    <Typography
                        variant="body1"
                        sx={{ color: theme.palette.text.menuTitle, fontWeight: theme.typography.fontWeightBold }}
                    >
                        {testTypeToString(testType, t)}
                    </Typography>
                    <Stack spacing={0.625}>
                        <TestInfoLabel
                            label={t('EstDuration')}
                            value={`${estimatedTimeMinutes} ${t('Min').toLowerCase()}`}
                        />
                        <TestInfoLabel label={t('TimeLimit')} value={allocatedTimeMinutes ? t('Yes') : t('No')} />
                    </Stack>
                </Stack>
            </Paper>
            {state === TestStates.Completed && <TestInfoCheckIcon />}
        </Box>
    );
}

export default TestInfoCard;
