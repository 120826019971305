import React from 'react';
import { useDialogsContext } from '../../../Context/DialogsContext';
import { TestInfo } from '../../../Models/TestInfo';
import TestActionDialogs from '../../MyAssessment/JobApplication/components/JobApplicationCard/components/TestAction/TestActionDialogs';
import useTestActions from '../../MyAssessment/JobApplication/components/JobApplicationCard/components/TestAction/useTestActions';
import CTMonitoringWarningDialog from './CTMonitoringWarningDialog';

interface CTMonitoringDialogsProps {
    id: string;
    activeTest: TestInfo | undefined;
}

function CTMonitoringDialogs({ id, activeTest }: CTMonitoringDialogsProps): JSX.Element {
    const { enableCTMonitoringDialogId, setEnableCTMonitoringDialogId } = useDialogsContext();
    const { startTestHandlerAsync } = useTestActions({
        id,
    });

    return (
        <>
            <CTMonitoringWarningDialog
                isOpen={enableCTMonitoringDialogId === id}
                onConfirm={() => {
                    setEnableCTMonitoringDialogId('');
                    startTestHandlerAsync(activeTest);
                }}
                onCancel={() => {
                    setEnableCTMonitoringDialogId('');
                }}
            />
            <TestActionDialogs id={id} activeTest={activeTest} />
        </>
    );
}

export default CTMonitoringDialogs;
