import { AutocompleteOption } from '@talentmesh/core';

export function isEmptyEducationLevel(option: AutocompleteOption | string): boolean {
    return (
        (typeof option === 'string' && option === '') ||
        (typeof option === 'object' && option.id === 'UserDefined' && option.label === '')
    );
}

export function isEmptyAutocompleteOption(option: AutocompleteOption | string): boolean {
    return (
        (typeof option === 'string' && option === '') ||
        (typeof option === 'object' && option.id === undefined && option.label === '')
    );
}

export function isValidDate(date: Date | null, allowNull: boolean = false): boolean {
    if (date === null) {
        return allowNull;
    }

    return date >= new Date('1900-01-01') && date <= new Date();
}
