import React from 'react';
import { LoadingButton } from '@talentmesh/core';
import { ChevronRightIcon } from '@talentmesh/icons';
import { ApplicantAssessmentInfo } from '../../../../../../../Models/ApplicantAssessmentInfo';
import { useAssessmentActionsContext } from '../../../../../../../Context/AssessmentActionsContext';

interface TestActionButtonProps extends Pick<ApplicantAssessmentInfo, 'id'> {
    label: string;
    onClick: () => void;
}

function TestActionButton({ id, label, onClick }: TestActionButtonProps) {
    const { activeAssessmentId } = useAssessmentActionsContext();
    const isButtonActive = id === activeAssessmentId;

    return (
        <LoadingButton
            size="large"
            color="primary"
            disabled={activeAssessmentId ? !isButtonActive : false}
            loading={isButtonActive}
            variant="contained"
            onClick={onClick}
            endIcon={<ChevronRightIcon />}
        >
            {label}
        </LoadingButton>
    );
}

export default TestActionButton;
