import { useTheme } from '@mui/material';
import React from 'react';
import { Box, Button, Dialog, Paper, Stack, Typography } from '@talentmesh/core';
import DialogTypography from '../../../../../Components/Dialogs/DialogTypography';
import { useTestContext } from '../../../Contexts/TestContext';
import useTMTranslation from '../../../../../Hooks/useTMTranslation';

interface SubmitDialogProps {
    open: boolean;
    ok: () => void;
    cancel: () => void;
}

function SubmitDialog({ open, ok, cancel }: SubmitDialogProps): JSX.Element {
    const theme = useTheme();
    const { tTest } = useTMTranslation();
    const { elapsedTime } = useTestContext();

    return (
        <Dialog open={open}>
            <Paper
                sx={{
                    padding: theme.spacing(2),
                    width: theme.spacing(48),
                }}
            >
                <Stack direction="column" spacing={theme.spacing(2)}>
                    <Typography variant="h4" color={theme.palette.text.primary}>
                        {tTest('ReadyToEndYourTest')}
                    </Typography>
                    <Box>
                        <DialogTypography text={`${tTest('YouStillHaveTimeToReviewYourAnswers1')} `} />
                        <DialogTypography text={`${elapsedTime} `} bold />
                        <DialogTypography text={`${tTest('YouStillHaveTimeToReviewYourAnswers2')} `} />
                    </Box>
                    <Typography variant="body2" color={theme.palette.text.primary}>
                        {tTest('YouWillNoLongerBeAbleToMakeChangesToYourAnswers')}
                    </Typography>
                    <Stack direction="row" justifyContent="right" spacing={theme.spacing(1)}>
                        <Button variant="outlined" onClick={cancel}>
                            {tTest('ReviewAnswers')}
                        </Button>
                        <Button data-cy="okButton" variant="contained" onClick={ok}>
                            {tTest('EndTest')}
                        </Button>
                    </Stack>
                </Stack>
            </Paper>
        </Dialog>
    );
}

export default SubmitDialog;
