import { AutocompleteOption, emptyAutocompleteOption } from '@talentmesh/core';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { JobExperienceDTO } from './JobExperienceDTO';
import {
    DefaultExperienceFormValues,
    ExperienceFormValues,
} from '../../Pages/MyAssessment/ProfileSettings/utils/ApplicantProfileUtils';
import autoMapping from '../../Utils/autoMapping';

dayjs.extend(utc);

export interface ExperienceDTO {
    title: string;
    jobExperience: JobExperienceDTO;
    company: string;
    workingHere: boolean;
    startDate: Date;
    endDate: Date | null;
}

export interface ExperienceParsedDTO {
    title: string;
    jobExperience: JobExperienceDTO;
    company: string;
    workingHere: boolean;
    startDate: Date | null;
    endDate: Date | null;
}

export const DefaultExperienceDTO: ExperienceDTO = {
    title: '',
    jobExperience: {},
    company: '',
    workingHere: false,
    startDate: new Date(),
    endDate: null,
};

export const DefaultExperienceParsedDTO: ExperienceParsedDTO = {
    title: '',
    jobExperience: {},
    company: '',
    workingHere: false,
    startDate: null,
    endDate: null,
};

const mapJobExperienceDTOToOption = (jobExperience: JobExperienceDTO): AutocompleteOption | string => {
    let option: AutocompleteOption = { ...emptyAutocompleteOption };

    const { id, userDefined } = jobExperience;
    if (id != null || userDefined != null) {
        option = {
            id: id?.toString(),
            label: userDefined || '',
        };
    }

    return option;
};

export function fromExperienceParsedDTOsToExperiencesFormValues(
    experiences: ExperienceParsedDTO[],
): ExperienceFormValues[] {
    return experiences.map((x) => {
        const experience = autoMapping(x, DefaultExperienceFormValues);
        experience.startDate = experience.startDate ? (dayjs.utc(experience.startDate) as unknown as Date) : null;
        experience.endDate = experience.endDate ? (dayjs.utc(experience.endDate) as unknown as Date) : null;
        experience.jobExperience = mapJobExperienceDTOToOption(x.jobExperience);
        return experience;
    });
}
