import { RHFTextField } from '@talentmesh/forms';
import React from 'react';

interface CustomRHFTextFieldProps {
    name: string;
    label: string;
}
const CustomRHFTextField: React.FC<CustomRHFTextFieldProps> = ({ name, label }) => {
    return <RHFTextField name={name} label={`${label}*`} fullWidth />;
};

export default CustomRHFTextField;
