import { useTheme } from '@mui/material';
import { Grid, Paper, Stack } from '@talentmesh/core';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { EducationFormValues } from '../../../../MyAssessment/ProfileSettings/utils/ApplicantProfileUtils';
import profilePaperPadding from '../../Common/Consts';
import { getPeriod } from '../../Common/Utils';
import GridItem from '../../Components/GridItem';
import GridLabel from '../../Components/GridLabel';
import GridValue from '../../Components/GridValue';
import Toolbar from './Components/Toolbar';
import EducationsFieldsNames from '../EducationsFieldsNames';
import useTMTranslation from '../../../../../Hooks/useTMTranslation';

export interface EducationsViewProps {
    index: number;
    onRemove: () => void;
    onEdit: (isInEdit: boolean, experience: EducationFormValues) => void;
}

export function EducationsView({ index, onRemove, onEdit }: EducationsViewProps): JSX.Element {
    const theme = useTheme();
    const { tCommon: t } = useTMTranslation();
    const { control } = useFormContext();
    const { schoolName, educationLevel, educationField, startDate, endDate }: EducationFormValues = useWatch({
        control,
        name: `${EducationsFieldsNames.EducationsName}.${index}`,
    });

    return (
        <Paper
            elevation={0}
            square
            sx={{
                backgroundColor: theme.palette.grey[200],
                borderTop: `1px solid ${theme.palette.border.main}`,
                borderBottom: `1px solid ${theme.palette.border.main}`,
                marginLeft: -profilePaperPadding,
                marginRight: -profilePaperPadding,
                paddingBottom: profilePaperPadding,
            }}
            data-cy={`EducationView-${index}`}
        >
            <Paper
                elevation={0}
                square
                sx={{
                    paddingTop: profilePaperPadding,
                    paddingLeft: profilePaperPadding,
                    paddingRight: profilePaperPadding,
                    backgroundColor: 'transparent',
                }}
            >
                <Stack spacing={1}>
                    <Grid container>
                        <GridLabel label={t('NameOfSchool')} />
                        <GridValue value={schoolName} xs={6} />
                        <GridItem xs={2} alignItems="center" justifyContent="flex-end">
                            <Toolbar index={index} onRemove={onRemove} onEdit={onEdit} />
                        </GridItem>
                        <GridLabel label={t('FieldOfEducation')} />
                        <GridValue value={educationField} />

                        <GridLabel label={t('LevelOfEducation')} />
                        <GridValue value={educationLevel} />

                        {startDate && endDate && (
                            <>
                                <GridLabel label={t('Period')} />
                                <GridValue value={getPeriod(new Date(startDate), new Date(endDate))} />
                            </>
                        )}
                    </Grid>
                </Stack>
            </Paper>
        </Paper>
    );
}
