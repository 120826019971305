import { AutocompleteOption } from '@talentmesh/core';
import { TMTranslationGetter } from '../../../Hooks/useTMTranslation';
import { CommonResourceKey } from '../../../i18n/byNamespace/i18nCommonResourceKeys';

type EducationLevelOptionsType = (t: TMTranslationGetter<CommonResourceKey>) => AutocompleteOption[];

const EducationLevelOptionsGetter: EducationLevelOptionsType = (t) => [
    { id: 'EightGrade', label: t('EightGrade') },
    { id: 'HighSchool', label: t('HighSchool') },
    { id: 'AssociateDegree', label: t('AssociateDegree') },
    { id: 'BachelorDegree', label: t('BachelorDegree') },
    { id: 'MasterDegree', label: t('MasterDegree') },
    { id: 'MBA', label: t('MBA') },
    { id: 'PostgraduateDegree', label: t('PostgraduateDegree') },
    { id: 'PHDDegree', label: t('PHDDegree') },
];

export default EducationLevelOptionsGetter;
