import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import { Dialog, Stack, Typography, Box } from '@talentmesh/core';
import useTMTranslation from '../../../../../../Hooks/useTMTranslation';
import TMWings from './TMWings.svg';

interface CvUploadDialogProps {
    open: boolean;
}

function CvUploadDialog({ open }: CvUploadDialogProps): JSX.Element {
    const theme = useTheme();
    const { tCommon: t } = useTMTranslation();

    const [dots, setDots] = useState('');

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDots((prevDots) => (prevDots.length < 3 ? `${prevDots}.` : ''));
        }, 500); // Change dot every 500ms

        return () => {
            clearInterval(intervalId); // Clean up on unmount
        };
    }, []);

    return (
        <Dialog open={open}>
            <Stack
                direction="column"
                alignItems="center"
                padding={theme.spacing(2.5)}
                paddingTop={theme.spacing(2)}
                paddingBottom={theme.spacing(1.25)}
                paddingLeft={theme.spacing(2.5)}
                paddingRight={theme.spacing(2.5)}
                sx={{ width: theme.spacing(70) }}
            >
                <Typography variant="h4" color={theme.palette.text.primary} marginBottom={theme.spacing(7)}>
                    {t('ImportCvWithAi')}
                </Typography>
                <Typography variant="body1" color={theme.palette.text.primary}>
                    {t('AiIsAnalyzingYourCvData')}
                    {dots}
                </Typography>
                <Box marginTop={theme.spacing(1.25)}>
                    <object
                        width={theme.spacing(27.125)}
                        height={theme.spacing(20.5)}
                        type="image/svg+xml"
                        data={TMWings}
                        aria-label={t('TMWingsIcon')}
                    />
                </Box>
            </Stack>
        </Dialog>
    );
}

export default CvUploadDialog;
