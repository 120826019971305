import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Button, Dialog, LoadingButton, Stack, SpanTypography } from '@talentmesh/core';
import Timer from './Timer.svg';
import { TestInfo } from '../../Models/TestInfo';
import { testTypeToString } from '../../Utils/mappings';
import useTMTranslation from '../../Hooks/useTMTranslation';

interface TimerWarningDialogProps {
    open: boolean;
    loading: boolean;
    activeTest: TestInfo | undefined;
    ok: () => void;
    cancel: () => void;
}

function TimerWarningDialog({ open, loading, activeTest, ok, cancel }: TimerWarningDialogProps): JSX.Element {
    const theme = useTheme();
    const { tCommon: t, tTest } = useTMTranslation();

    return (
        <Dialog open={open} onBackdropClick={cancel} maxWidth={false}>
            <Stack direction="row" spacing={theme.spacing(2.5)} padding={theme.spacing(2.5)}>
                <Stack>
                    <object
                        width="100%"
                        height="100%"
                        type="image/svg+xml"
                        data={Timer}
                        aria-label={tTest('TimerIcon')}
                    />
                </Stack>
                {activeTest && (
                    <Stack direction="column" width={theme.spacing(42.75)} justifyContent="space-between">
                        <Stack spacing={theme.spacing(1.25)}>
                            <SpanTypography variant="h4" color={theme.palette.text.menuTitle}>
                                {`${tTest('ThisTestHasATimeLimitOf')} `}
                                <SpanTypography variant="h4" color={theme.palette.text.highlight}>
                                    {tTest('NumberOfMinutes', { count: activeTest.allocatedTimeMinutes ?? 0 })}
                                </SpanTypography>
                                {` ${tTest('AndIncludes')} `}
                                <SpanTypography variant="h4" color={theme.palette.text.highlight}>
                                    {tTest('NumberOfQuestions', { count: activeTest.totalQuestionsCount })}
                                </SpanTypography>
                            </SpanTypography>
                            <SpanTypography variant="body2">
                                {`${tTest('YouCanTrackTimeOnTheTimer')} `}
                                {`${tTest('TheTestCannotBePaused')}`}
                            </SpanTypography>
                        </Stack>

                        <Stack>
                            <LoadingButton loading={loading} variant="contained" size="large" onClick={ok}>
                                {tTest('StartTestType', {
                                    testName: testTypeToString(activeTest.testType, t).toLowerCase(),
                                })}
                            </LoadingButton>
                            <Button size="large" onClick={cancel}>
                                {t('GoBack')}
                            </Button>
                        </Stack>
                    </Stack>
                )}
            </Stack>
        </Dialog>
    );
}

export default TimerWarningDialog;
