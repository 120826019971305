import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { LoadingOverlay } from '@talentmesh/core';
import { useTranslation } from 'react-i18next';
import {
    useApplicantAssessmentsClient,
    useAssessmentClient,
    useQuestionConfigurationClient,
    useUsersClient,
} from '../Hooks/ClientHooks';
import { CommonLanguageCode, CommonLanguageCodes, TMFallbackLanguageCode } from '../i18n/i18nTypes';

export interface LocalizationContextValue {
    appLanguage: string | undefined;
    activeLanguage: string | undefined;
    currentTestLanguage: string | undefined;
    availableTestLanguage: Array<string>;
    isInitialLoading: boolean;
    fetchingTestLanguageOptions: boolean;
}

export interface LocalizationContextType extends LocalizationContextValue {
    updateAppLanguage: (locale: CommonLanguageCode, updateActive?: boolean) => Promise<void>;
    updateTestLanguage: (recruitmentId: string, locale: string) => Promise<void>;
    setAvailableTestLanguage: (recruitmentId: string) => Promise<void>;
    switchToAppLanguage: () => void;
    switchToTestLanguage: (locale: string) => void;
}

export const LocalizationContext = createContext<LocalizationContextType | undefined>(undefined);

export interface LocalizationContextProviderProps {
    children: ReactNode;
}

export const useLocalizationProvider = (): LocalizationContextType => {
    const client = useUsersClient();
    const assessmentClient = useAssessmentClient();
    const applicantAssessmentsClient = useApplicantAssessmentsClient();
    const configurationClient = useQuestionConfigurationClient();

    const { ready, i18n } = useTranslation();

    const [state, setState] = useState<LocalizationContextValue>({
        activeLanguage: undefined,
        appLanguage: undefined,
        currentTestLanguage: undefined,
        availableTestLanguage: [''],
        isInitialLoading: true,
        fetchingTestLanguageOptions: false,
    });

    const {
        activeLanguage,
        appLanguage,
        currentTestLanguage,
        availableTestLanguage,
        isInitialLoading,
        fetchingTestLanguageOptions,
    } = state;

    const updateAppLanguage = async (locale: CommonLanguageCode) => {
        try {
            await client.saveAppLanguageAsync({ locale });
        } finally {
            setState((prev) => {
                return {
                    ...prev,
                    appLanguage: locale,
                    activeLanguage: locale,
                };
            });
        }
    };

    const updateTestLanguage = async (recruitmentId: string, locale: string) => {
        try {
            if (availableTestLanguage.length > 1) {
                await applicantAssessmentsClient.saveApplicantAssessmentLocaleAsync(recruitmentId, locale);
            }
        } finally {
            setState((prev) => {
                return {
                    ...prev,
                    currentTestLanguage: locale,
                };
            });
        }
    };

    const setAvailableTestLanguage = async (recruitmentId: string) => {
        let testLanguages: Array<string>;

        if (recruitmentId !== '') {
            setState((prev) => {
                return {
                    ...prev,
                    fetchingTestLanguageOptions: true,
                };
            });

            try {
                const { locale } = await assessmentClient.getRecruitmentLocaleAsync(recruitmentId);

                if (locale) {
                    testLanguages = [locale];
                } else {
                    testLanguages = await configurationClient.getAvailableRecruitmentLocale();
                }
            } finally {
                setState((prev) => {
                    return {
                        ...prev,
                        availableTestLanguage: testLanguages,
                        fetchingTestLanguageOptions: false,
                    };
                });
            }
        }
    };

    const switchToAppLanguage = () => {
        if (activeLanguage === undefined) {
            return;
        }

        if (activeLanguage === appLanguage) {
            return;
        }

        setState((prev) => {
            return {
                ...prev,
                activeLanguage: prev.appLanguage,
            };
        });
    };

    const switchToTestLanguage = (locale: string) => {
        if (activeLanguage === undefined) {
            return;
        }

        if (activeLanguage === currentTestLanguage) {
            return;
        }

        setState((prev) => {
            return {
                ...prev,
                currentTestLanguage: locale,
                activeLanguage: locale,
            };
        });
    };

    const doBrowserDetectionAndGracefullyFallback = (): string => {
        const browserLocales = navigator.languages.map((language) => language.substring(0, 2));
        const filteredBrowserLocales = browserLocales.filter((locale) =>
            CommonLanguageCodes.find((knownLocale) => knownLocale === locale),
        );

        const isNoMatchingSupportedLocale = filteredBrowserLocales.length === 0;

        if (isNoMatchingSupportedLocale) {
            return TMFallbackLanguageCode;
        }

        return filteredBrowserLocales[0];
    };

    useEffect(() => {
        const loadAppLanguage = async () => {
            let localeToUse: string | undefined;

            if (!isInitialLoading) {
                return;
            }

            try {
                const { locale } = await client.getAppLanguage();
                localeToUse = locale;
            } finally {
                if (!localeToUse) {
                    localeToUse = doBrowserDetectionAndGracefullyFallback();
                }

                setState((prev) => {
                    return {
                        ...prev,
                        appLanguage: localeToUse,
                        activeLanguage: localeToUse,
                    };
                });
            }
        };

        loadAppLanguage();
    }, []);

    useEffect(() => {
        async function handleChangeLanguage() {
            if (!ready) {
                return;
            }

            await i18n.changeLanguage(activeLanguage);
        }

        handleChangeLanguage();
    }, [activeLanguage]);

    useEffect(() => {
        if (isInitialLoading && i18n.language !== undefined) {
            setState((prev) => {
                return {
                    ...prev,
                    isInitialLoading: false,
                };
            });
        }
    }, [i18n.language]);

    return {
        activeLanguage,
        appLanguage,
        currentTestLanguage,
        availableTestLanguage,
        isInitialLoading,
        fetchingTestLanguageOptions,

        updateAppLanguage,
        updateTestLanguage,
        setAvailableTestLanguage,
        switchToAppLanguage,
        switchToTestLanguage,
    };
};

export const LocalizationContextProvider = ({ children }: LocalizationContextProviderProps): JSX.Element => {
    const provider = useLocalizationProvider();

    if (provider.isInitialLoading) {
        return <LoadingOverlay />;
    }

    return <LocalizationContext.Provider value={provider}>{children}</LocalizationContext.Provider>;
};

export function useLocalizationContext() {
    const context = useContext(LocalizationContext);
    if (!context) {
        throw new Error('useLocalizationContext must be used within the LocalizationContext.Provider');
    }
    return context;
}
