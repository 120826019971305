import ClientBase from './ClientBase';
import { get } from '../Utils/HTTP/HttpUtils';
import { LikertAnswerGroupData } from '../Models/AssessmentData';
import { TMFallbackLanguageCode } from '../i18n/i18nTypes';

export default class QuestionsConfigurationClient extends ClientBase {
    async getLikertAnswerGroups(locale: string): Promise<LikertAnswerGroupData[]> {
        const query = new URLSearchParams({ locale });
        const accessToken = await this.getAccessTokenSilently();

        const result = await get<LikertAnswerGroupData[]>({
            path: super.addHost(`questions/configuration/LikertAnswerGroups?${query.toString()}`),
            accessToken,
        });

        if (result.ok && result.body) {
            return result.body;
        } else {
            return [];
        }
    }

    async getAvailableRecruitmentLocale(): Promise<string[]> {
        const response = await get<string[]>({
            path: this.addHost('questions/configuration/test-locales'),
            accessToken: await this.getAccessTokenSilently(),
        });

        const { ok, body } = response;

        if (ok && body) {
            return body;
        }

        return [TMFallbackLanguageCode];
    }
}
