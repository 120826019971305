import { useTheme } from '@mui/material';
import { Box } from '@talentmesh/core';
import React, { useState } from 'react';
import ProfileInformationStep from './ProfileInformation/ProfileInformationStep';
import RecruitmentInformationStep from './RecruitmentInformation/RecruitmentInformationStep';

function ApplicantOnboardStepper(): JSX.Element {
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Box
            width={theme.spacing(79.5)}
            alignSelf="center"
            paddingTop={theme.spacing(8)}
            paddingBottom={theme.spacing(8)}
        >
            {activeStep === 0 && <ProfileInformationStep handleNext={handleNext} />}
            {activeStep === 1 && <RecruitmentInformationStep handleBack={handleBack} />}
        </Box>
    );
}

export default ApplicantOnboardStepper;
