/* eslint-disable no-case-declarations */
import { AnswerDTO } from '../DTO/Questions/AnswerDTO';
import { DefaultQuestionDTO } from '../DTO/Questions/QuestionDTO';
import { TestStateDTO } from '../DTO/Tests/TestStateDTO';
import { TestTypes } from './Configuration';
import { mapFromQuestionDTO, Question } from './Question';
import QuestionType from './QuestionType';

export interface TestState {
    testId: string;
    candidateId: string;
    assessmentId: string;
    testType: TestTypes;
    startedAt?: string;
    completedAt?: string;
    isWebcamEnabled: boolean;
    isScreenInvigilationEnabled: boolean;
    isFullScreenEnabled: boolean;
    questions: Array<Question>;
    candidateAnswers: Map<string, string>;
    timeLeftSeconds?: number;
    lastAnsweredQuestionIndex: number;
    allocatedTimeSeconds?: number;
    locale: string;
}

export const DefaultTestState: TestState = {
    testId: '',
    candidateId: '',
    assessmentId: '',
    testType: TestTypes.None,
    startedAt: undefined,
    completedAt: undefined,
    isWebcamEnabled: false,
    isScreenInvigilationEnabled: false,
    isFullScreenEnabled: false,
    questions: [],
    candidateAnswers: new Map<string, string>(),
    timeLeftSeconds: 0,
    lastAnsweredQuestionIndex: 0,
    allocatedTimeSeconds: 0,
    locale: '',
};

export function mapFromTestStateDTO(dto: TestStateDTO): TestState {
    let lastAnsweredQuestionIndex = -1;
    const answers = new Map<string, string>();
    // Typescript cannot serialize Map correctly from json
    // therefore we first cast it to object and then convert to a map.
    const map = new Map(Object.entries(dto.candidateAnswers));
    map.forEach((value: AnswerDTO, key: string) => {
        const index = dto.questions.findIndex((item) => item.id === key);
        const question = index >= 0 ? dto.questions[index] : DefaultQuestionDTO;
        if (index > lastAnsweredQuestionIndex) {
            lastAnsweredQuestionIndex = index;
        }
        switch (question.questionType) {
            case QuestionType.Choice:
            case QuestionType.Likert:
                answers.set(key, value.answerId);
                break;
            case QuestionType.Exact:
                answers.set(key, value.answerText);
                break;
            default:
                throw new Error('Unknown question type');
        }
    });
    return {
        testId: dto.testId,
        candidateId: dto.candidateId,
        assessmentId: dto.assessmentId,
        testType: dto.testType,
        startedAt: dto.startedAt,
        completedAt: dto.completedAt,
        isWebcamEnabled: dto.isWebcamEnabled,
        isScreenInvigilationEnabled: dto.isScreenInvigilationEnabled,
        isFullScreenEnabled: dto.isFullScreenEnabled,
        questions: dto.questions.map((item) => mapFromQuestionDTO(item)),
        candidateAnswers: answers,
        timeLeftSeconds: dto.timeLeftSeconds,
        lastAnsweredQuestionIndex,
        allocatedTimeSeconds: dto.allocatedTimeSeconds,
        locale: dto.locale,
    };
}
