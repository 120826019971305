import BitmapCaptureService from './bitmap-capture.service';

class BitmapCaptureWebcamService extends BitmapCaptureService {
    protected initialize = async (): Promise<void> => {
        if (this.media?.active) {
            return;
        }

        this.media = await navigator.mediaDevices.getUserMedia({ video: true });
        this.video.srcObject = this.media;
    };
}

export default BitmapCaptureWebcamService;
