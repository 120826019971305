import React from 'react';
import { Typography, TooltipPaper, Stack } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import useTMTranslation from '../../../../../../../Hooks/useTMTranslation';

function TestPurposeTooltipContent() {
    const theme = useTheme();
    const { tCommon: t } = useTMTranslation();

    return (
        <TooltipPaper minWidth={theme.spacing(43.1)}>
            <Stack spacing={2.5}>
                <Typography variant="h5" sx={{ color: theme.palette.secondary.main }}>
                    {t('PurposeOfTalentAssessment')}
                </Typography>
                <Typography variant="body2" sx={{ color: theme.palette.secondary.main }}>
                    <span>{`${t('PurposeOfTalentAssessmentIsToGenerate')} `}</span>
                    <span style={{ fontWeight: theme.typography.fontWeightBold }}>
                        {`${t('ItsAnOpportunityToShowcaseYourUniquetalent')}, `}
                    </span>
                    <span>{t('WhichCanBeHardToEvaluateSolelyFromReading')}</span>
                </Typography>
            </Stack>
        </TooltipPaper>
    );
}

export default TestPurposeTooltipContent;
