import React, { createContext, ReactNode, useContext, useState } from 'react';

interface DialogsContextState {
    completeTestDialogId: string;
    timerWarningDialogId: string;
    enableCTMonitoringDialogId: string;
}

const DefaultDialogsContextState = {
    completeTestDialogId: '',
    timerWarningDialogId: '',
    enableCTMonitoringDialogId: '',
};

export interface DialogsContextType extends DialogsContextState {
    setCompleteTestDialogId: (id: string) => void;
    setTimerWarningDialogId: (id: string) => void;
    setEnableCTMonitoringDialogId: (id: string) => void;
    closeAllDialogs: () => void;
}

export const DialogsContext = createContext<DialogsContextType | undefined>(undefined);

const DialogsContextProvider = DialogsContext.Provider;

interface DialogsProps {
    children: ReactNode;
}

export const DialogsProvider = ({ children }: DialogsProps): JSX.Element => {
    const [state, setState] = useState<DialogsContextState>(DefaultDialogsContextState);

    const setCompleteTestDialogId = (id: string) => {
        setState(() => ({
            completeTestDialogId: id,
            timerWarningDialogId: '',
            enableCTMonitoringDialogId: '',
        }));
    };

    const setTimerWarningDialogId = (id: string) => {
        setState(() => ({
            completeTestDialogId: '',
            timerWarningDialogId: id,
            enableCTMonitoringDialogId: '',
        }));
    };

    const setEnableCTMonitoringDialogId = (id: string) => {
        setState(() => ({
            completeTestDialogId: '',
            timerWarningDialogId: '',
            enableCTMonitoringDialogId: id,
        }));
    };

    const closeAllDialogs = () => {
        setState(DefaultDialogsContextState);
    };

    return (
        <DialogsContextProvider
            value={{
                ...state,

                setCompleteTestDialogId,
                setTimerWarningDialogId,
                setEnableCTMonitoringDialogId,
                closeAllDialogs,
            }}
        >
            {children}
        </DialogsContextProvider>
    );
};

export function useDialogsContext() {
    const context = useContext(DialogsContext);
    if (!context) {
        throw new Error('useDialogsContext must be used within the DialogsContext.Provider');
    }
    return context;
}
