import { Stack } from '@talentmesh/core';
import { DeleteOutlineIcon, EditIcon } from '@talentmesh/icons';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ExperienceFormValues } from '../../../../../MyAssessment/ProfileSettings/utils/ApplicantProfileUtils';
import CustomIconButton from '../../../Components/CustomIconButton';
import JobExperiencesFieldsNames from '../../JobExperiencesFieldsNames';

interface ToolbarProps {
    index: number;
    onRemove: () => void;
    onEdit: (isInEdit: boolean, experience: ExperienceFormValues) => void;
}

const Toolbar: React.FC<ToolbarProps> = ({ index, onRemove, onEdit }) => {
    const { control } = useFormContext();
    const experience: ExperienceFormValues = useWatch({
        control,
        name: `${JobExperiencesFieldsNames.ExperiencesName}.${index}`,
    });

    return (
        <Stack direction="row">
            <CustomIconButton
                data-cy="edit"
                onClick={() => {
                    // need to clone an object here
                    // otherwise, the snapshot will get all resent updates.
                    onEdit(true, { ...experience });
                }}
            >
                <EditIcon />
            </CustomIconButton>
            <CustomIconButton data-cy="delete" onClick={onRemove}>
                <DeleteOutlineIcon />
            </CustomIconButton>
        </Stack>
    );
};

export default Toolbar;
