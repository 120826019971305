import Constants from '../../../../Models/Constants';

export function getDelta(start: Date, end: Date): number {
    return (end.getTime() - start.getTime()) / 1000;
}

export function pad(value: number): string {
    let result = value.toString();
    if (result.length === 1) {
        result = `0${result}`;
    }

    return result;
}

export function getElapsed(start: Date, end: Date): string {
    const delta = getDelta(start, end);

    const minutes = pad(Math.floor(delta / 60) % 60);
    const seconds = pad(Math.floor(delta % 60));

    if (delta < 0) {
        return Constants.TimeOut;
    }

    return `${minutes}:${seconds}`;
}

export function getPageSize(): number {
    const itemWidth = 48; // pixels
    const shiftButtonWidth = 64; // pixels
    const shiftButtonStepperMargin = 24;
    const widthScope = document.body.clientWidth - 2 * shiftButtonWidth - 2 * shiftButtonStepperMargin;
    return Math.floor(widthScope / itemWidth);
}
