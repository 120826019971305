import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography, Box } from '@talentmesh/core';
import {
    getApplicantAssessmentStatus,
    getJobApplicationCardStatusColor,
    getPreviousApplicantAssessmentStatus,
    getStatusTransitionTrigger,
} from '../../../../../utils/JobApplicationUtil';
import { ApplicantAssessmentInfoStatus } from '../../../../../../../../DTO/Assessments/ApplicantAssessmentInfoDTO';
import useTMTranslation from '../../../../../../../../Hooks/useTMTranslation';

interface StatusSectionProps {
    applicantAssessmentInfoStatus: ApplicantAssessmentInfoStatus;
    isUpdated: boolean;
}

function StatusSection({ applicantAssessmentInfoStatus, isUpdated }: StatusSectionProps) {
    const theme = useTheme();
    const { tCommon: t } = useTMTranslation();
    const triggerTransition = isUpdated && getStatusTransitionTrigger(applicantAssessmentInfoStatus);

    const [status, setStatus] = useState<ApplicantAssessmentInfoStatus>(
        triggerTransition
            ? getPreviousApplicantAssessmentStatus(applicantAssessmentInfoStatus)
            : applicantAssessmentInfoStatus,
    );

    useEffect(() => {
        if (triggerTransition) {
            const updateStatus = setTimeout(() => {
                setStatus(applicantAssessmentInfoStatus);
            }, 300);

            return () => clearTimeout(updateStatus);
        }

        return undefined;
    }, []);

    return (
        <Box
            sx={{
                backgroundColor: getJobApplicationCardStatusColor(theme, status),
                borderBottomLeftRadius: theme.shape.mediumBorderRadius,
                paddingX: theme.spacing(2),
                width: 'fit-content',
                marginLeft: 'auto',
                marginBottom: theme.spacing(1.25),
                transitionProperty: triggerTransition ? 'background-color' : 'none',
                transitionDuration: '.3s',
            }}
        >
            <Typography
                variant="body1"
                sx={{
                    color: theme.palette.common.white,
                    fontWeight: theme.typography.fontWeightBold,
                    paddingX: theme.spacing(1),
                    paddingY: theme.spacing(0.5),
                }}
            >
                {`${t('Status')}: ${getApplicantAssessmentStatus(status, t)}`}
            </Typography>
        </Box>
    );
}

export default StatusSection;
