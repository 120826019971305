import React, { useEffect, useState } from 'react';
import { Stack } from '@talentmesh/core';
import { useField } from 'formik';
import CurrencyAutocomplete from './CurrencyAutocomplete';
import YearlySalaryRange from './YearlySalaryRange';
import SalaryError from './SalaryError';
import { RecruitmentInformationSalaryExpectation } from '../../../RecruitmentInformationUtils';
import useTMTranslation from '../../../../../../../Hooks/useTMTranslation';

function Salary(): JSX.Element {
    const { tCommon } = useTMTranslation();

    function xor(a: boolean, b: boolean): boolean {
        return (!a && b) || (a && !b);
    }

    const [, metaSalaryExpectationMin] = useField(RecruitmentInformationSalaryExpectation.SalaryExpectationMin);
    const [, metaSalaryExpectationMax] = useField(RecruitmentInformationSalaryExpectation.SalaryExpectationMax);
    const [, metaSalaryExpectationCurrency] = useField(
        `${RecruitmentInformationSalaryExpectation.SalaryExpectationCurrency}.label`,
    );
    const [customError, setCustomError] = useState<string | undefined>('');

    useEffect(() => {
        const yearlySalaryFromError = metaSalaryExpectationMin.touched ? metaSalaryExpectationMin.error : undefined;
        const yearlySalaryToError = metaSalaryExpectationMax.touched ? metaSalaryExpectationMax.error : undefined;
        const currencyError = metaSalaryExpectationCurrency.touched ? metaSalaryExpectationCurrency.error : undefined;

        // xor(a,b) returns true if only one variable is true
        // to check if only one field has error, we use xor(xor(a, b), c)
        // using !! we convert string to boolean
        if (xor(xor(!!yearlySalaryFromError, !!yearlySalaryToError), !!currencyError)) {
            // Only 1 error
            setCustomError(yearlySalaryFromError || yearlySalaryToError || currencyError);
        } else if (yearlySalaryFromError || yearlySalaryToError || currencyError) {
            // More than 1 error
            setCustomError(tCommon('InvalidInputError'));
        } else {
            // No errors
            setCustomError(undefined);
        }
    }, [metaSalaryExpectationMin, metaSalaryExpectationMax, metaSalaryExpectationCurrency]);

    return (
        <Stack>
            <Stack direction="row" spacing={2.5}>
                <YearlySalaryRange />
                <CurrencyAutocomplete />
            </Stack>
            {customError && <SalaryError error={customError} />}
        </Stack>
    );
}

export default Salary;
