import { useTheme } from '@mui/material';
import { RHFDatePicker } from '@talentmesh/forms';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface CustomRHFDatePickerProps {
    name: string;
    label: string;
    datePickerLabel?: string;
    disabled?: boolean;
}

const CustomRHFDatePicker: React.FC<CustomRHFDatePickerProps> = ({ name, label, datePickerLabel, disabled }) => {
    const theme = useTheme();
    const { setValue } = useFormContext();

    useEffect(() => {
        if (disabled) {
            setValue(name, null);
        }
    }, [disabled]);

    return (
        <RHFDatePicker
            name={name}
            label={`${label}*`}
            datePickerLabel={datePickerLabel}
            views={['month', 'year']}
            disableFuture
            slotProps={{
                textField: {
                    fullWidth: true,
                    style: {
                        backgroundColor: disabled
                            ? theme.palette.action.disabledBackgroundLight
                            : theme.palette.common.white,
                        borderRadius: theme.spacing(1),
                    },
                },
            }}
            disabled={disabled}
        />
    );
};

export default CustomRHFDatePicker;
