import { Location, useLocation } from 'react-router';
import useNavigation from '../../../Hooks/useNavigation';
import { TestInfo } from '../../../Models/TestInfo';

interface CTMonitoringPageRouteState {
    activeTest?: TestInfo;
    companyName?: string;
}

function useCTMonitoringPageRouteState(): CTMonitoringPageRouteState {
    const { replaceWithJobApplicationsPage } = useNavigation();
    const location = useLocation() as Location<CTMonitoringPageRouteState>;

    const activeTest = location.state?.activeTest;
    const companyName = location.state?.companyName;

    if (activeTest === undefined || companyName === undefined) {
        replaceWithJobApplicationsPage();
    }

    return { activeTest, companyName };
}

export default useCTMonitoringPageRouteState;
