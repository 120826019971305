export default class Constants {
    static readonly textFieldLargeCapacity = 500;

    static readonly textFieldSmallCapacity = 30;

    static readonly yesNoCapacity = 3;

    static readonly numberOfRows = 10;

    static readonly isEmployed = 'isEmployed';

    static readonly isUnemployed = 'isUnemployed';
}
