import React from 'react';
import { useTheme } from '@mui/material';
import { Box, Button, Dialog, Stack, SpanTypography, Typography } from '@talentmesh/core';
import DialogTypography from './DialogTypography';
import useTMTranslation from '../../Hooks/useTMTranslation';

interface CompleteTestDialogProps {
    open: boolean;
    ok: () => void;
    cancel: () => void;
    companyName: string;
}

function CompleteTestDialog({ companyName, open, ok, cancel }: CompleteTestDialogProps): JSX.Element {
    const theme = useTheme();
    const { tTest: t } = useTMTranslation();

    return (
        <Dialog open={open}>
            <Stack
                direction="column"
                spacing={theme.spacing(2)}
                padding={theme.spacing(2.5)}
                sx={{ width: theme.spacing(50) }}
            >
                <Typography variant="h4" color={theme.palette.text.primary}>
                    {t('CompleteYourUnfinishedTest')}
                </Typography>
                <Box>
                    <DialogTypography text={`${t('YouHaveAnUnfinishedTalentMeshPreEmploymentTestFrom')} `} />
                    <SpanTypography variant="body2" color={theme.palette.text.highlight}>
                        {companyName}
                    </SpanTypography>
                    <DialogTypography text={`. ${t('YouNeedToCompleteTest')} `} />
                </Box>

                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={theme.spacing(1.5)}>
                    <Button variant="outlined" onClick={cancel}>
                        {t('MaybeLater')}
                    </Button>
                    <Button variant="contained" onClick={ok}>
                        {t('TakeMeThere')}
                    </Button>
                </Stack>
            </Stack>
        </Dialog>
    );
}

export default CompleteTestDialog;
