import { createContext, useContext, useState } from 'react';

interface EducationListContextState {
    hasEducation: boolean;
    subformsInEditCounter: number;
}

type EducationListContextType = Omit<EducationListContextState, 'subformsInEditCounter'> & {
    editing: boolean;
    setHasEducation: (value: boolean) => void;
    incrementEditingSubformsCounter: () => void;
    decrementEditingSubformsCounter: () => void;
    resetEditingSubformsCounter: () => void;
};

const EducationListContext = createContext<EducationListContextType | undefined>(undefined);
export const EducationListContextProvider = EducationListContext.Provider;

export const useEducationListContextValue = (
    defaultHasEducation: boolean,
    defaultSubformsInEditCounter: number,
): EducationListContextType => {
    const [state, setState] = useState<EducationListContextState>({
        subformsInEditCounter: defaultSubformsInEditCounter,
        hasEducation: defaultHasEducation,
    });
    const { hasEducation, subformsInEditCounter } = state;

    const setHasEducation = (value: boolean) => {
        setState((prevState) => {
            return {
                ...prevState,
                hasEducation: value,
            };
        });
    };

    const incrementEditingSubformsCounter = () => {
        setState((prevState) => {
            return {
                ...prevState,
                subformsInEditCounter: prevState.subformsInEditCounter + 1,
            };
        });
    };

    const decrementEditingSubformsCounter = () => {
        setState((prevState) => {
            return {
                ...prevState,
                subformsInEditCounter: prevState.subformsInEditCounter - 1,
            };
        });
    };

    const resetEditingSubformsCounter = () => {
        setState((prevState) => {
            return {
                ...prevState,
                subformsInEditCounter: 0,
            };
        });
    };

    return {
        hasEducation,
        editing: subformsInEditCounter > 0,

        setHasEducation,
        incrementEditingSubformsCounter,
        decrementEditingSubformsCounter,
        resetEditingSubformsCounter,
    };
};

export function useEducationListContext() {
    const context = useContext(EducationListContext);
    if (!context) {
        throw new Error('useEducationListContext must be used within the EducationListContext.Provider');
    }
    return context;
}
