import React from 'react';
import { Button, useTheme } from '@mui/material';
import { Dialog, Stack, Typography } from '@talentmesh/core';
import useTMTranslation from '../../../../../../Hooks/useTMTranslation';
import { useOnBoardingContext } from '../../../../../../Context/OnBoardingContext';
import ProcessingState from '../../../../../../Models/ProcessingState';

interface CvParsingErrorDialogProps {
    open: boolean;
}

function CvParsingErrorDialog({ open }: CvParsingErrorDialogProps): JSX.Element {
    const theme = useTheme();
    const { tCommon: t } = useTMTranslation();
    const { parseCvAsync, setCvParsingState, blobUrl } = useOnBoardingContext();

    const handleFillInManually = () => {
        setCvParsingState(ProcessingState.Success);
    };

    const handleReTryParseWithAiAsync = async () => {
        await parseCvAsync(blobUrl);
    };

    return (
        <Dialog open={open}>
            <Stack direction="column" padding={theme.spacing(2.5)} spacing={theme.spacing(1.25)}>
                <Typography variant="h4" color={theme.palette.text.primary}>
                    {t('ErrorParsingCv')}
                </Typography>
                <Typography variant="body2" color={theme.palette.text.primary}>
                    {t('UnfortunatelyYourCvCouldNotBeParsed')}
                </Typography>
                <Stack direction="row" spacing={theme.spacing(1.25)}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleFillInManually}
                        size="large"
                        sx={{ flexGrow: 1 }}
                    >
                        {t('FillInManually')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleReTryParseWithAiAsync}
                        size="large"
                        sx={{ flexGrow: 1 }}
                    >
                        {t('ReTryParseWithAi')}
                    </Button>
                </Stack>
            </Stack>
        </Dialog>
    );
}

export default CvParsingErrorDialog;
