import VideoGrabberService from './video-grabber.service';

abstract class BitmapCaptureService extends VideoGrabberService {
    protected readonly video: HTMLVideoElement;

    constructor(media?: MediaStream) {
        super(media);
        this.video = document.createElement('video');
        this.video.autoplay = true;
    }

    public grab = async (): Promise<File | undefined> => {
        await this.initialize();

        if (this.media) {
            const bitmap = await createImageBitmap(this.video);
            const { width, height } = bitmap;

            this.canvas.width = width;
            this.canvas.height = height;

            this.canvas.getContext('2d')?.drawImage(this.video, 0, 0, width, height);

            return await this.readFile();
        }
        return undefined;
    };
}

export default BitmapCaptureService;
