import React from 'react';
import { Button, Stack } from '@talentmesh/core';
import useTMTranslation from '../../../../../../Hooks/useTMTranslation';

interface DialogButtonPanelProps {
    ok: () => void;
    cancel: () => void;
}

function DialogButtonPanel({ ok, cancel }: DialogButtonPanelProps): JSX.Element {
    const { tTest } = useTMTranslation();

    return (
        <Stack direction="row" justifyContent="space-around" spacing={1.5}>
            <Button data-cy="okButton" variant="outlined" onClick={cancel} fullWidth>
                {tTest('EndTest')}
            </Button>
            <Button variant="contained" onClick={ok} fullWidth>
                {tTest('ContinueTest')}
            </Button>
        </Stack>
    );
}

export default DialogButtonPanel;
