import { Grid } from '@talentmesh/core';
import React, { ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';

interface GridColumnProps {
    children: ReactNode;
    left?: boolean;
    right?: boolean;
}

const xlSmall = 4;
const xlLarge = 8;

const lgSmall = 2;
const lgLarge = 10;

function GridColumn({ children, left, right }: GridColumnProps): JSX.Element {
    const theme = useTheme();
    return (
        <Grid item xs={6}>
            {left && (
                <Grid container sx={{ height: '100%' }}>
                    <Grid item xl={xlSmall} lg={lgSmall} md={0} xs={0} />
                    <Grid item xl={xlLarge} lg={lgLarge} md={12} xs={12}>
                        {children}
                    </Grid>
                </Grid>
            )}
            {right && (
                <Grid container sx={{ height: '100%' }}>
                    <Grid
                        item
                        xl={xlLarge}
                        lg={lgLarge}
                        md={12}
                        xs={12}
                        sx={{ backgroundColor: theme.palette.common.white }}
                    >
                        {children}
                    </Grid>
                    <Grid
                        item
                        xl={xlSmall}
                        lg={lgSmall}
                        md={0}
                        xs={0}
                        sx={{ backgroundColor: theme.palette.common.white }}
                    />
                </Grid>
            )}
        </Grid>
    );
}

export default GridColumn;
