import { useTheme } from '@mui/material';
import React from 'react';
import { Stack, Typography } from '@talentmesh/core';
import { ImportantIcon } from '@talentmesh/icons';
import useTMTranslation from '../../../Hooks/useTMTranslation';

function CTMonitoringWarningDialogHeader(): JSX.Element {
    const theme = useTheme();
    const { tTest } = useTMTranslation();

    return (
        <Stack
            direction="column"
            spacing={theme.spacing(2)}
            alignItems="flex-start"
            sx={{
                padding: theme.spacing(2),
            }}
        >
            <ImportantIcon display="flex" />
            <Typography variant="h3" color={theme.palette.text.primary}>
                {tTest('CTMonitoringFeaturesWarningHeader')}
            </Typography>
        </Stack>
    );
}

export default CTMonitoringWarningDialogHeader;
