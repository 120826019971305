import ClientBase from './ClientBase';
import { CvSummaryDTO, DefaultCvSummaryDTO } from '../DTO/Assessments/CvSummaryDTO';
import { post, toJson } from '../Utils/HTTP/HttpUtils';

export default class AiClient extends ClientBase {
    async parseCvAsync(blobUrl: string): Promise<CvSummaryDTO> {
        const strategy = toJson({ blobUrl });
        const response = await post<CvSummaryDTO>({
            path: this.addHost(`ai/cv/parse`),
            accessToken: await this.getAccessTokenSilently(),
            ...strategy,
        });
        if (response.ok && response.body) {
            return response.body;
        }
        return DefaultCvSummaryDTO;
    }
}
