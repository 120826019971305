import { useField } from 'formik';
import React, { useState } from 'react';
import 'react-phone-input-2/lib/semantic-ui.css';
import PhoneInput from 'react-phone-input-2';
import { Label } from '@talentmesh/core';
import { PhoneBox } from './PhoneBox';
import { PhoneHelperText } from './PhoneHelperText';
import useTMTranslation from '../../../../../../../Hooks/useTMTranslation';

interface PhoneNumberFiledProps {
    name: string;
    label: string;
}

const PhoneNumberFiled = ({ label, name }: PhoneNumberFiledProps) => {
    const [field, meta, helpers] = useField(name);
    const [number, setNumber] = useState(field.value);
    const { tCommon: t } = useTMTranslation();
    const hasError = meta.touched && meta.error != null;

    return (
        <PhoneBox hasError={hasError}>
            <Label>{label}</Label>
            <PhoneInput
                searchPlaceholder={t('Search')}
                country="us"
                value={number}
                onChange={(phone) => {
                    helpers.setTouched(true);
                    setNumber(phone);
                    helpers.setValue(phone.length > 0 ? `+${phone}` : '');
                }}
                enableSearch
            />

            <PhoneHelperText errorMessage={hasError ? meta.error : undefined} />
        </PhoneBox>
    );
};
export default PhoneNumberFiled;
