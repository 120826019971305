import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography, Stack } from '@talentmesh/core';
import { FormikTextField } from '@talentmesh/forms';
import QuestionType from '../../../../Models/QuestionType';
import RadioAnswers from './RadioAnswers';
import { Answer } from '../../../../Models/Answer';
import useTMTranslation from '../../../../Hooks/useTMTranslation';

export interface TestAnswersProps {
    questionType: QuestionType;
    answers: Answer[];
}

function TestAnswers({ questionType, answers }: TestAnswersProps): JSX.Element {
    const theme = useTheme();
    const { tTest } = useTMTranslation();

    return (
        <Stack direction="column">
            <Typography variant="h4" color={theme.palette.primary.main}>
                {tTest('Answer')}
            </Typography>
            <Typography variant="h5">
                {questionType === QuestionType.Choice && tTest('ChooseAnswers')}
                {questionType === QuestionType.Exact && tTest('EnterAnswer')}
            </Typography>
            <Stack spacing={2} alignItems="flex-start">
                {questionType === QuestionType.Choice && <RadioAnswers name="answer" answers={answers} />}
                {questionType === QuestionType.Exact && <FormikTextField name="answer" />}
            </Stack>
        </Stack>
    );
}

export default TestAnswers;
