import React from 'react';
import { ErrorPage } from '@talentmesh/core';
import { resourceBaseUrl } from '../../AppSettings';
import NotFoundPageInfo from '../../Components/NotFoundPageInfo';
import useTMTranslation from '../../Hooks/useTMTranslation';

function NotFoundPageContainer(): JSX.Element {
    const { tCommon: t } = useTMTranslation();

    return (
        <ErrorPage
            imageUrl={`${resourceBaseUrl}/general/404.svg`}
            imageAlt={t('PageNotFound')}
            imageHeight="100%"
            imageWidth="100%"
        >
            <NotFoundPageInfo />
        </ErrorPage>
    );
}

export default NotFoundPageContainer;
