import React, { ReactNode } from 'react';
import { ThemeProvider, theme } from '@talentmesh/core';
import { CssBaseline, GlobalStyles, StyledEngineProvider } from '@mui/material';

interface ApplicationContextProviderProps {
    children: ReactNode;
}

function StyleContextProvider({ children }: ApplicationContextProviderProps): JSX.Element {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <GlobalStyles styles={{ body: { overflow: 'hidden' } }} />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default StyleContextProvider;
