import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@talentmesh/core';
import { Check } from '@talentmesh/icons';
import { getBorderByTestState } from './TestInfoUtil';
import { TestStates } from '../../../../../../../Models/TestInfo';

function TestInfoCheckIcon(): JSX.Element {
    const theme = useTheme();
    return (
        <Box
            sx={{
                position: 'absolute',
                top: theme.spacing(-0.75),
                right: theme.spacing(-0.625),
                width: theme.spacing(2.75),
                height: theme.spacing(2.75),
                border: getBorderByTestState(theme, TestStates.Completed),
                borderRadius: '50%',
            }}
        >
            <Check
                data-cy="TestInfoCheckIcon"
                sx={{
                    backgroundColor: theme.palette.success.dark,
                    borderRadius: '50%',
                    color: theme.palette.common.white,
                    width: theme.spacing(2.5),
                    height: theme.spacing(2.5),
                }}
            />
        </Box>
    );
}

export default TestInfoCheckIcon;
