import { VideoGrabber } from './video-grabber';
import ContentTypes from '../../Utils/ContentTypes';

abstract class VideoGrabberService implements VideoGrabber {
    protected readonly canvas: HTMLCanvasElement;

    protected media: MediaStream | undefined;

    constructor(media?: MediaStream) {
        this.canvas = document.createElement('canvas');
        if (media) {
            this.media = media;
        }
    }

    public abstract grab(): Promise<File | undefined>;

    protected abstract initialize(): Promise<void>;

    protected readFile = async (): Promise<File | undefined> => {
        let file: File | undefined;
        await new Promise<void>((resolve) => {
            this.canvas.toBlob((blob: Blob | null) => {
                if (blob) {
                    file = new File([blob], 'image.jpeg');
                }
                resolve();
            }, ContentTypes.ImageJpeg);
        });
        return file;
    };
}

export default VideoGrabberService;
