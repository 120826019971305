export type DateRange = { startDate: Date; endDate: Date };

export function getTotalJobExperience<T extends DateRange>(dates: T[]): string {
    let totalMonths = 0;
    dates.forEach(({ startDate, endDate }: T) => {
        totalMonths += monthDiff(startDate, endDate);
    });

    return monthsToString(totalMonths);
}

export function getPeriod(startDate: Date | null, endDate: Date | string): string {
    if (!startDate) {
        return '';
    }

    const totalMonths = monthDiff(startDate, endDate instanceof Date ? endDate : new Date());

    const endDateString = endDate instanceof Date ? dateToString(endDate) : endDate;
    return `${dateToString(startDate)} - ${endDateString} (${monthsToString(totalMonths > 0 ? totalMonths : 1)})`;
}

function monthsToString(totalMonths: number): string {
    const months = totalMonths % 12;
    const years = (totalMonths - months) / 12;

    if (years > 0 || months > 0) {
        return `${getValueLabel(years, 'year')} ${getValueLabel(months, 'month')}`.trim();
    }

    return '0';
}

function getValueLabel(value: number, label: string): string {
    let result = value > 0 ? `${value} ${label}` : '';
    result = value > 1 ? `${result}s` : result;

    return result;
}

function monthDiff(d1: Date, d2: Date): number {
    let months = 0;

    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();

    return months <= 0 ? 0 : months;
}

function dateToString(date: Date): string {
    return date.toLocaleDateString('en-au', {
        month: 'long',
        year: 'numeric',
    });
}
