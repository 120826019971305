import { styled } from '@mui/material/styles';
import React from 'react';
import { AppTabs, TabsProps } from '@talentmesh/core';

const StyledAppTabs = styled(AppTabs)(({ theme }) => ({
    '.MuiTabs-flexContainerVertical': {
        gap: theme.spacing(1.25),
    },
    '.MuiTabs-indicator': {
        left: 1,
        right: 'unset',
        width: '10px',
        borderEndStartRadius: theme.shape.smallBorderRadius,
        borderStartStartRadius: theme.shape.smallBorderRadius,
    },
    '.MuiTab-root': {
        minHeight: 0,
        justifyContent: 'flex-start',
        borderRadius: theme.shape.smallBorderRadius,
        fontWeight: theme.typography.fontWeightRegular,
        '&.Mui-selected': {
            backgroundColor: theme.palette.info.light,
            color: theme.palette.primary.main,
        },
    },
}));

const VerticalNavTabs: React.FC<Omit<TabsProps, 'orientation'>> = (props) => (
    <StyledAppTabs orientation="vertical" {...props} />
);

export default VerticalNavTabs;
