import { useTheme } from '@mui/material';
import { IconButton, IconButtonProps } from '@talentmesh/core';
import React from 'react';

const CustomIconButton: React.FC<IconButtonProps> = (props) => {
    const theme = useTheme();
    return (
        <IconButton
            sx={{
                color: theme.palette.grey[500],
                width: theme.spacing(3),
                height: theme.spacing(3),
            }}
            {...props}
        />
    );
};

export default CustomIconButton;
