import { SaveStatsDTO } from '../DTO/Questions/SaveStatsDTO';
import { post, toJson } from '../Utils/HTTP/HttpUtils';
import ClientBase from './ClientBase';

export default class QuestionStatsClient extends ClientBase {
    async saveStatsAsync(stats: SaveStatsDTO): Promise<void> {
        const strategy = toJson(stats);
        await post({
            path: this.addHost('questionStats'),
            accessToken: await this.getAccessTokenSilently(),
            ...strategy,
        });
    }
}
