import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AutocompleteOption, emptyAutocompleteOption } from '@talentmesh/core';
import { TMTranslationGetter } from '../../Hooks/useTMTranslation';
import {
    DefaultEducationFormValues,
    EducationFormValues,
} from '../../Pages/MyAssessment/ProfileSettings/utils/ApplicantProfileUtils';
import autoMapping from '../../Utils/autoMapping';
import { CommonResourceKey } from '../../i18n/byNamespace/i18nCommonResourceKeys';
import { EducationFieldDTO } from './EducationFieldDTO';
import EducationLevelDTO from './EducationLevelDTO';
import EducationLevelOptionsGetter from '../../Components/ProfileFields/Models/EducationLevelOptions';

dayjs.extend(utc);

export interface EducationDTO {
    schoolName: string;
    educationLevel: EducationLevelDTO;
    educationField: EducationFieldDTO;
    startDate: Date;
    endDate: Date;
}

export const DefaultEducationDTO: EducationDTO = {
    educationField: {},
    educationLevel: {},
    endDate: new Date(),
    schoolName: '',
    startDate: new Date(),
};

export interface EducationParsedDTO {
    schoolName: string;
    educationLevel: EducationLevelDTO;
    educationField: EducationFieldDTO;
    startDate: Date | null;
    endDate: Date | null;
}

export const DefaultEducationParsedDTO: EducationParsedDTO = {
    schoolName: '',
    educationField: {},
    educationLevel: {},
    startDate: null,
    endDate: null,
};

const mapEducationFieldDTOToOption = (educationField: EducationFieldDTO): AutocompleteOption | string => {
    let option: AutocompleteOption = { ...emptyAutocompleteOption };

    const { id, userDefined } = educationField;
    if (id != null || userDefined != null) {
        option = {
            id: id?.toString(),
            label: userDefined || '',
        };
    }

    return option;
};

const mapEducationLevelDTOToOption = (
    educationLevel: EducationLevelDTO,
    t: TMTranslationGetter<CommonResourceKey>,
): AutocompleteOption | string => {
    let option: AutocompleteOption = { ...emptyAutocompleteOption };

    const { level, userDefined } = educationLevel;
    if (level != null || userDefined != null) {
        const levelLabel =
            level === 'UserDefined' ? userDefined : EducationLevelOptionsGetter(t).find((x) => x.id === level)?.label;

        option = {
            id: level,
            label: levelLabel || '',
        };
    }

    return option;
};

export function fromEducationParsedDTOsToEducationsFormValues(
    educations: EducationParsedDTO[],
    t: TMTranslationGetter<CommonResourceKey>,
): EducationFormValues[] {
    return educations.map((x) => {
        const education = autoMapping(x, DefaultEducationFormValues);
        education.startDate = education.startDate ? (dayjs(education.startDate) as unknown as Date) : null;
        education.endDate = education.endDate ? (dayjs(education.endDate) as unknown as Date) : null;
        education.educationLevel = mapEducationLevelDTOToOption(x.educationLevel, t);
        education.educationField = mapEducationFieldDTOToOption(x.educationField);
        return education;
    });
}
