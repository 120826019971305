import { useTheme } from '@mui/material';
import React from 'react';
import { Stack, SpanTypography } from '@talentmesh/core';
import { ImportantIcon } from '@talentmesh/icons';
import useTMTranslation from '../../../../../../Hooks/useTMTranslation';

function DialogHeader(): JSX.Element {
    const theme = useTheme();
    const { tTest } = useTMTranslation();

    return (
        <Stack direction="column" spacing={theme.spacing(2)}>
            <ImportantIcon />
            <SpanTypography variant="h3">{tTest('EndTestWithUnansweredQuestions')}</SpanTypography>
        </Stack>
    );
}

export default DialogHeader;
