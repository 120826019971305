/* eslint-disable @typescript-eslint/lines-between-class-members */
import RouteParam from './RouteParam';

export default class RoutePath {
    static readonly Home = '/';
    static readonly IdentityError = '/identity-error';
    static readonly Error = '/error';
    static readonly Version = '/version';
    static readonly NotFound = '/404';
    static readonly Forbidden = '/403';

    static readonly Execute = '/execute';

    static readonly Assessments = '/assessments';
    static readonly AssessmentId = `${RoutePath.Assessments}/:${RouteParam.AssessmentId}`;
    static readonly AssessmentWelcome = `${RoutePath.AssessmentId}/welcome`;
    static readonly AssessmentCTMonitoringPage = `${RoutePath.AssessmentId}/monitoring`;

    static readonly AssessmentOnboard = `${RoutePath.AssessmentId}/onboard`;
    static readonly AssessmentTest = `${RoutePath.AssessmentId}/tests/:${RouteParam.TestId}`;
    static readonly RecruitmentClosedPage = `${RoutePath.Assessments}/closed`;

    static readonly FullExperience = '/full-experience';

    static readonly ProfileSettings = '/profile-settings';
}
