import { useTheme } from '@mui/material';
import React from 'react';
import { Stack, StatusChip } from '@talentmesh/core';
import DialogBodyText from './DialogBodyText';
import WarningLabel from '../../../../../../Components/WarningLabel';
import useTMTranslation from '../../../../../../Hooks/useTMTranslation';

interface DialogBodyProps {
    timeLeft: string;
    unansweredQuestionsCount: number;
}

function DialogBody({ timeLeft, unansweredQuestionsCount }: DialogBodyProps): JSX.Element {
    const theme = useTheme();
    const { tTest } = useTMTranslation();

    return (
        <Stack
            direction="column"
            spacing={theme.spacing(2)}
            sx={{
                border: `1px solid ${theme.palette.border.main}`,
                borderRadius: theme.shape.mediumBorderRadius,
                padding: theme.spacing(2),
            }}
        >
            <StatusChip
                status="warning"
                label={<WarningLabel text={tTest('HangOn')} />}
                sx={{
                    display: 'flex',
                    width: theme.spacing(18),
                }}
            />
            <DialogBodyText timeLeft={timeLeft} unansweredQuestionsCount={unansweredQuestionsCount} />
        </Stack>
    );
}

export default DialogBody;
