import React from 'react';
import { Box, CompanyLogo, Stack, SpanTypography, Typography } from '@talentmesh/core';
import { useTheme } from '@mui/material';
import GoToDesktop from './GoToDesktop.svg';
import useTMTranslation from '../../Hooks/useTMTranslation';

function GetFullExperiencePage(): JSX.Element {
    const theme = useTheme();
    const { tCommon: t } = useTMTranslation();

    return (
        <Box sx={{ height: '100vh', overflowY: 'auto' }}>
            <Stack
                spacing={4}
                flexDirection="column"
                justifyContent="center"
                alignItems="start"
                sx={{ paddingBlock: theme.spacing(4), paddingInline: theme.spacing(2.5) }}
            >
                <CompanyLogo />
                <Box sx={{ textAlign: 'left' }}>
                    <SpanTypography variant="h2" color={theme.palette.text.menuTitle}>
                        {`${t('StartYour')} `}
                        <SpanTypography variant="h2" color={theme.palette.primary.main}>
                            {t('TalentMeshAssessment')}
                        </SpanTypography>
                        {` ${t('OnYourDesktopBrowser')}`}
                    </SpanTypography>
                </Box>
                <Box>
                    <Typography sx={{ textAlign: 'left' }}>{t('WeWorkHard')}</Typography>
                    <Box mt={1} sx={{ textAlign: 'left' }}>
                        <SpanTypography>{t('TillThen')}</SpanTypography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                    <object type="image/svg+xml" data={GoToDesktop} aria-label={t('GoToDesktop')} />
                </Box>
            </Stack>
        </Box>
    );
}

export default GetFullExperiencePage;
