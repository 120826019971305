import ClientBase from './ClientBase';
import { post } from '../Utils/HTTP/HttpUtils';

export default class ApplicantAssessmentsClient extends ClientBase {
    async saveApplicantAssessmentLocaleAsync(recruitmentId: string, locale: string): Promise<void> {
        await post({
            path: this.addHost(`applicant-assessments/${recruitmentId}/locale/${locale}`),
            accessToken: await this.getAccessTokenSilently(),
        });
    }
}
