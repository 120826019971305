import QuestionType from '../../Models/QuestionType';
import { AnswerDTO } from './AnswerDTO';

export interface QuestionDTO {
    id: string;
    questionText: string;
    answers: AnswerDTO[];
    questionType: QuestionType;
    blobFileURI: string;
    fileMimeType: string;
    topic: string;
    subTopic: string;
    likertAnswerGroupId: string;
}

export const DefaultQuestionDTO: QuestionDTO = {
    id: '',
    questionText: '',
    answers: [],
    questionType: QuestionType.Exact,
    blobFileURI: '',
    fileMimeType: '',
    topic: '',
    subTopic: '',
    likertAnswerGroupId: '',
};
