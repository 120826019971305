import { useTheme } from '@mui/material';
import React from 'react';
import { Button, Stack } from '@talentmesh/core';
import useTMTranslation from '../../../Hooks/useTMTranslation';

interface CTMonitoringWarningDialogActionsProps {
    onCancel: () => void;
    onConfirm: () => void;
}

function CTMonitoringWarningDialogActions({ onCancel, onConfirm }: CTMonitoringWarningDialogActionsProps): JSX.Element {
    const theme = useTheme();
    const { tTest } = useTMTranslation();

    return (
        <Stack direction="row" justifyContent="space-around" margin={theme.spacing(2)}>
            <Button variant="outlined" onClick={onConfirm}>
                {tTest('StartTestWithoutEnabling')}
            </Button>
            <Button variant="contained" onClick={onCancel}>
                {tTest('EnableTestMonitoring')}
            </Button>
        </Stack>
    );
}

export default CTMonitoringWarningDialogActions;
