/* eslint-disable jsx-a11y/media-has-caption */
import { SelectChangeEvent } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Dropdown, Stack, Typography } from '@talentmesh/core';
import { CTMonitoringPanelFormValues } from './CTMonitoringTogglesForm';
import useTMTranslation from '../../../Hooks/useTMTranslation';

const DefaultMediaDeviceInfo: MediaDeviceInfo = {
    deviceId: '',
    groupId: '',
    kind: 'videoinput',
    label: 'No camera found',
    toJSON(): any {},
};

interface VideoPreviewPanelState {
    devices: Array<MediaDeviceInfo>;
    stream: MediaStream | null;
    selectedDevice: MediaDeviceInfo;
    hasVideo: boolean;
}

const defaultVideoPreviewPanelState: VideoPreviewPanelState = {
    devices: [],
    stream: null,
    selectedDevice: DefaultMediaDeviceInfo,
    hasVideo: false,
};

function VideoPreviewPanel(): JSX.Element | null {
    const videoRef = useRef<HTMLVideoElement>(null);
    const { values } = useFormikContext<CTMonitoringPanelFormValues>();
    const [state, setState] = useState<VideoPreviewPanelState>(defaultVideoPreviewPanelState);
    const theme = useTheme();
    const width = theme.spacing(71.13);
    const height = theme.spacing(40);
    const { tTest } = useTMTranslation();

    useEffect(() => {
        if (values.isWebcamEnabled) {
            const doGetDevices = async () => {
                try {
                    const media = await navigator.mediaDevices.getUserMedia({ video: true });
                    setState((prev) => {
                        return {
                            ...prev,
                            stream: media,
                        };
                    });
                    // eslint-disable-next-line no-empty
                } catch (e) {}
                const media = await navigator.mediaDevices.enumerateDevices();
                const videos = media.filter((item) => item.kind === 'videoinput');
                if (videos.length > 0) {
                    const videoStream = await navigator.mediaDevices.getUserMedia({
                        video: {
                            deviceId: {
                                exact: videos[0].deviceId,
                            },
                        },
                    });
                    setState((prev) => {
                        return {
                            ...prev,
                            stream: videoStream,
                            devices: videos,
                            hasVideo: true,
                            selectedDevice: videos[0],
                        };
                    });
                } else {
                    setState((prev) => {
                        return {
                            ...prev,
                            devices: [DefaultMediaDeviceInfo],
                            hasVideo: false,
                        };
                    });
                }
            };

            doGetDevices();
        }
    }, [values.isWebcamEnabled]);

    useEffect(() => {
        if (videoRef && videoRef.current) {
            if (values.isWebcamEnabled && state.stream) {
                videoRef.current.srcObject = state.stream;
            } else {
                videoRef.current.pause();
                videoRef.current.srcObject = null;
            }
        }
    }, [videoRef, state.stream, values.isWebcamEnabled]);

    if (!values.isWebcamEnabled) {
        return null;
    }

    const onChangeHandler = async (event: SelectChangeEvent<unknown>) => {
        const { value } = event.target;
        const { devices } = state;
        const deviceIndex = devices.findIndex((item) => item.label === value);
        const { deviceId } = devices[deviceIndex];
        const cameraStream = await navigator.mediaDevices.getUserMedia({
            video: {
                deviceId: {
                    exact: deviceId,
                },
            },
        });
        setState((prev) => {
            return {
                ...prev,
                stream: cameraStream,
                selectedDevice: devices[deviceIndex],
            };
        });
    };

    return (
        <Stack direction="column" spacing={theme.spacing(2.5)} alignItems="center">
            <Dropdown
                values={state.devices.map((item) => item.label)}
                value={state.selectedDevice?.label || ''}
                onChange={onChangeHandler}
                MenuProps={{ transformOrigin: { horizontal: 'center', vertical: 'top' } }}
            />
            {!state.hasVideo && (
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        backgroundColor: theme.palette.common.black,
                        width,
                        height,
                    }}
                >
                    <Typography sx={{ color: theme.palette.common.white }}>{tTest('NoWebcamFound')}</Typography>
                </Stack>
            )}
            {state.hasVideo && <video autoPlay ref={videoRef} width={width} height={height} />}
        </Stack>
    );
}

export default VideoPreviewPanel;
