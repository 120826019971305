import React from 'react';
import { TMTooltip, IconButton } from '@talentmesh/core';
import { InfoIcon } from '@talentmesh/icons';
import TestPurposeTooltipContent from './TestPurposeTooltipContent';

function TestPurposeTooltip() {
    return (
        <TMTooltip title={<TestPurposeTooltipContent />}>
            <IconButton>
                <InfoIcon color="secondary" />
            </IconButton>
        </TMTooltip>
    );
}

export default TestPurposeTooltip;
