import { IconButton, useTheme } from '@mui/material';
import { DeleteOutlineIcon } from '@talentmesh/icons';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Stack } from '@talentmesh/core';
import { useOnBoardingContext } from '../../../../../../../Context/OnBoardingContext';
import { ApplicantProfileFormValues } from '../../../../../../MyAssessment/ProfileSettings/utils/ApplicantProfileUtils';
import CvFileMetadata from './CvFileMetadata';
import { getCvFileIcon } from './CvUtils';

const CvFilePreview = () => {
    const theme = useTheme();
    const form = useFormContext<ApplicantProfileFormValues>();
    const { isUploadInProgress, currentFilename, fileSize, deleteCv } = useOnBoardingContext();

    const fileIcon = useMemo(() => getCvFileIcon(currentFilename), [currentFilename]);

    const deleteCvWrapper = async () => {
        form.setValue('cvFile', '', { shouldValidate: true });
        await deleteCv();
    };

    return (
        <Stack
            direction="row"
            spacing={theme.spacing(1)}
            sx={{
                color: theme.palette.border.main,
                border: '1px',
                borderStyle: 'solid',
                borderRadius: theme.shape.smallBorderRadius,
                py: theme.spacing(1),
                px: theme.spacing(2.5),
            }}
        >
            {fileIcon}
            <Stack
                direction="column"
                spacing={theme.spacing(1)}
                sx={{
                    width: '100%',
                    height: '100%',
                    alignSelf: 'center',
                }}
            >
                <Stack direction="row" alignItems="center" sx={{ flex: 1 }}>
                    <CvFileMetadata
                        fileSize={fileSize}
                        isUploadInProgress={isUploadInProgress}
                        currentFilename={currentFilename}
                    />
                    {currentFilename && !isUploadInProgress && (
                        <IconButton onClick={deleteCvWrapper}>
                            <DeleteOutlineIcon data-cy="DeleteCvFile" />
                        </IconButton>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};

export default CvFilePreview;
