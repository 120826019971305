import { keyframes, Theme } from '@mui/material/styles';

export type Keyframe = ReturnType<typeof keyframes>;

export const customEaseCurve = 'cubic-bezier(0.7, -0.4, 0.4, 1.4)';

export const growAssessmentSection = (theme: Theme): Keyframe => keyframes`
    0% {
        height: 0;
    }

    100% {
        height: ${theme.spacing(26.85)};
    }
`;

export const shrinkAssessmentSection = (theme: Theme): Keyframe => keyframes`
    0% {
        height: ${theme.spacing(17.85)};
    }

    100% {
        height: ${theme.spacing(11.25)};
    }
`;

export const nullAnimation = keyframes``;

export const shrinkTestInfoCard = (theme: Theme): Keyframe => keyframes`
    0% {
        height: ${theme.spacing(12.5)};
    }

    100% {
        height: ${theme.spacing(5.75)};
    }
`;
