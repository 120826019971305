import * as yup from 'yup';
import { OptionalObjectSchema } from 'yup/lib/object';
import { TMTranslationGetter } from '../../Hooks/useTMTranslation';
import { CommonResourceKey } from '../../i18n/byNamespace/i18nCommonResourceKeys';
import { ApplicantProfileFormValues } from '../../Pages/MyAssessment/ProfileSettings/utils/ApplicantProfileUtils';
import ValidationErrors from '../../Utils/ValidationErrors';

const maxLength = 128;
const startDateMinValue = new Date(1900, 0, 1);
const autocompleteValidationSchema = (t: TMTranslationGetter<CommonResourceKey>) =>
    yup.object({
        id: yup.string().nullable(),
        label: yup
            .string()
            .max(maxLength, ValidationErrors(t).FieldShouldNotExceed(maxLength))
            .required(ValidationErrors(t).Required),
    });

const currentDate = new Date();

const dateValidationSchema = (t: TMTranslationGetter<CommonResourceKey>) =>
    yup.date().required(ValidationErrors(t).Required).typeError(ValidationErrors(t).PleaseEnterAValidDate);

const startDateValidationSchema = (t: TMTranslationGetter<CommonResourceKey>) =>
    dateValidationSchema(t)
        .max(currentDate, ValidationErrors(t).StartDateCannotBeInTheFuture)
        .min(startDateMinValue, ValidationErrors(t).StartDateCannotGoBeyond(startDateMinValue));

const endDateValidationSchema = (t: TMTranslationGetter<CommonResourceKey>) =>
    yup.date().when('startDate', {
        is: (startDate: Date) => startDate !== null && startDate !== undefined,
        then: dateValidationSchema(t)
            .max(currentDate, ValidationErrors(t).EndDateCannotBeInTheFuture)
            .min(yup.ref('startDate'), `${t('EndDate')} ${t('CannotBeBefore')} ${t('StartDate')}`),
        otherwise: yup.date().notRequired().nullable(),
    });

type ProfileSettingsSchemaGetterType = (
    t: TMTranslationGetter<CommonResourceKey>,
    withoutCv?: boolean,
) => OptionalObjectSchema<Record<keyof ApplicantProfileFormValues, yup.AnySchema<any, any, any>>, Record<string, any>>;

const profileSettingsSchema: ProfileSettingsSchemaGetterType = (t, withoutCv) =>
    yup.object({
        firstName: yup
            .string()
            .nullable()
            .max(maxLength, ValidationErrors(t).FirstNameExceedsLimit)
            .required(ValidationErrors(t).Required),
        lastName: yup
            .string()
            .nullable()
            .max(maxLength, ValidationErrors(t).LastNameExceedsLimit)
            .required(ValidationErrors(t).Required),
        email: yup.string().required(ValidationErrors(t).Required),
        location: yup.object().default(null).nullable().required(ValidationErrors(t).Required),
        isWillingToRelocate: yup.boolean().required(ValidationErrors(t).Required),

        cvFile: withoutCv ? yup.string() : yup.string().required(ValidationErrors(t).Required),

        experiences: yup
            .array()
            .of(
                yup.object({
                    title: yup
                        .string()
                        .max(maxLength, ValidationErrors(t).TitleExceedsLimit)
                        .required(ValidationErrors(t).Required),
                    jobExperience: autocompleteValidationSchema(t).nullable().required(ValidationErrors(t).Required),
                    company: yup
                        .string()
                        .max(maxLength, ValidationErrors(t).CompanyExceedsLimit)
                        .required(ValidationErrors(t).Required),
                    workingHere: yup.boolean(),
                    startDate: startDateValidationSchema(t),
                    endDate: yup.date().when('workingHere', {
                        is: true,
                        then: yup.date().notRequired().nullable().typeError(''),
                        otherwise: endDateValidationSchema(t),
                    }),
                }),
            )
            .required(),

        educations: yup
            .array()
            .of(
                yup.object({
                    schoolName: yup
                        .string()
                        .max(maxLength, ValidationErrors(t).SchoolNameExceedsLimit)
                        .required(ValidationErrors(t).Required),
                    educationLevel: autocompleteValidationSchema(t).nullable().required(ValidationErrors(t).Required),
                    educationField: autocompleteValidationSchema(t).nullable().required(ValidationErrors(t).Required),
                    startDate: startDateValidationSchema(t),
                    endDate: endDateValidationSchema(t),
                }),
            )
            .required(),
    });

export default profileSettingsSchema;
