import { useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Stack } from '@talentmesh/core';
import { ArrowBackIcon, ArrowForwardIcon } from '@talentmesh/icons';
import { getFieldValue } from '@talentmesh/forms';
import { Question } from '../../../../Models/Question';
import { useTestContext } from '../../Contexts/TestContext';
import { StepStatuses, useStepperSkillsContext } from '../Contexts/StepperSkillsContext';
import SubmitUnansweredDialog from './Dialogs/SubmitUnanswered/SubmitUnansweredDialog';
import SubmitDialog from './Dialogs/SubmitDialog';
import Constants from '../../../../Models/Constants';
import TimesUpDialog from './Dialogs/TimesUpDialog';
import useTMTranslation from '../../../../Hooks/useTMTranslation';
import ProcessingState from '../../../../Models/ProcessingState';
import Prompt from '../../../../Components/Prompt';

interface SkillsButtonPanelProps {
    question: Question;
}

function SkillsButtonPanel({ question }: SkillsButtonPanelProps): JSX.Element {
    const theme = useTheme();
    const {
        saveAnswerAsync,
        saveStatsAsync,
        test,
        elapsedTime,
        unansweredQuestionsCount,
        completeTestAsync,
        redirectAfterTestEnd,
        setProcessingState,
    } = useTestContext();
    const { setActiveStep, contextState } = useStepperSkillsContext();
    const { activeIndex } = contextState;
    const { questions, candidateAnswers } = test;
    const formik = useFormikContext<string>();
    const [submitDialogOpen, setSubmitDialogOpen] = useState<boolean>(false);
    const [submitUnansweredDialogOpen, setSubmitUnansweredDialogOpen] = useState<boolean>(false);
    const [timesUpDialogOpen, setTimesUpDialogOpen] = useState<boolean>(false);
    const [timeIsUp, setTimeIsUp] = useState<boolean>(false);
    const { tTest } = useTMTranslation();

    const answerFieldName = 'answer';

    const isAnswered = () => {
        return getFieldValue(formik, answerFieldName) !== '';
    };

    const answerQuestionAsync = async () => {
        if (isAnswered()) {
            const answer = getFieldValue(formik, answerFieldName);
            await saveAnswerAsync(question.id, question.questionType, answer);
        } else {
            await saveStatsAsync(question.id, question.questionType);
        }
    };

    const saveAnswerAndChangeQuestionAsync = async (index: number) => {
        try {
            await answerQuestionAsync();
            setActiveStep(index, isAnswered() ? StepStatuses.Answered : StepStatuses.Visited);
        } catch {
            setProcessingState(ProcessingState.Error);
        }
    };

    const nextHandler = async () => {
        let index = activeIndex;
        if (activeIndex + 1 < questions.length) {
            index += 1;
        }
        await saveAnswerAndChangeQuestionAsync(index);
    };

    const previousHandler = async () => {
        let index = activeIndex;
        if (activeIndex > 0) {
            index -= 1;
        }
        await saveAnswerAndChangeQuestionAsync(index);
    };

    useEffect(() => {
        if (elapsedTime === Constants.TimeOut) {
            setTimeIsUp(true);
            setTimesUpDialogOpen(true);
        }
    }, [elapsedTime]);

    const submitTestHandler = async () => {
        await saveAnswerAndChangeQuestionAsync(activeIndex);
        if (unansweredQuestionsCount() > 0) {
            setSubmitUnansweredDialogOpen(true);
            return;
        }

        setSubmitDialogOpen(true);
    };

    const showSubmit = (): boolean => {
        return (
            // last question is active
            activeIndex + 1 === questions.length &&
            // questions were either answered or visited
            candidateAnswers.size + unansweredQuestionsCount() === questions.length
        );
    };

    return (
        <>
            <Stack
                direction="row"
                sx={{
                    marginTop: theme.spacing(3),
                }}
                spacing={theme.spacing(4)}
            >
                <div style={{ flexGrow: 1 }}>
                    <Button
                        variant="text"
                        startIcon={<ArrowBackIcon />}
                        onClick={previousHandler}
                        disabled={activeIndex === 0}
                    >
                        {tTest('Previous')}
                    </Button>
                </div>
                {showSubmit() ? (
                    <Button size="large" variant="contained" onClick={submitTestHandler}>
                        {tTest('EndTest')}
                    </Button>
                ) : (
                    <Button size="large" variant="contained" endIcon={<ArrowForwardIcon />} onClick={nextHandler}>
                        {tTest('Next')}
                    </Button>
                )}
                <SubmitDialog
                    open={submitDialogOpen}
                    ok={async () => {
                        setSubmitDialogOpen(false);
                        await completeTestAsync();
                        redirectAfterTestEnd();
                    }}
                    cancel={() => setSubmitDialogOpen(false)}
                />
                <SubmitUnansweredDialog
                    open={submitUnansweredDialogOpen}
                    ok={() => setSubmitUnansweredDialogOpen(false)}
                    cancel={async () => {
                        setSubmitUnansweredDialogOpen(false);
                        await completeTestAsync();
                        redirectAfterTestEnd();
                    }}
                />
                <TimesUpDialog
                    open={timesUpDialogOpen}
                    ok={() => {
                        setTimesUpDialogOpen(false);
                        redirectAfterTestEnd();
                    }}
                    actionAsync={completeTestAsync}
                />
            </Stack>
            <Prompt message={tTest('SkillsExit')} when={!showSubmit() && !timeIsUp} />
        </>
    );
}

export default SkillsButtonPanel;
