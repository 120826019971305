import { useTheme } from '@mui/material';
import { Button } from '@talentmesh/core';
import { AddIcon } from '@talentmesh/icons';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
    DefaultExperienceFormValues,
    ExperienceFormValues,
} from '../../../MyAssessment/ProfileSettings/utils/ApplicantProfileUtils';
import ProfilePaper from '../Common/ProfilePaper';
import { JobExperience } from './JobExperience';
import { JobExperienceListContextProvider, useJobExperienceListContextValue } from './JobExperienceListContext';
import JobExperiencesFieldsNames from './JobExperiencesFieldsNames';
import JobExperienceListHeader from './JobExperienceListHeader';
import useTMTranslation from '../../../../Hooks/useTMTranslation';
import isExperienceEditRequired from './isExperienceEditRequired';

const name = JobExperiencesFieldsNames.ExperiencesName;

interface JobExperienceListProps {
    defaultHasExperience: boolean;
    defaultIncompleteExperienceCount: number;
}

function JobExperienceList({
    defaultHasExperience,
    defaultIncompleteExperienceCount,
}: JobExperienceListProps): JSX.Element {
    const theme = useTheme();
    const { tCommon: t } = useTMTranslation();

    const { control } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        control,
        name,
    });

    const contextValue = useJobExperienceListContextValue(defaultHasExperience, defaultIncompleteExperienceCount);
    const { hasExperience, editing, resetEditingSubformsCounter, incrementEditingSubformsCounter, setHasExperience } =
        contextValue;

    const handleToggleJobExperience = (value: boolean) => {
        setHasExperience(value);

        if (value && !fields.length) {
            append(DefaultExperienceFormValues);
            incrementEditingSubformsCounter();
        }

        if (!value) {
            resetEditingSubformsCounter();
            remove();
        }
    };

    return (
        <JobExperienceListContextProvider value={contextValue}>
            <ProfilePaper header={t('JobExperiences')}>
                <JobExperienceListHeader onChange={handleToggleJobExperience} />
                {hasExperience && (
                    <>
                        {fields.map((field, index) => {
                            return (
                                <JobExperience
                                    key={field.id}
                                    initialIsEdit={isExperienceEditRequired(field as any as ExperienceFormValues)}
                                    index={index}
                                    onRemove={() => {
                                        remove(index);
                                    }}
                                    onCancel={() => {
                                        remove(index);
                                    }}
                                />
                            );
                        })}
                        <Button
                            startIcon={<AddIcon />}
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={() => {
                                append({
                                    ...DefaultExperienceFormValues,
                                });
                                incrementEditingSubformsCounter();
                            }}
                            disabled={editing}
                            sx={{
                                backgroundColor: theme.palette.common.white,
                                '&:disabled': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                        >
                            {t('AddAdditionalJobExperience')}
                        </Button>
                    </>
                )}
            </ProfilePaper>
        </JobExperienceListContextProvider>
    );
}

export default JobExperienceList;
