import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { LogoutOptions, useAuth0 } from '@auth0/auth0-react';
import Candidate, { DefaultCandidate } from '../Models/Candidate';
import { useUsersClient } from '../Hooks/ClientHooks';
import { authProviderSettings } from '../AppSettings';
import { ApplicantProfile } from '../Models/ApplicantProfile';

type UserContextType = {
    user: Candidate;
    isLoading: boolean;
    loadUserAsync: () => Promise<void>;
    refreshUserAsync: () => Promise<void>;
    upsertUserAsync: (model: ApplicantProfile) => Promise<void>;
    error?: Error;
    logOut: () => void;
};

export const UserContext = createContext<UserContextType | undefined>(undefined);

const UserContextProvider = UserContext.Provider;

export interface UserProviderProps {
    children: ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps): JSX.Element => {
    const [talentMeshUser, setTalentMeshUser] = useState<Candidate>(DefaultCandidate);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | undefined>(undefined);
    const { user, logout, isAuthenticated } = useAuth0();
    const client = useUsersClient();

    const logOut = () => {
        const logoutOptions: LogoutOptions = {
            client_id: authProviderSettings.clientId,
            returnTo: `${window.location.origin}`,
        };
        logout(logoutOptions);
    };

    const loadUserAsync = async () => {
        try {
            setIsLoading(true);

            const response = await client.getUser();
            setTalentMeshUser(response);
        } catch (exception) {
            setError(exception as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const refreshUserAsync = async () => {
        try {
            const response = await client.getUser();
            setTalentMeshUser(response);
        } catch (exception) {
            setError(exception as Error);
        }
    };

    const upsertUserAsync = async (model: ApplicantProfile) => {
        try {
            const response = await client.upsertUser(model);
            setTalentMeshUser(response);
        } catch (exception) {
            setError(exception as Error);
        }
    };

    useEffect(() => {
        if (isAuthenticated && user) {
            const get = async () => await loadUserAsync();
            get();
        } else {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <UserContextProvider
            value={{
                user: talentMeshUser,
                isLoading,
                error,
                logOut,
                loadUserAsync,
                refreshUserAsync,
                upsertUserAsync,
            }}
        >
            {children}
        </UserContextProvider>
    );
};

export function useUserContext() {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUserContext must be used within the UserContext.Provider');
    }
    return context;
}
