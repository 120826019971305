export interface CandidateAssessmentInfo {
    assessmentId: string;
    activeTestId?: string | null;
    companyName: string;
    jobFunctionName: string;
    jobCategoryName: string;
    jobFunctionId: number;
}

export const DefaultCandidateAssessmentInfo: CandidateAssessmentInfo = {
    assessmentId: '',
    companyName: '',
    jobCategoryName: '',
    jobFunctionName: '',
    jobFunctionId: 0,
};
