import { AnswerDTO } from '../DTO/Questions/AnswerDTO';

export interface Answer {
    answerId: string;
    answerText: string;
}
export function mapFromAnswerDTO(dto: AnswerDTO): Answer {
    return {
        answerId: dto.answerId,
        answerText: dto.answerText,
    };
}
