import { useTheme } from '@mui/material/styles';
import React, { Fragment, useEffect, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { Container, LoadingButton, Chip, Collapse, Grid, LoadingTick, Typography, Stack } from '@talentmesh/core';
// @ts-ignore
import iScroll from 'iscroll';
import { TestTypes } from '../../../../Models/Configuration';
import ProcessingState from '../../../../Models/ProcessingState';
import { DefaultQuestion, Question } from '../../../../Models/Question';
import QuestionType from '../../../../Models/QuestionType';
import { useTestContext } from '../../Contexts/TestContext';
import { useStepperPersonalityContext } from '../Contexts/StepperPersonalityContext';
import { Column } from '../Models/Column';
import HeaderPaper from './HeaderPaper';
import RowPaper from './RowPaper';
import ReactIScroll from './iscroll/react-iscroll';
import { isMouse, isTouchpad } from './utils';
import useTMTranslation from '../../../../Hooks/useTMTranslation';
import Prompt from '../../../../Components/Prompt';

const visibleQuestionsCount = 4;
const emptyString = '';
const completeTestId = -1;
const wheelCountMagicNumber = 10;

const getColorByScore = (score: number): string | undefined => {
    switch (score) {
        case -2:
            return 'info';
        case -1:
            return 'success';
        case 1:
            return 'warning';
        case 2:
            return 'error';
        default:
            return undefined;
    }
};

function PersonalityQuestionsList(): JSX.Element {
    const theme = useTheme();
    const { tTest } = useTMTranslation();

    const {
        test,
        likert,
        likertGroups,
        saveAnswerAsync,
        completeTestAsync,
        redirectAfterTestEnd,
        processingState,
        setProcessingState,
        resetStartTime,
    } = useTestContext();
    const { handleNext, handleBack, handleComplete, initialize, contextState } = useStepperPersonalityContext();
    const { activeIndex } = contextState;

    const [columns, setColumns] = useState<Column[]>([]);
    const [visibleQuestions, setVisibleQuestions] = useState<number[]>([]);
    const [selectedValues, setSelectedValues] = useState<Map<string, string>>(new Map<string, string>());
    const [canSubmit, setCanSubmit] = useState(true);

    const [disableButton, setDisableButtonState] = useState(false);
    const [wheelCount, setWheelCount] = useState(0);

    const pageWidth = theme.spacing(180); // 1440px

    // TODO: ENABLE WHEN IT NEEDED
    // const appInsights = useAppInsightsContext();
    // const trackWheel = useTrackEvent(appInsights, 'Wheel', {});

    useEffect(() => {
        resetStartTime();
    }, [activeIndex]);

    useEffect(() => {
        const lastQuestion = test.questions[test.questions.length - 1];
        const lastQuestionAnswered = selectedValues.has(lastQuestion.id) && selectedValues.get(lastQuestion.id) !== '';
        setCanSubmit(selectedValues.size === test.questions.length && lastQuestionAnswered);
    }, [selectedValues]);

    const [loadingQuestionId, setLoadingQuestionId] = useState<string>();
    const [loadingAnswerId, setLoadingAnswerId] = useState<string>();

    useEffect(() => {
        const c: Column[] = [];
        likert.answers
            .sort((a, b) => (a.reverseScore > b.reverseScore ? 1 : -1))
            .forEach((a) => {
                c.push({
                    id: a.id,
                    label: a.answerText,
                    color: getColorByScore(a.reverseScore),
                });
            });

        setColumns([{ id: 'left' }, { id: 'question' }, ...c, { id: 'right' }]);
    }, [likert]);

    useEffect(() => {
        initialize(test.questions.length, test.lastAnsweredQuestionIndex);

        const nextIndex = test.lastAnsweredQuestionIndex >= 0 ? test.lastAnsweredQuestionIndex + 1 : 0;

        const arr = Array.from(Array(visibleQuestionsCount).keys())
            .map((i) => nextIndex + i)
            .filter((x) => x < test.questions.length);
        if (arr.length === 0) {
            arr.push(nextIndex - 1);
        }
        if (arr.length < visibleQuestionsCount) {
            arr.push(completeTestId);
        }
        setVisibleQuestions(arr);

        setSelectedValues(() => {
            const nextState = new Map(test.candidateAnswers);

            if (nextIndex < test.questions.length) {
                const { id } = test.questions[nextIndex];
                if (!nextState.has(id)) {
                    // add empty answer id
                    nextState.set(id, emptyString);
                }
            }
            return nextState;
        });
    }, [test]);

    const isDisabled = (questionId: string): boolean => {
        return !selectedValues.has(questionId);
    };

    const scrollDown = (force: boolean = false) => {
        const indexB = visibleQuestions[visibleQuestionsCount - 1];
        const lastQuestion = test.questions.length - 1;
        if (
            indexB >= 0 &&
            indexB < test.questions.length - 1 &&
            (force || selectedValues.has(test.questions[visibleQuestions[1]].id))
        ) {
            setVisibleQuestions((prev) => [...prev.filter((i) => i !== visibleQuestions[0]), indexB + 1]);

            handleNext();
        } else if (visibleQuestions.indexOf(lastQuestion) > 0) {
            setVisibleQuestions((prev) => [...prev.filter((i) => i !== visibleQuestions[0]), completeTestId]);

            handleNext();
        }
    };

    const scrollUp = () => {
        const indexA = visibleQuestions[0];
        const lastQuestion = test.questions.length - 1;

        if (indexA > 0) {
            setVisibleQuestions((prev) => {
                const next = [indexA - 1, ...prev.filter((i) => i !== prev[visibleQuestionsCount - 1])];

                const index = next.indexOf(lastQuestion);

                if (index >= 0 && index < visibleQuestionsCount - 1) {
                    next.push(completeTestId);
                }

                return next;
            });

            handleBack();
        }
    };

    const clearLoadingState = () => {
        setLoadingQuestionId('');
        setLoadingAnswerId('');
    };

    const handleChangeInner = async (question: Question, answerId: string, index: number) => {
        const { id, questionType, likertAnswerGroupId } = question;
        if (answerId === emptyString || questionType !== QuestionType.Likert) {
            return;
        }

        // we have two likert group with same set of answers and  different set of scores
        // most of the questions belongs to Strongly Agree - Strongly Disagree group and it's used to render header
        // but there is Validity group which uses a different likert group so
        // we need to make sure if answer id belongs to correct group
        let correctAnswerId = answerId;
        if (likertAnswerGroupId !== likert.id) {
            const currentAnswer = likert.answers.find((x) => x.id === answerId);
            if (currentAnswer) {
                const likertGroup = likertGroups.find((x) => x.id === likertAnswerGroupId);
                if (likertGroup) {
                    const correctAnswer = likertGroup.answers.find((x) => x.answerText === currentAnswer.answerText);
                    if (correctAnswer) {
                        correctAnswerId = correctAnswer.id;
                    }
                }
            }
        }

        setLoadingQuestionId(question.id);
        setLoadingAnswerId(answerId);

        await saveAnswerAsync(id, questionType, correctAnswerId);

        const nextIndex = index + 1;
        setSelectedValues((prevState) => {
            const nextState = new Map(prevState);

            nextState.set(id, answerId);

            if (nextIndex < test.questions.length) {
                const nextQuestion = test.questions[nextIndex];
                if (nextQuestion != null && !nextState.has(nextQuestion.id)) {
                    // add empty answer id
                    nextState.set(nextQuestion.id, emptyString);
                }
            }

            return nextState;
        });

        clearLoadingState();

        handleComplete();
        scrollDown(true);
    };

    const handleChange = async (question: Question, answerId: string, index: number) => {
        if (disableButton) {
            return;
        }
        try {
            setDisableButtonState(true);
            await handleChangeInner(question, answerId, index);
        } catch {
            setProcessingState(ProcessingState.Error);
            clearLoadingState();
        } finally {
            setDisableButtonState(false);
        }
    };

    const wheelEventHandler = (event: any) => {
        const { deltaY, wheelDeltaY } = event.nativeEvent;

        if (deltaY === 0 || wheelDeltaY === 0) {
            return;
        }

        const isM = isMouse(deltaY, wheelDeltaY);
        const isTP = isTouchpad(deltaY, wheelDeltaY);
        if (isM || (isTP && wheelCount % wheelCountMagicNumber === 0)) {
            if (deltaY > 0) {
                scrollDown();
            }
            if (deltaY < 0) {
                scrollUp();
            }
        }

        // TODO: ENABLE WHEN IT NEEDED
        // if (isM === isTP) {
        //     const eventLog = mapToWheelEventLog(event.nativeEvent);
        //     trackWheel(eventLog);
        // }
        setWheelCount((prevState) => prevState + 1);
    };

    const handleScrollStart = () => {
        setWheelCount(0);
    };

    const handleScrollEnd = () => {};

    return (
        <>
            <Grid onWheel={wheelEventHandler}>
                <HeaderPaper>
                    <Container disableGutters style={{ maxWidth: pageWidth }}>
                        <Grid container justifyContent="center" alignItems="center">
                            {columns.map((column) => (
                                <Fragment key={column.id}>
                                    {column.id === 'question' ? (
                                        <Grid item xs>
                                            <Chip
                                                label={
                                                    <Typography variant="body1" color={theme.palette.common.white}>
                                                        {tTest('PersonalityTestHint')}
                                                    </Typography>
                                                }
                                                color="primary"
                                                sx={{
                                                    marginLeft: theme.spacing(5),
                                                    position: 'relative',
                                                    top: theme.spacing(5),
                                                    height: theme.spacing(6),
                                                    borderRadius: theme.spacing(2.5),
                                                    paddingLeft: theme.spacing(1),
                                                    paddingRight: theme.spacing(1),
                                                }}
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid item xs={1}>
                                            <Stack direction="row" justifyContent="center" alignItems="center">
                                                <Typography
                                                    sx={{ maxWidth: theme.spacing(13.75) }}
                                                    variant="body2"
                                                    textAlign="center"
                                                >
                                                    {column.label}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    )}
                                </Fragment>
                            ))}
                        </Grid>
                    </Container>
                </HeaderPaper>

                <div style={{ top: theme.spacing(30), position: 'fixed', width: '100%' }}>
                    <ReactIScroll
                        iScroll={iScroll}
                        options={{
                            mouseWheel: true,
                            scrollbars: true,
                            scrollX: false,
                            scrollY: true,
                        }}
                        onScrollStart={handleScrollStart}
                        onScrollEnd={handleScrollEnd}
                    >
                        <TransitionGroup>
                            {visibleQuestions.map((i: number) => {
                                const question = i === completeTestId ? DefaultQuestion : test.questions[i];
                                const lastQuestion = test.questions[test.questions.length - 1];
                                const disabled =
                                    i === completeTestId
                                        ? !(
                                              test.questions.length === selectedValues.size &&
                                              selectedValues.get(lastQuestion.id) != null
                                          )
                                        : isDisabled(question.id);
                                return (
                                    <Collapse key={question.questionText}>
                                        <RowPaper isDisabled={disabled}>
                                            <Container disableGutters style={{ maxWidth: pageWidth }}>
                                                <Grid container justifyContent="center" alignItems="center">
                                                    {question.id === emptyString && (
                                                        <>
                                                            <Grid item xs />
                                                            <Grid item xs>
                                                                <Typography variant="h4">
                                                                    {test.testType === TestTypes.Personality
                                                                        ? tTest('PersonalityTestEnded')
                                                                        : tTest('EmotionalIntelligenceTestEnded')}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs>
                                                                <LoadingButton
                                                                    size="large"
                                                                    color="primary"
                                                                    loading={
                                                                        processingState === ProcessingState.Processing
                                                                    }
                                                                    variant="contained"
                                                                    disabled={!canSubmit}
                                                                    onClick={async () => {
                                                                        await completeTestAsync();
                                                                        redirectAfterTestEnd();
                                                                    }}
                                                                >
                                                                    {tTest('EndTest')}
                                                                </LoadingButton>
                                                            </Grid>
                                                        </>
                                                    )}
                                                    {question.id !== emptyString &&
                                                        columns.map((column: Column) => {
                                                            return (
                                                                <Fragment key={column.id}>
                                                                    {/* eslint-disable-next-line no-nested-ternary */}
                                                                    {column.id === 'question' ? (
                                                                        <Grid item xs>
                                                                            <Typography
                                                                                variant={
                                                                                    isDisabled(question.id)
                                                                                        ? 'body1'
                                                                                        : 'h5'
                                                                                }
                                                                                sx={{ paddingLeft: theme.spacing(7) }}
                                                                            >
                                                                                {question.questionText}
                                                                            </Typography>
                                                                        </Grid>
                                                                    ) : column.id === 'left' ||
                                                                      column.id === 'right' ? (
                                                                        <Grid item xs={1} />
                                                                    ) : (
                                                                        <Grid item xs={1}>
                                                                            <Stack
                                                                                direction="row"
                                                                                justifyContent="center"
                                                                                alignItems="center"
                                                                            >
                                                                                <LoadingTick
                                                                                    disabled={
                                                                                        isDisabled(question.id) ||
                                                                                        disableButton
                                                                                    }
                                                                                    checked={
                                                                                        selectedValues.get(
                                                                                            question.id,
                                                                                        ) === column.id ?? false
                                                                                    }
                                                                                    loading={
                                                                                        loadingQuestionId ===
                                                                                            question.id &&
                                                                                        loadingAnswerId === column.id
                                                                                    }
                                                                                    onChange={async () => {
                                                                                        await handleChange(
                                                                                            question,
                                                                                            column.id,
                                                                                            i,
                                                                                        );
                                                                                    }}
                                                                                    color={column.color}
                                                                                />
                                                                            </Stack>
                                                                        </Grid>
                                                                    )}
                                                                </Fragment>
                                                            );
                                                        })}
                                                </Grid>
                                            </Container>
                                        </RowPaper>
                                    </Collapse>
                                );
                            })}
                        </TransitionGroup>
                    </ReactIScroll>
                </div>
            </Grid>
            <Prompt message={tTest('PersonalityExit')} when={!canSubmit} />
        </>
    );
}

export default PersonalityQuestionsList;
