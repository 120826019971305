import { TMTranslationGetter } from '../Hooks/useTMTranslation';
import { CommonResourceKey } from '../i18n/byNamespace/i18nCommonResourceKeys';

function mapLocaleCodeToLanguageName(localeCode: string, t: TMTranslationGetter<CommonResourceKey>): string {
    let key: CommonResourceKey;

    switch (localeCode) {
        case 'de':
            key = 'German';
            break;
        case 'es':
            key = 'Spanish';
            break;
        case 'fr':
            key = 'French';
            break;
        case 'it':
            key = 'Italian';
            break;
        case 'pt':
            key = 'Portuguese';
            break;
        case 'en':
        default:
            key = 'English';
            break;
    }

    return t(key);
}

export default mapLocaleCodeToLanguageName;
