import { Page as TMPage, PageProps, ToolbarHeight } from '@talentmesh/core';
import React, { ReactNode, useEffect } from 'react';
import { useLocalizationContext } from '../../Context/LocalizationContext';
import TopBar from '../TopBar';

const Page = ({ children, topBar = TopBar(), ...props }: PageProps) => {
    return (
        <TMPage topBar={topBar} {...props}>
            {children}
        </TMPage>
    );
};

interface FullPageProps {
    children: ReactNode;
    topBar?: ReactNode;
    topBarHeight?: string;
}
export const FullPage = ({ children, topBar, topBarHeight = ToolbarHeight }: FullPageProps) => {
    const { switchToAppLanguage } = useLocalizationContext();

    useEffect(() => {
        window.onbeforeunload = (event) => {
            event.preventDefault();
            return true;
        };

        return () => {
            switchToAppLanguage();
            window.onbeforeunload = null;
        };
    }, []);

    return (
        <>
            {topBar}
            <div
                style={{
                    height: `calc(100vh - ${topBarHeight})`,
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    position: 'relative',
                }}
            >
                {children}
            </div>
        </>
    );
};

export default Page;
