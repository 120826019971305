import React from 'react';
import { RouterProvider } from 'react-router-dom';
import StyleContextProvider from './Context/StyleContextProvider';
import router from './Routing/Router';

function App() {
    return (
        <StyleContextProvider>
            <RouterProvider router={router} />
        </StyleContextProvider>
    );
}
export default App;
