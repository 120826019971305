import ApplicantOnBoardDataDTO from '../DTO/Assessments/ApplicantOnBoardDataDTO';
import { mapApplicantProfile2DTO } from '../DTO/Assessments/ApplicantProfileDTO';
import { ApplicantInformationAnswerDTO } from '../DTO/Onboarding/ApplicantInformationAnswerDTO';
import { ApplicantOnBoardingInfoDTO } from '../DTO/Onboarding/ApplicantOnBoardingInfoDTO';
import InformationCategories from '../DTO/Onboarding/InformationCategories';
import InformationTypes from '../DTO/Onboarding/InformationTypes';
import { mapApplicantProfileDTO2Model } from '../Pages/MyAssessment/ProfileSettings/utils/ApplicantProfileUtils';
import autoMapping from '../Utils/autoMapping';
import { AdditionalInfo } from './AdditionalInfo';
import ApplicantInformationType from './ApplicantInformationType';
import { ApplicantProfile, DefaultApplicantProfile } from './ApplicantProfile';

export interface ApplicantOnBoardingInfo {
    assessmentId: string;
    assessmentName: string;
    jobFunctionId: number;
    includedTestsCount: number;
    recruiterFirstName: string;
    recruiterLastName: string;
    companyName: string;
    applicantProfile: ApplicantProfile;
    assessmentAdditionalInformation: Array<ApplicantInformationType>;
    isOnBoardingFormSubmitted: boolean;
    completedAt?: string;
    inProgressTestId?: string;
    isInternal: boolean;
    isRecruitmentAvailable: boolean;
}

export const DefaultApplicantOnBoardingInfo: ApplicantOnBoardingInfo = {
    assessmentId: '',
    assessmentName: '',
    jobFunctionId: 0,
    includedTestsCount: 0,
    recruiterFirstName: '',
    recruiterLastName: '',
    companyName: '',
    applicantProfile: DefaultApplicantProfile,
    assessmentAdditionalInformation: [],
    isOnBoardingFormSubmitted: false,
    completedAt: undefined,
    inProgressTestId: undefined,
    isInternal: false,
    isRecruitmentAvailable: true,
};

export function mapApplicantOnBoardingInfoDTO2Model(dto: ApplicantOnBoardingInfoDTO): ApplicantOnBoardingInfo {
    const result = autoMapping(dto, DefaultApplicantOnBoardingInfo);

    if (dto.applicantProfile) {
        result.applicantProfile = mapApplicantProfileDTO2Model(dto.applicantProfile);
    }
    result.assessmentAdditionalInformation = dto.applicantInformation
        .filter(({ informationCategory }) => informationCategory === InformationCategories.RecruitmentInformation)
        .map(({ informationType }) => informationType as unknown as ApplicantInformationType);

    return result;
}

export function mapAdditionalInfo2ApplicantOnBoardDataDTO(
    assessmentId: string,
    additionalInfo: AdditionalInfo,
): ApplicantOnBoardDataDTO {
    const { applicantProfile, applicantInformation } = additionalInfo;
    const informationAnswers: ApplicantInformationAnswerDTO[] = applicantInformation.map(
        ({ informationType, answer }) => {
            return {
                informationCategory: InformationCategories.RecruitmentInformation,
                informationType: informationType as unknown as InformationTypes,
                answer,
            };
        },
    );

    return {
        assessmentId,
        applicantProfile: mapApplicantProfile2DTO(applicantProfile),
        applicantInformation: [...informationAnswers],
    };
}
