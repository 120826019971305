/* eslint-disable import/prefer-default-export */
import { TMTranslationGetter } from '../Hooks/useTMTranslation';
import { CommonResourceKey } from '../i18n/byNamespace/i18nCommonResourceKeys';
import { TestTypes } from '../Models/Configuration';

export function testTypeToName(testType: TestTypes, t: TMTranslationGetter<CommonResourceKey>): string {
    switch (testType) {
        case TestTypes.Aptitude:
            return t('TalentMeshAptitudeTest');
        case TestTypes.Skills:
            return t('TalentMeshSkillsTest');
        case TestTypes.Personality:
            return t('TalentMeshPersonalityTest');
        case TestTypes.EmotionalIntelligence:
            return t('TalentMeshEmotionalIntelligenceTest');
        default:
            return '';
    }
}

export function testTypeToString(testType: TestTypes, t: TMTranslationGetter<CommonResourceKey>): string {
    switch (testType) {
        case TestTypes.Skills:
            return t('Skills');
        case TestTypes.Personality:
            return t('Personality');
        case TestTypes.Aptitude:
            return t('Aptitude');
        case TestTypes.EmotionalIntelligence:
            return t('EmotionalIntelligence');
        default:
            return '';
    }
}
