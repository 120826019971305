import autoMapping from '../Utils/autoMapping';
import { ApplicantAppSettingsDTO } from '../DTO/Users/ApplicantAppSettingsDTO';

export interface ApplicantAppSettings {
    locale: string | undefined;
}

export const DefaultApplicantAppSettings: ApplicantAppSettings = {
    locale: undefined,
};

export const mapApplicantAppSettingsDTOtoApplicantAppSettings = (
    dto: ApplicantAppSettingsDTO,
): ApplicantAppSettings => {
    return autoMapping<ApplicantAppSettingsDTO, ApplicantAppSettings>(dto, DefaultApplicantAppSettings);
};
