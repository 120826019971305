import ImageCaptureService from './image-capture.service';

class ImageCaptureScreenService extends ImageCaptureService {
    protected initialize = async (): Promise<void> => {
        if (this.media?.active) {
            return;
        }

        this.media = await navigator.mediaDevices.getDisplayMedia({ video: true });
    };
}

export default ImageCaptureScreenService;
