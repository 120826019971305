import { useTheme } from '@mui/material';
import React from 'react';
import { Box, Stack } from '@talentmesh/core';
import Page from './Page';
import SideNavigation from '../SideNavigation/SideNavigation';
import TopBar from '../TopBar';

const PageSideNav = ({ children }: React.PropsWithChildren) => {
    const theme = useTheme();
    const sideNavWidth = theme.spacing(35.25);

    return (
        <Page containerProps={{ disableGutters: true }} topBar={<TopBar />}>
            <Stack spacing={3} direction="row">
                <Box
                    sx={{
                        minWidth: sideNavWidth,
                        pt: theme.spacing(12.5),
                        position: 'relative',
                        zIndex: 1,
                    }}
                >
                    <SideNavigation sx={{ width: sideNavWidth, position: 'fixed' }} />
                </Box>
                {children}
            </Stack>
        </Page>
    );
};

export default PageSideNav;
