import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './i18n/i18nConfig';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
const app = (
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

root.render(app);
