import { useLocation, useParams } from 'react-router';
import RouteParam from '../Routing/RouteParam';

function useAssessmentId(): string {
    const location = useLocation();
    let { assessmentId } = useParams<{ [RouteParam.AssessmentId]: string }>();
    if (assessmentId === undefined || assessmentId === '') {
        assessmentId = new URLSearchParams(location.search).get(RouteParam.AssessmentId) || '';
    }

    return assessmentId;
}

export default useAssessmentId;
