enum ApplicantInformationType {
    ResumeCV = 'ResumeCV',
    PhoneNumber = 'PhoneNumber',
    ReasonForApplying = 'ReasonForApplying',
    IsEmployed = 'IsEmployed',
    ReasonForLeaving = 'ReasonForLeaving',
    NoticePeriod = 'NoticePeriod',
    ValidWorkPermit = 'ValidWorkPermit',
    WhyWorkInStartup = 'WhyWorkInStartup',
    RankRemunerationLearningBalance = 'RankRemunerationLearningBalance',
    StructureImportance = 'StructureImportance',
    PersonalProject = 'PersonalProject',
    WhereDoYouSeeYourself = 'WhereDoYouSeeYourself',
    SalaryExpectation = 'SalaryExpectation',
}

export default ApplicantInformationType;
