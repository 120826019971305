import { InformationAnswer } from '../../../../Models/AdditionalInfo';
import ApplicantInformationType from '../../../../Models/ApplicantInformationType';
import SalaryExpectation from '../../../../Models/SalaryExpectation';
import { informationFromFormValues } from '../../../../Utils/informationFromFormValues';

export interface RecruitmentInformationFormValues {
    PhoneNumber: string;
    cvFile: string;
    ReasonForApplying: string;
    IsEmployed: string;
    ReasonForLeaving: string;
    NoticePeriod: string;
    ValidWorkPermit: string;
    WhyWorkInStartup: string;
    RankRemunerationLearningBalance: string;
    StructureImportance: string;
    PersonalProject: string;
    WhereDoYouSeeYourself: string;
    SalaryExpectation: SalaryExpectation;
}

export enum RecruitmentInformationSalaryExpectation {
    SalaryExpectationMin = 'SalaryExpectation.min',
    SalaryExpectationMax = 'SalaryExpectation.max',
    SalaryExpectationCurrency = 'SalaryExpectation.currency',
}

export const informationFromRecruitmentInformationFormValues = (
    form: RecruitmentInformationFormValues,
    applicantInformation: ApplicantInformationType[],
): Array<InformationAnswer<ApplicantInformationType>> => {
    return informationFromFormValues(form, applicantInformation);
};
