import React from 'react';
import { Search } from '@talentmesh/core';
import { useApplicantAssessmentInfoContext } from '../../../../../../Context/ApplicantAssessmentInfoContext';
import useTMTranslation from '../../../../../../Hooks/useTMTranslation';

function JobApplicationSearch() {
    const { setSearch } = useApplicantAssessmentInfoContext();
    const { tCommon: t } = useTMTranslation();

    return <Search placeholder={t('SearchJobApplications')} onSearch={setSearch} />;
}

export default JobApplicationSearch;
