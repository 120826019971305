import { InformationAnswer } from '../Models/AdditionalInfo';
import ApplicantInformationType from '../Models/ApplicantInformationType';
import { RecruitmentInformationFormValues } from '../Pages/CandidateOnboard/Steps/RecruitmentInformation/RecruitmentInformationUtils';

export function object2Answers<TInterface, TEnum>(object: TInterface, enums: TEnum[]) {
    return enums.reduce(
        (filtered, key) => {
            if (object[key as keyof TInterface] !== undefined) {
                if (key === 'SalaryExpectation') {
                    filtered.push({
                        informationType: key,
                        answer: JSON.stringify((object as RecruitmentInformationFormValues).SalaryExpectation),
                    });
                } else {
                    filtered.push({
                        informationType: key,
                        answer: object[key as keyof TInterface] as string,
                    });
                }
            }
            return filtered;
        },
        [] as Array<InformationAnswer<TEnum>>,
    );
}

export function informationFromFormValues<T>(
    form: T,
    applicantInformation: ApplicantInformationType[],
): Array<InformationAnswer<ApplicantInformationType>> {
    return object2Answers<T, ApplicantInformationType>(form, applicantInformation);
}
